<template>
	<div id="lodging-all">
		<div class="container-index">
			<div class="desti-header text-center">
				<h1 class="merriweather-reg-40 mt-4">{{ this.pageTitle.toUpperCase() || 'Nos hébergements' }}</h1>
				<h3 v-if="this.searchType !== 'country'" class="merriweather-italic24 mt-4">{{ this.pageSubtitle || 'Les plus beaux hébergements éco-responsables' }}</h3>
				<p class="raleway-reg-p15 mt-2">
					{{
						this.pageDescription ||
						'Amoureuse de la nature et avant tout dénicheuse de voyages, Hortense vous propose une sélection des plus beaux hébergements éco-responsables. Laissez-vous emporter par le meilleur du tourisme haut de gamme : services exceptionnels, expériences uniques et locales… Préparez-vous à passer un séjour inoubliable !'
					}}
				</p>
				<p class="raleway-reg-p15">{{ this.formatLodgings() }}</p>
			</div>
			<div class="btn-filters" :class="{ 'row row-weekend': isPhone }">
				<div id="description" class="exclusive-options" :class="{ 'ol-lg-6 col-xs-10 col-weekend cardColl p-2 mb-3': isPhone, 'mr-2 p-3': !isPhone }">
					 <h5>Découvrez notre offre exclusive</h5>
					 <!--<p>{{ this.pageExclusive }}</p>-->
					 <ul>
						<li v-if="this.pageExclusive1">{{ this.pageExclusive1 }}</li>
						<li v-if="this.pageExclusive2">{{ this.pageExclusive2 }}</li>
						<li v-if="this.pageExclusive3">{{ this.pageExclusive3 }}</li>
						<li v-if="this.pageExclusive4">{{ this.pageExclusive4 }}</li>
						<li v-if="this.pageExclusive5">{{ this.pageExclusive5 }}</li>
					 </ul>
				</div>
				<div class="exclusive-hiw card-content" :class="{ 'ol-lg-6 col-xs-10 col-weekend cardColl p-2': isPhone, 'p-3': !isPhone }">
					<h5>Comment ça marche ?</h5>
       				<p class="p-reg-p13 mb-2">{{ this.pageHow }}</p>
				</div>
			</div>
      <SearchLodging v-if="!isPhone" @updateFilteredLodgings="updateFilteredLodgings" @close="false"></SearchLodging>

			<div class="btn-filters mt-5">
				<div class="green-note-filter mr-3 df-jsb-aic" @click="sortByNote">
					<p class="raleway-reg-p13 mb-0 mr-3">Green note</p>
					<img src="../../images/fleches dropdown.svg" alt="fleches" />
				</div>
				<div v-if="this.lodgingsCounter !== 0" class="map-redirection" @click="displayMap">
					<p class="raleway-reg-p13 mb-0">Carte</p>
				</div>
			</div>
			<div class="filters-phone">
				<p class="raleway-reg-p15 mb-0" @click="showFilters" v-if="showFilt">Masquer les filtres</p>
				<p class="raleway-reg-p15 mb-0" @click="showFilters" v-else>Appliquer les filtres</p>
			</div>
			<div class="row row-page">
				<div class="col-lg-3 col-xs-0 col-filter">
					<Filterlodgings
						:stylesMount="stylesMount"
						:servicesMount="servicesMount"
						:desiresMount="desiresMount"
						@checked="setData"
						@switch="setData"
						@changedBudget="setData"
					>
					</Filterlodgings>
				</div>
				<div class="col-lg-9 col-xs-10">
					<div class="lodgings">
            <Maptest v-if="showMap" :markers="this.markers"  :lodgings="this.filteredLodgings" @markerSelected="selectCardMap" :selectCardMap="selectCardMap" :selectCard="selectCard" @close="showMap = false"></Maptest>
						<div v-if="!selectedMarker">
							<div>
								<CardProductbig
									v-for="(lodging, index) in this.filteredLodgings"
									:key="index"
									:wishlistLodgings="wishlistLodgings"
									:user="isUserConnected()"
									:lodging="lodging"
									:destination="lodging.destination"
									v-if="index === 0"
									:data-marker="index"
									:data-lodging="lodging.id"
									data-aos="fade-up"
									data-aos-duration="900"
									v-show="false"
								></CardProductbig>
								<CardProductbig 
								    :wishlistLodgings="wishlistLodgings"
									:lodging="lodging" 
									:user="isUserConnected()" 
									:destination="lodging.destination" 
									v-else 
									style="margin-top: 20px" :data-marker="index" 
									:data-lodging="lodging.id"
									v-show="false">
								</CardProductbig>
							</div>
							
							<CardProductbig
								v-for="(lodging, index) in this.displayedLodgs"
								:key="index"
								:wishlistLodgings="wishlistLodgings"
								:user="isUserConnected()"
								:lodging="lodging"
								:destination="lodging.destination"
								v-if="index === 0"
								:data-marker="index"
								:data-lodging="lodging.id"
								data-aos="fade-up"
								data-aos-duration="900"
								@hideBigMap="hideBigMap"
							></CardProductbig>
							<CardProductbig
							    :wishlistLodgings="wishlistLodgings"
							    :lodging="lodging" 
								:user="isUserConnected()" 
								:destination="lodging.destination" 
								v-else 
								style="margin-top: 20px" :data-marker="index" 
								:data-lodging="lodging.id"
								@hideBigMap="hideBigMap">
							</CardProductbig>
						</div>
						<div v-else>
<!--							<Cardmap :lodging="this.selectedLodging" :destination="this.selectedLodging.destination" @unselect="selectCardMap" :idMarker="selectedCard"></Cardmap>-->
						</div>
					</div>
					<nav v-if="lodgingsCounter !== 0">
								<ul class="pagination">
									<li class="page-item" @click="scrollToTop">
										<button
										    v-for="pageNumber in pages"
											:class="page === pageNumber ? 'active-item' : ''"
											type="button"
											class="page-link"
											@click="page = pageNumber"> 
											{{pageNumber}} 
										</button>
									</li>
								</ul>
					</nav>
					<div v-if="lodgingsCounter === 0" class="no-lodging text-center">
						<p class="raleway-reg-p15">Aucun hébergement disponible.</br>
						Hortense est à la recherche de lieux d’exception à vous proposer dans cette destination.</br></br>
						Si vous souhaitez que nous vous trouvions un hébergement de charme dans une destination spécifique</br>
						, <strong>n'hésitez pas à nous contacter et à nous fournir les détails de votre demande.</strong> Que ce soit un hôtel de charme en pleine nature, une</br>
						chambre d'hôtes à la campagne, une villa en bord de mer, un chalet en montagne ou un boutique hôtel en pleine ville,<br/>
						nous mettrons tout en œuvre pour trouver l'endroit parfait correspondant à vos attentes.</p></br>
						<a :href="'/voyage-sur-mesure/'" class="hortense-btn-primary" style="margin: 0px auto;display: inline">VOTRE VOYAGE SUR MESURE</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CardProductbig from '../../components/cards/CardProductbig.vue';
	import Filterlodgings from '../../pages/destinations/Filterlodgings.vue';
	import Cardmap from '../../components/cards/Cardmap.vue';
	import Maptest from '../../components/Maptest.vue';
	import fetcher from '../../packs/shared/api.js';
	import AOS from 'aos';
  import SearchLodging from "../../components/btns/Searchlodging.vue";
	export default {
		name: 'exclusivelodgings',
		components: {SearchLodging, CardProductbig, Filterlodgings, Maptest, Cardmap },
		props: ['page-title', 'lodgings', 'user', 'search-type', 'page-description', 'page-exclusive', 'page-how', 'page-subtitle', 'page-name', 'page-exclusive1', 'page-exclusive2','page-exclusive3', 'page-exclusive4', 'page-exclusive5', 'services', 'styles', 'desires', 'exclusive'],
		data() {
			return {
				wishlistLodgings: null,
				page: 1,
			    perPage: 10,
			    pages: [],
				lodgingsList: null,
				filteredLodgings: this.lodgings,
				showMap: false,
				markers: [],
				selectedMarker: false,
				selectedLodging: null,
				showFilt: false,
				selectedCard: null,
				current: 1,
				asc: true,
				allValues: {
					score: [null, '4', '5', '6', '7', '8', '9', '10'],
					standing: [null, '0', '1', '2', '3', '4', '5'],
					type: [null, 'Hotel', 'Guestroom', 'Gite', 'Guesthouse', 'Other', 'Villa', 'Appartment', 'Lodge'],
					budget: [0, 2000],
					beddings: [null, 'Lit double - Standard', 'Lit double - King size', 'King size', 'king size', 'Queen size', 'Standard', 'Lit double - Queen size', 'queen-size', 'Lit double', 'Lit simple', 'Lits jumeaux'],
					breakfast_include: false,
					half_pension: false,
					full_pension: false,
					all_inclusive: false,
					animal: false,
					business: false,
					freeCancel: false,
          services: [],
          styles: [],
          desires: [],
				},
				activeFilters: {
          score: [null, '4', '5', '6', '7', '8', '9', '10'],
          standing: [null, '0', '1', '2', '3', '4', '5'],
          type: [null, 'Hotel', 'Guestroom', 'Gite', 'Guesthouse', 'Other', 'Villa', 'Appartment', 'Lodge'],
          budget: [0, 2000],
          beddings: [null, 'Lit double - Standard', 'Lit double - King size', 'King size', 'king size', 'Queen size', 'Standard', 'Lit double - Queen size', 'queen-size', 'Lit double', 'Lit simple', 'Lits jumeaux'],
          breakfast_include: false,
          half_pension: false,
          full_pension: false,
          all_inclusive: false,
          animal: false,
          business: false,
          freeCancel: false,
          services: [],
          styles: [],
          desires: [],
        },
				stylesMount: [],
				desiresMount: [],
				servicesMount: [],
			};
		},
		mounted(){

			console.log('LOGINGS');
			console.log(this.filteredLodgings);

		},
		watch: {
			allValues: {
				deep: true,
				handler(val) {
					this.activeFilters = Object.assign({}, val);
				},
			},
			activeFilters: {
				deep: true,
				handler() {
					this.filter();
				},
			},
			lodgings: {
				immediate: true,
				handler(val) {
					this.lodgingsList = val.map((el) => JSON.parse(el));
					this.filteredLodgings = this.lodgingsList;
					this.setPages();
				},
			},
			filteredLodgings() {
					this.setPages();		
			},
			desiresMount: {
				deep: true,
				handler(val) {
					if(val) {
						if (!val.length) return;
						this.allValues.desires = val.map((el) => el.id);
					}
				},
			},
			servicesMount: {
				deep: true,
				handler(val) {
					if(val) {
						if (!val.length) return;
						this.allValues.services = val.map((el) => el.id);
					}
				},
			},
			stylesMount: {
				deep: true,
				handler(val) {
					if(val) {
						if (!val.length) return;
						this.allValues.styles = val.map((el) => el.id);
					}
				},
			},
		},
		computed: {
			displayedLodgs() {
				return this.paginate(this.filteredLodgings);
		    },
			lodgingsCounter() {
				return this.filteredLodgings.length;
			},
			isPhone() {
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
					return true;
				}else{
					return false;
				}
		    }
		},
		beforeMount(){
			this.fetchWishlist();
		},
		methods: {
			hideBigMap() {
			   this.showMap = false;
			},
      updateFilteredLodgings(newLodgings) {
        this.filteredLodgings = newLodgings;
        this.lodgings = newLodgings;
      },
			async fetchWishlist() {
				try {
					if (this.user) {
						const response = await fetcher.get('/wishlists.json');
						this.wishlistLodgings = response.data.lodgings;
					}
				} catch (e) {
					console.error(e);
				}
			},
			scrollToTop() {
				window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
				});
			},
			setPages() {
				this.page = 1;
				this.pages = [];
				let numberOfPages = Math.ceil(this.filteredLodgings.length / this.perPage);
				for (let index = 1; index <= numberOfPages; index++) {
					this.pages.push(index);
				}
		   },
		   paginate(lodgs) {
			    let page = this.page;
			    let perPage = this.perPage;
			    let from = (page * perPage) - perPage;
			    let to = (page * perPage);
			    return  lodgs.slice(from, to);
		    },
			setData(val, name) {
				// as for animal switch for example
				if (typeof val === 'boolean') {
					this.activeFilters[name] = val;
					return;
				}
				// all unchecked means we actually want all of them
				if (Array.isArray(val) && val.length === 0) {
					this.activeFilters[name] = [...this.allValues[name]];
				} else {
					this.activeFilters[name] = val;
				}
			},
			showFilters() {
				const filter = document.querySelector('#filter-lodging');
				if (filter.style.display === 'block') {
					this.showFilt = false;
					filter.style.display = 'none';
				} else {
					filter.style.display = 'block';
					this.showFilt = true;
				}
			},
			isUserConnected() {
				return this.user ? this.user : null;
			},
			sortByNote() {
				this.filteredLodgings.sort((a, b) => {
					if (this.asc) {
						return a.score - b.score;
					} else {
						return b.score - a.score;
					}
				});
				this.asc = !this.asc;
			},
			filter() {
				this.filteredLodgings = this.lodgingsList;

				Object.entries(this.activeFilters).forEach(([filter, value]) => {
					// attributes with a unique value
					if (['score', 'standing', 'type'].includes(filter)) {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							return value.includes(lodging[filter]?.toString());
						});
					}

					// attributes with multiple possible values
					if (['styles', 'services', 'desires', 'beddings'].includes(filter)) {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							return this.includesIn(value, lodging[filter + '_arr']);
						});
					}

					// remove lodgings that don't offer a pricing for it
					//if (['breakfast_include', 'half_pension', 'full_pension', 'all_inclusive'].includes(filter)) {
					//	this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
					//		if (value === false) return true;

					//		return lodging[filter + '_pricings'].some((pricing) => pricing > 0);
					//	});
					//}

					// number range
					if (filter === 'budget') {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							//if (!lodging.pricings_arr.length) return true;

							//return lodging.pricings_arr.some((price) => price >= value[0] && price <= value[1]);
							return lodging.lowest_price >= value[0] && lodging.lowest_price <= value[1];
						});
					}

					// toggle button, only filter when toggled
					if (filter === 'animal') {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							if (value === false) return true;

							return lodging.lodging_profile.animal === true;
						});
					}

					// means only Leonie collection
					if (filter === 'business') {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							if (value === false) return true;

							return lodging.collections_arr.includes('Léonie');
						});
					}

					// free when canceling price is either nil or 0
					if (filter == 'freeCancel') {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							if (value === false) return true;

							return lodging.cancelation_free_pricings.some((pricing) => pricing == 0);
						});
					}
				});
			},
			formatLodgings() {
				let hebergement = ' hébergement';
				if (this.lodgingsCounter > 1) {
					hebergement += 's';
				}
				return this.lodgingsCounter + hebergement;
			},
      selectCard(attr) {
        const cards = document.querySelectorAll('#cardProduct-big');
        const selectedCard = Array.from(cards).find(node => node.dataset.lodging === attr.toString());
        this.selectedCard = selectedCard;
        this.selectedMarker = attr;
        cards.forEach((el) => {
          if (el?.dataset?.marker !== selectedCard?.dataset?.marker) {
            el.style.display === 'none' ? (el.style.display = 'flex') : (el.style.display = 'none');
          }
        });
        this.selectedLodging = this.lodgingsList.find((lod) => {
          return lod.id === parseInt(attr);
        });
      },
			selectCardMap(attr){
				// this.selectCard(attr);
				if(this.showMap){
					setTimeout(() => {this.showMap = false}, 10);	
					setTimeout(() => {this.showMap = true}, 10);	
				}
			},
			displayMap() {
				this.showMap = !this.showMap;
				this.markers = this.markerMap();
			},
			includesIn(array1, array2) {
				// don't filter out this lodging when services, beddings, styles, or desires are not set
				if (!array2.length || !array1.length) return true;

				array1 = array1.map((e) => e?.toString());
				array2 = array2.map((e) => e?.toString());
				for (let val of array1) {
					if (array2.includes(val)) return true;
				}
				return false;
			},
			markerMap() {
				const markers = [];
				this.filteredLodgings.map((el) => {
					markers.push([el.longitude, el.latitude, el.id,  el.lowest_price]);
				});
				return markers;
			},
		},
    created() {
			AOS.init();
			this.stylesMount = this.styles;
			this.servicesMount = this.services;
			this.desiresMount = this.desires;
		},
	};
</script>
<style lang="scss" scoped>
   .page-item{
	border-color: black;
        button{
		display: inline-block; 
		color: black;
	  }
    }   
	.active-item{
		color: green;
		border-color: black;
		border-style: solid;
	}
	.exclusive-options {
		background-color:  #cddfd3;
		border-radius: 20px;
		text-transform: none;
		width: 50%;
		h5{
			font-family: $Merriweather;
			font-weight: 400;
			font-style: italic;
			font-size: 20px;
			text-transform: none;
        }
		p {
			font-family: $Raleway;
			font-weight: 400;
			font-size: 15px;
			color: #000000;
			text-transform: none;
		}
		ul{
          list-style: none;
          list-style-image: url('../../images/Icotype_puce.svg');
          font-family: $Raleway;
          font-weight: 400;
          font-size: 13px;
        }
	}

	.exclusive-hiw {
		border-color: #cddfd3;
		border-style: solid;
		border-width: 1px;
		border-top-right-radius: 20px 20px;
		border-bottom-right-radius: 20px 20px;
		border-bottom-left-radius: 20px 20px;
		width: 50%;	
		p {
			font-family: $Raleway;
			font-weight: 400;
			font-size: 15px;
			color: #000000;
			text-transform: none;
		}
	}
	@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
		.exclusive-options {
			background-color:  #cddfd3;
			border-radius: 20px;
			text-transform: none;
			width: 80%;
			h5{
				font-family: $Merriweather;
				font-weight: 400;
				font-style: italic;
				font-size: 20px;
				text-transform: none;
			}
			p {
				font-family: $Raleway;
				font-weight: 400;
				font-size: 15px;
				color: #000000;
				text-transform: none;
			}
			ul{
			list-style: none;
			list-style-image: url('../../images/Icotype_puce.svg');
			font-family: $Raleway;
			font-weight: 400;
			font-size: 13px;
			}
		}

		.exclusive-hiw {
			border-color: #cddfd3;
			border-style: solid;
			border-width: 1px;
			border-top-right-radius: 20px 20px;
			border-bottom-right-radius: 20px 20px;
			border-bottom-left-radius: 20px 20px;		
			width: 80%;
			p {
				font-family: $Raleway;
				font-weight: 400;
				font-size: 15px;
				color: #000000;
				text-transform: none;
			}
			h5{
				font-family: $Merriweather;
				font-weight: 400;
				font-size: 20px;
				text-transform: uppercase;
			}
		}
    }
</styles>
<style
  lang="scss"
  scope="app/assets/stylesheets/components/exclusive-gallery"></style>
