<template>
  <div id="modal-subcribe">
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-content">
              <div class="modal-content-form">
                <div class="closing">
                  <button class="close-btn-modal" @click="$emit('close')"></button>
                </div>
                <div class="header-modal">
                  <p class="raleway-reg-p24 ">La newsletter d’Hortense</p>
                </div>
                <div class="form-signup raleway-reg-p13">
                  <div class="form-item">
                    <label for="name">*Prénom</label>
                    <input v-model="newsletter.first_name" type="text">
                  </div>
                  <div class="form-item">
                    <label for="name">*Nom</label>
                    <input v-model="newsletter.last_name" type="text">
                  </div>
                  <div class="form-item">
                    <label for="name">*Mon adresse mail</label>
                    <input v-model="newsletter.email" type="text">
                  </div>
                  <p class="raleway-it-l13">Les champs marqués d’un * sont obligatoires.</p>
                  <button class="hortense-btn-primary" @click="sendContact">Je m'abonne</button>
                </div>
                <div class="become d-flex">
                  <p class="raleway-reg-p15 mr-3">En m’abonnant, j’accepte qu’Hortense m’envoie des informations et des offres par e-mail. Je peux me désabonner à tout moment sur mon espace en ligne ou en cliquant sur le lien « Se désinscrire » qui se trouve en bas de tous nos e-mails. J’ai lu la <a href="/politique-confidentialité/" class="link-hortense"> Politique de confidentialité</a> Hortense.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';

  export default{
    name: 'modal-subcribe',
    data() {
      return {
        newsletter: {
          email: '',
          last_name: '',
          first_name: '',
        },
      }
    },
    methods: {
      async sendContact(){
        try {
          console.log('newsletter')
          console.log(this.newsletter)
          const res = await fetcher.post('/newsletters/', { newsletter: this.newsletter })
          this.$emit('closeSubscribe');
          window.location.href = '/';
        } catch (e) {
          window.location.href = '/';
          console.error(e);
        }
      },
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/modal-signup'>

</style>
