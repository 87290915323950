<template>
  <div id="experiences">
    <div class="container-index">
      <div class="header-inspi text-center">
        <h1 class="merriweather-reg-40">Nos expériences</h1>
        <p class="raleway-reg-p15">
          Agrémentez votre séjour d’expériences inédites : oenologie, DIY,
          sport, culture, nature, gastronomie locale… Profitez de vos vacances
          pour partir à l’aventure dans le respect de l’environnement et des
          populations locales. Découvrez la sélection d’Hortense pour un voyage
          responsable et inoubliable.
        </p>
      </div>
      <div class="filter-exp" v-if="categories">
        <template v-for="category in categories">
          <input
            type="form-check-input"
            class="btn-check"
            @click="selectExperience"
            :value="category.name.toLowerCase()"
            :id="category.name"
          />
          <label
            class="btn raleway-bold-p experience"
            :for="category.name"
            :class="category.name === `au bord de l'eau` ? 'active' : null"
            >{{ category.name }}
            <img src="../../images/Tick.svg" alt="tick" />
          </label>
        </template>
        <br />
      </div>
      <div class="exp-description" v-if="this.categories">
        <h3 class="raleway-reg-p24">
          Expériences {{ titleExperience === "diy" ? "DIY" : titleExperience }}
        </h3>
        <p class="raleway-reg-p15 text-center">
          {{ this.descriptionExperience }}
        </p>
      </div>
      <button
        class="btn-only-phone hortense-btn-snd"
        @click="showDesire = true"
      >
        <p class="raleway-bold-p16 mb-0">Préciser mon envie</p>
        <img src="../../images/flechebas-simple.svg" alt="fleche" />
      </button>
      <div class="searching-exp">
        <div class="autocomplete">
          <input
            type="text"
            class="input-exp"
            placeholder="Que voulez-vous faire?"
            v-model="inputName"
            @input="onChangeName"
          />
          <ul v-show="autocompleteNameIsOpen" class="autocomplete-results">
            <li
              v-for="(result, i) in autocompleteNameResults"
              :key="i"
              class="autocomplete-result"
              @click="setResultName(result)"
            >
              {{ result }}
            </li>
          </ul>
        </div>
        <div class="autocomplete">
          <input
            type="text"
            class="input-exp"
            placeholder="Où souhaitez-vous partir?"
            v-model="inputDestination"
            @input="onChangeLoc"
          />
          <ul v-show="autocompleteLocIsOpen" class="autocomplete-results">
            <li
              v-for="(result, i) in autocompleteLocationResults"
              :key="i"
              class="autocomplete-result"
              @click="setResultLoc(result)"
            >
              {{ result }}
            </li>
          </ul>
        </div>
        <button
          class="hortense-btn-primary"
          @click="filterExperiencesBytextInput"
        >
          Préciser votre envie
        </button>
      </div>
      <p class="raleway-reg-p15 text-center mt-5">
        Hortense a trouvé {{ this.experiences.length }} expériences
        correspondant à vos envies
      </p>
      <div class="search-exp-phone" v-if="showDesire">
        <div class="closing">
          <p class="raleway-bold-p16 mb-0">Préciser mon envie</p>
          <button class="close-btn-modal" @click="showDesire = false">
            <img src="../../images/Croix fermer.svg" />
          </button>
        </div>
        <div class="inputs-search">
          <label class="label-exp-phone raleway-reg-p13"
            >Que voulez-vous faire ?</label
          >
          <input
            type="text"
            class="input-exp"
            placeholder="Musée, balade..."
            v-model="inputName"
          />
          <label class="label-exp-phone raleway-reg-p13"
            >Où souhaitez-vous partir ?</label
          >
          <input
            type="text"
            class="input-exp"
            placeholder="France, Caraïbes..."
            v-model="inputDestination"
          />
        </div>
        <button
          class="hortense-btn-primary"
          @click="filterExperiencesBytextInput"
        >
          Valider
        </button>
      </div>
      <div class="exp-gallery row" v-if="this.experiences.length !== 0">
        <figure
          class="structure-galerie gallery-item col-lg-6 col-md-10"
          v-for="experience in this.experiences.slice(
            0,
            this.experiencesByPage * this.experiencesCurrentPage
          )"
        >
          <div :class="'gallery-item-1'">
            <ExperienceGrid
              :experience="experience"
              :user="isUserConnected()"
              :categoryName="titleExperience"
              :wishlist="wishlistExp"
              data-aos="fade-up"
              data-aos-duration="900"
            ></ExperienceGrid>
          </div>
        </figure>
      </div>
      <div class="exp-description" v-else>
        <h3 class="raleway-reg-p24">Aucun résultat</h3>
      </div>
      <div class="pagination-hub d-flex mt-3">
        <a
          href="#"
          v-on:click.prevent="loadMoreExperiences"
          class="link-hortense"
        >
          voir plus
          <img src="../../images/flechebas-simple.svg" alt="fleche bas"
        /></a>
      </div>

      <ScrollUp></ScrollUp>
    </div>
  </div>
</template>

<script>
import ExperienceGrid from "components/experiences/ExperienceGrid.vue";
import ScrollUp from "components/btns/ScrollUp.vue";
import fetcher from "packs/shared/api.js";
import AOS from "aos";

export default {
  name: "experiences",
  props: ["user", "experiences_list"],
  components: {
    ScrollUp,
    ExperienceGrid,
  },
  data() {
    return {
      showDesire: false,
      selectedExperience: "au bord de l'eau",
      categories: null,
      titleExperience: "au bord de l'eau",
      filteredExperiences: null,
      descriptionExperience: null,
      wishlistExp: null,
      catMount: null,
      experienceListed: null,
      experiences: this.experiences_list["au bord de l'eau"],
      experiencesByCategory: null,
      expfilter: null,
      inputName: "",
      inputDestination: "",
      experiencesCurrentPage: 1,
      experiencesByPage: 10,
      autocompleteLocation: null,
      autocompleteLocationResults: [],
      autocompleteLocIsOpen: false,
      autocompleteName: null,
      autocompleteNameResults: [],
      autocompleteNameIsOpen: false,
    };
  },
  methods: {
    isUserConnected() {
      return this.user ? this.user : null;
    },
    async toggleLike() {
      try {
        const response = await fetcher.patch("/wishlists/" + 1, {
          experience_id: this.experience.id,
        });
        this.wishlistExp = response.data.experiences;
      } catch (e) {
        console.error(e);
      }
    },
    handleClickOutside(event) {
      this.autocompleteLocIsOpen = false;
      this.autocompleteNameIsOpen = false;
    },
    setResultLoc(result) {
      this.inputDestination = result;
      this.autocompleteLocIsOpen = false;
      this.filterExperiencesBytextInput();
    },
    setResultName(result) {
      this.inputName = result;
      this.autocompleteNameIsOpen = false;
      this.filterExperiencesBytextInput();
    },
    filterResultsName() {
      this.autocompleteNameResults = this.autocompleteName.filter(
        (item) => item.toLowerCase().indexOf(this.inputName.toLowerCase()) > -1
      );
    },
    filterResultsLoc() {
      this.autocompleteLocationResults = this.autocompleteLocation.filter(
        (item) =>
          item.toLowerCase().indexOf(this.inputDestination.toLowerCase()) > -1
      );
    },
    onChangeName() {
      this.filterResultsName();
      if (
        this.autocompleteNameResults.length === 0 ||
        this.inputName.length === 0
      ) {
        this.autocompleteNameIsOpen = false;
      } else {
        this.autocompleteNameIsOpen = true;
      }
    },
    onChangeLoc() {
      this.filterResultsLoc();
      if (
        this.autocompleteLocationResults.length === 0 ||
        this.inputDestination.length === 0
      ) {
        this.autocompleteLocIsOpen = false;
      } else {
        this.autocompleteLocIsOpen = true;
      }
    },
    async autocomplete() {
      try {
        const response = await fetcher.get(
          "/categories/" +
            (this.titleExperience === "diy" ? "DIY" : this.titleExperience) +
            `/experiences/list?query=true`
        );
        const resLoc = response.data
          .map((a) => a.address)
          .join(", ")
          .split(", ");
        const uniqueResLoc = [...new Set(resLoc)];
        this.autocompleteLocation = uniqueResLoc;
        const resName = response.data
          .map((a) => a.name)
          .join(", ")
          .split(", ");
        const uniqueResName = [...new Set(resName)];
        this.autocompleteName = uniqueResName;
      } catch (e) {
        console.error(e);
      }
    },
    selectExperience(e) {
      this.selectedExperience = e.currentTarget.id;
      this.titleExperience = e.currentTarget.value;
      this.fetchExperience();
      this.autocomplete();
      const actives = document.querySelectorAll(".active.experience");
      if (actives) {
        actives.forEach((el) => el.classList.toggle("active"));
      }
      e.currentTarget.nextElementSibling.classList.toggle("active");
    },
    async fetchExp(value) {
      try {
        const response = await fetcher.get(
          "/categories/" + this.formatSlug(value) + `/experiences/list`
        );
        this.expfilter = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    filterExperiences() {
      this.filteredExperiences = this.experiences;
      if (this.selectedExperience) {
        this.experiencesByCategory = Object.entries(
          this.filteredExperiences
        ).filter(([key, value]) => key === this.titleExperience);
        this.filteredExperiences = this.experiencesByCategory[0][1];
        this.fetchdatas(this.titleExperience);
        this.fetchExp(this.titleExperience);
        this.expfilter = this.experiences[this.titleExperience];
      }
    },
    formatSlug(value) {
      return value.toLowerCase().replaceAll(" ", "-").replaceAll("'", "-");
    },
    filterExperiencesByInput() {
      this.expfilter = this.experiences[this.titleExperience];
      this.expfilter = this.experiences.filter((e) => {
        return e.name.toLowerCase().startsWith(this.inputName.toLowerCase());
      });
    },
    async filterExperiencesBytextInput() {
      try {
        this.showDesire = false;
        const response = await fetcher.get(
          "/categories/" +
            (this.titleExperience === "diy" ? "DIY" : this.titleExperience) +
            `/experiences/list?location=` +
            this.inputDestination +
            `&search=` +
            this.inputName
        );
        this.experiences = response.data.filter((el) => el);
        this.filteredExperiences = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchExperience() {
      try {
        this.descriptionExperience = this.categories.find(
          (el) => el.name.toLowerCase() === this.titleExperience
        ).description;
        const response = await fetcher.get(
          "/categories/" +
            (this.titleExperience === "diy" ? "DIY" : this.titleExperience) +
            `/experiences/list`
        );
        this.experiences = response.data.filter((el) => el);
        this.filteredExperiences = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async loadMoreExperiences(e) {
      e.preventDefault();
      this.experiencesCurrentPage++;
    },
    async fetchCategories() {
      try {
        const response = await fetcher.get("/categories");
        this.categories = response.data;
        this.descriptionExperience = this.categories.find(
          (el) => el.name.toLowerCase() === this.titleExperience
        ).description;
      } catch (e) {
        console.error(e);
      }
    },
    isIncludeInWishlist() {
      if (this.wishlistExp) {
        for (const experience of this.wishlistExp) {
          if (experience.id === this.experience.id) {
            return true;
          }
        }
      }
      return false;
    },
    async fetchWishlist() {
      try {
        if (this.user) {
          const response = await fetcher.get("/wishlists");
          this.wishlistExp = response.data.experiences;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.fetchCategories();
    this.autocomplete();
    this.fetchWishlist();
    AOS.init();
    if (window.location.search) {
      this.titleExperience = window.location.search.split("?")[1];
      this.selectedExperience = window.location.search.split("?")[1];
      this.filterExperiences();
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    experiences(newVal) {
      this.experiences = newVal;
    },
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/experience"></style>
