<template>
  <div id="modal-environmental-promise">
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="closing">
                <button class="close-btn-modal" @click="$emit('close')"></button>
              </div>
              <div class="heading-modal">
                <h3 class="raleway-reg-p24">leur promesse environnementale</h3>
              </div>
              <div class="modal-content-pros">
                <div class="box-pros">
                  <p class="raleway-bold-p16">Les points forts de cet hébergement</p>
                  <pre class="raleway-reg-p15">{{ formatList(this.description.pros)}}</pre>
                </div>
                <div class="box-futur-actions">
                  <p class="raleway-bold-p16">Les actions futures</p>
                  <p class="raleway-reg-p15">L’hébergement souhaite s’engager sur plusieurs actions :</p>
                  <pre class="raleway-reg-p15">{{ formatList(this.description.future_action)}}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>
</template>

<script>
    import fetcher from 'packs/shared/api.js';

  export default{
    name: 'modal-environmental-promise',
    props:[ 'description' ],
    data() {
      return {

      }
    },
    methods: {
      formatList(string){
        const regex = /\W-/g;
        return string.replaceAll( regex, "\n\n-")
      },
    }
  }
</script>

<style lang="scss" scoped>
#modal-environmental-promise{
  .modal-mask {
    position:fixed;
    overflow:scroll;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: 721px;
    height: 90vh;
    margin: 51px auto;
    padding: 1rem 1rem;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    .heading-modal{
      padding: 0rem 7rem;
    }
  }
  .modal-content-pros{
    padding: 3rem 8rem;
    overflow-y: scroll;
    height: 75vh;
  }
  .closing{
    display: flex;
    justify-content: flex-end;
  }
  button.close-btn-modal{
    background-image: url('../../images/Croix fermer.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 25px;
    height: 25px;
    border: 0;
    background-color: transparent;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #modal-environmental-promise{
    .modal-container {
      width: 356px;
      height: fit-content;
      margin: 51px auto;
      padding: 1rem 1rem;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      .heading-modal{
        padding: 0rem 1rem;
      }
    }
    .modal-content-pros{
      padding: 3rem 1rem;
      overflow-y: scroll;
      height: 75vh;
    }
    .closing{
      display: flex;
      justify-content: center;
    }
  }
}

</style>
