<template>
  <div id="contact-form">
    <!-- modify this form HTML and place wherever you want your form -->
    <div class="container-index-trip">
      <div class="header-inspi mb-5"><br><br>
        <h1 class="text-center" :class="{ 'merriweather-reg-40': !isPhone, 'merriweather-reg-20': isPhone }">Nous vous organisons un voyage sur mesure et personnalisé</h1>
        <div id="banner-made-trip">
          <div class="banner-show-container">
            <div class="">
              <p id="desire-p" :class="{ 'merriweather-italic30': !isPhone,  'merriweather-italic-15': isPhone}">
                Envie de voyager de manière éco-responsable ?<br> A la recherche de lieux d’exception respectueux<br> de l’environnement ?
              </p>
            </div>
          </div>
        </div>
        <p id="trip-description" class="mt-2" :class="{ 'raleway-reg-p15 padding-desktop': !isPhone, 'raleway-reg-p13 ml-5': isPhone }">
        Vous souhaitez organiser un voyage plus authentique et responsable ? <br> 
        Hortense est à votre écoute pour construire votre voyage sur mesure et vous accompagne afin de créer le voyage qui vous ressemble. <br><br>
        Décrivez votre projet de voyage à l’aide du formulaire ci-dessous, nous vous contacterons dans les meilleurs délais pour en discuter. <br><br>
        Commencez ici votre expérience de voyage responsable avec Hortense !<br>
        </p>
      </div>
      <div id="my-form-container">
      <form id="my-form" action="https://formspree.io/f/myyqyanl" method="POST" ref="myForm">
          <div class="container">
            <div class="row">
              <div :class="{ 'col-2': !isPhone, 'col-4': isPhone }">
                <v-select class="select-item raleway-reg-p13 text-center" placeholder="Mme" v-model="sexSelected" :options="sexChoices"></v-select>
              </div>
              <div :class="{ 'col-5': !isPhone, 'col-8': isPhone }">
                <input type="text" name="NOM" class="form-control raleway-reg-p13" placeholder="Nom" id="name" data-validation-required-message="Veuillez entrer votre NOM svp">
                <p class="help-block text-danger"></p>
              </div>
              <div :class="{ 'col-5': !isPhone, 'col-12': isPhone }">
                <input type="text" name="PRENOM" class="form-control raleway-reg-p13" placeholder="Prénom" id="name" data-validation-required-message="Veuillez entrer votre PRENOM svp">
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div :class="{ 'col-12': isPhone, 'col-6': !isPhone }">
                <input type="text" name="EMAIL" class="form-control raleway-reg-p13" placeholder="Adresse e-mail" id="name" data-validation-required-message="Veuillez entrer votre EMAIL svp">
                <p class="help-block text-danger"></p>
              </div>
              <div :class="{ 'col-12': isPhone, 'col-6': !isPhone }">
                <input type="text" name="TELEPHONE" class="form-control raleway-reg-p13" placeholder="Téléphone" id="name" data-validation-required-message="Veuillez entrer votre PRENOM svp">
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div :class="{ 'col-12': isPhone, 'col-6': !isPhone }">
                <input type="text" name="PAYS" class="form-control raleway-reg-p13" placeholder="Pays de résidence" id="name" data-validation-required-message="Veuillez entrer votre EMAIL svp">
                <p class="help-block text-danger"></p>
              </div>
              <div :class="{ 'col-12': isPhone, 'col-6': !isPhone }">
                <input type="text" name="POSTAL" class="form-control raleway-reg-p13" placeholder="Code postal" id="name" data-validation-required-message="Veuillez entrer votre POSTAL svp">
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-12">
                <input type="text" name="DESTINATION" class="form-control raleway-reg-p13" placeholder="Destination" id="name" data-validation-required-message="Veuillez entrer votre DESTINATION svp">
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-12">
                <v-select id="tripType" name="TYPEVOYAGE" class="select-item raleway-bold-p13 text-center" placeholder="Type de voyage" v-model="tripTypeSelected" :options="tripTypeChoices"></v-select>
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div :class="{ 'col-12': isPhone, 'col-6': !isPhone }">
                <v-select id="adult" name="ADULTE" class="select-item raleway-bold-p13 text-center" placeholder="Nombre d'adultes" v-model="adultSelected" :options="adultChoices"></v-select>
                <p class="help-block text-danger"></p>
              </div>
              <div :class="{ 'col-12': isPhone, 'col-6': !isPhone }">
                <v-select id="child" name="ENFANT" class="select-item raleway-bold-p13 text-center" placeholder="Nombre d'enfants" v-model="childSelected" :options="childChoices"></v-select>
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>
        <div class="container">
          <div class="row">
            <div :class="{ 'col-12': isPhone, 'col-6': !isPhone }">
                <textarea type="text" name="TARIFMAX" class="form-control raleway-reg-p13"
                          :placeholder="isPhone
                                ? 'Si vous avez des enfants, quel âge \nont-ils ?'
                                : 'Si vous avez des enfants, quel âge ont-ils ?'"
                          data-validation-required-message="Veuillez entrer votre TARIFMAX svp"></textarea>
              <p class="help-block text-danger"></p>
            </div>
          </div>
        </div>
          <input style="display:none" type="text" ref="startDateInput" name="DEPART" class="form-control raleway-reg-p13" placeholder="Date de départ" id="depart">
          <input style="display:none" type="text" ref="endDateInput" name="RETOUR" class="form-control raleway-reg-p13" placeholder="Date de retour" id="retour">

          <div class="made-trip-duration container mb-3">
                <HotelDatePicker
                  :startDate="minDate"
                  :endDate="maxDate"
                  :format="'DD/MM/YYYY'"
                  :gridStyle="false"
                  :hoveringTooltip="false"
                  :i18n="cFR"
                  ref="datePicker"
                  @periodSelected="selectDates"
                ></HotelDatePicker>
           </div>

          <div class="container">
            <div class="row">
              <div :class="{ 'col-12 mt-5': isPhone, 'col-6': !isPhone }">
                <v-select id="flexible" name="FLEXIBLE" class="select-item raleway-bold-p13 text-center" placeholder="Êtes-vous flexibles sur les dates ?" v-model="flexibleSelected" :options="flexibleChoices"></v-select>
                <p class="help-block text-danger"></p>
              </div>
              <div :class="{ 'col-12 mb-3': isPhone, 'col-6 mb-3': !isPhone }">
                <multiselect
                    name="BESOIN"
                    :options="needChoices"
                    :multiple="true"
                    placeholder="De quoi avez-vous besoin ?"
                    v-model="needSelected">
                </multiselect>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div :class="{ 'col-12 mb-3': isPhone, 'col-6 mb-3': !isPhone }">
                <multiselect
                    name="ETABLISSEMENT"
                    :options="lodgeTypeChoices"
                    :multiple="true"
                    placeholder="Quel type d’établissement privilégiez vous ?"
                    v-model="lodgeTypeSelected">
                </multiselect>
              </div>
              <div :class="{ 'col-12 mb-3': isPhone, 'col-6': !isPhone }">
                <multiselect
                    name="TYPEVOYAGEUR"
                    :options="travelerChoices"
                    :multiple="true"
                    placeholder="Quel type de voyageur êtes-vous ?"
                    v-model="travelerSelected">
                </multiselect>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <textarea type="text" name="INCONTOURNABLE" class="form-control raleway-reg-p13" 
                          :placeholder="isPhone
                              ? 'Quels sont les incontournables que vous\nsouhaitez voir/faire pendant votre séjour ?'
                              : 'Quels sont les incontournables que vous souhaitez voir/faire pendant votre séjour ?'"
                          id="incontournable" 
                          data-validation-required-message="Veuillez entrer votre INCONTOURNABLE svp"></textarea>
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div :class="{ 'col-12': isPhone, 'col-6': !isPhone }">
                <textarea type="text" name="BUDGET" class="form-control raleway-reg-p13"
                  :placeholder="isPhone
                                ? 'Quel est votre budget total pour \nce séjour ?'
                                : 'Quel est votre budget total pour ce séjour ?'"
                  placeholder="Quel est votre budget total pour ce séjour ?"
                  id="budget" data-validation-required-message="Veuillez entrer votre BUDGET svp"></textarea>
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <textarea name="PROJET" class="form-control raleway-reg-p13"
                  placeholder="Description du projet" id="projet" 
                  data-validation-required-message="Veuillez entrer votre DEPART svp"></textarea>
                <p class="help-block text-danger"></p>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <button class="hortense-btn-primary" id="my-form-button" @click="submit">Envoyer</button>
                <p id="my-form-status"></p>
              </div>
            </div>
          </div>
        </form>
        <div class="home-button">  
          <button class="hortense-btn-primary" id="my-home-button" @click="back" v-show="false">RETOURNER SUR LA PAGE D'ACCUEIL</button>
        </div>
    </div>
    
    <h1 class="text-center mt-5" :class="{ 'merriweather-reg-30': !isPhone, 'merriweather-reg-20': isPhone }">
    <i>Des lieux exceptionnels où luxe et durabilité sont indissociables</i>
    </h1>
    <div id="footer-made-trip" class="mt-4"></div>
    <!-- Place this script at the end of the body tag -->
  </div>
  </div>
</template>


<script>
  import Multiselect from 'vue-multiselect';
  import { VueDatePicker } from "@mathieustan/vue-datepicker";

  export default {
    name: 'madetrip',
    components: { VueDatePicker, Multiselect},

    data() {
      return{
        currentDate: new Date(),
        cFR: {
        night: "Nuit",
        nights: "Nuits",
        week: "semaine",
        weeks: "semaines",
        "day-names": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        "check-in": "Date de départ",
        "check-out": "Date de retour",
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
          tooltip: {
            halfDayCheckIn: "Available CheckIn",
            halfDayCheckOut: "Available CheckOut",
            saturdayToSaturday: "Only Saturday to Saturday",
            sundayToSunday: "Only Sunday to Sunday",
            minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum.",
          },
        },
        selectedDate: null,
        datepickerFormat: 'DD/MM/YYYY',
        datepickerLanguage: 'fr',
        travelerChoices: [
          "Aventurier",
          "Food-Lover",
          "Amoureux de la nature",
          "Romantique",
          "Fêtard",
          "Instagrammer",
          "Farniente sur une chaise longue",
          "Globe-trotter",
          "Voyageurs 5 étoiles",
          "Explorateur culturel",
          "Urbain",
          "Autre"
        ],
        lodgeTypeChoices: [
          "Hôtels",
          "Chambres d'hôtes",
          "Gites & Maisons",
          "Villas",
          "Chalets",
          "Lieux insolites",
          "Autre"
        ],
        needChoices: [
          "Hébergement seul",
          "Hébergement + petit déjeuner",
          "Hébergement + demi-pension",
          "Hébergement + pension complète"
        ],
        flexibleChoices: [
          "Oui",
          "Non"
        ],
        sexChoices: [
          "Mme",
          "Mr"
        ],
        tripTypeChoices: [
          "Voyage en famille",
          "Voyage en couple",
          "Voyage entre amis",
          "Voyage solo"

        ],
        adultChoices: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10"
        ],
        childChoices: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10"
        ],
        objectSelected: null,
        sexSelected: "",
        tripTypeSelected: null,
        adultSelected: null,
        childSelected: null,
        flexibleSelected: null,
        minDate: new Date().toISOString().substr(0, 10),
        needSelected: [],
        lodgeTypeSelected: [],
        travelerSelected: [],
      }
    },
    mounted(){
      let svgElements = document.querySelectorAll('.vs__open-indicator');
      svgElements.forEach(function(svgElement) {
        let pathElement = svgElement.querySelector('path');
        pathElement.setAttribute('d', 'M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z');
        pathElement.setAttribute('fill', '#3B6152');
        svgElement.setAttribute('viewBox', '5 5 15 15');
      });
    },
    computed: {
     minDate() {
      return new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
    },
    maxDate() {
      return new Date(
        this.currentDate.getFullYear() + 1,
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
     },
     isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
     }
    },
    methods: {
      tripTypeSelectionChange(selectedValues){
        document.getElementById('tripType').value = selectedValues;
      },
      adultSelectionChange(selectedValues) {
        document.getElementById('adult').value = selectedValues;
      },
      childSelectionChange(selectedValues) {
        document.getElementById('child').value = selectedValues;
      },
       flexibleSelectionChange(selectedValues) {
        document.getElementById('flexible').value = selectedValues;
      },
      formatDate(date) {
        const set = new Date(date);
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return set.toLocaleString("fr", options);
      },
      selectDates(event, start, end) {
        document.getElementById('depart').value = this.formatDate(start);
        document.getElementById('retour').value = this.formatDate(end);
        //this.$refs.startDateInput.value = this.formatDate(start);
        //this.$refs.endDateInput.value = this.formatDate(end);
        console.log('start')
        console.log(document.getElementById('depart').value)
        console.log('end')
        console.log(document.getElementById('retour').value)
      },
      handleSubmit: async function(event) {
      event.preventDefault();
      const status = document.getElementById("my-form-status");
      const form = document.getElementById("my-form");
      const myHomeButton = document.getElementById("my-home-button");
      const tripDescription = document.getElementById("trip-description");
      const focusElement = document.getElementById("my-form-container");
      const data = new FormData(event.target);
      data.append("SEX", this.sexSelected);
      data.append("TYPEVOYAGE", this.tripTypeSelected);
      data.append("ADULTE", this.adultSelected);
      data.append("ENFANT", this.childSelected);
      data.append("FLEXIBLE", this.flexibleSelected);
      data.append("BESOIN", this.needSelected);
      data.append("ETABLISSEMENT", this.lodgeTypeSelected);
      data.append("TYPEVOYAGEUR", this.travelerSelected);

      try {
        const response = await fetch(event.target.action, {
          method: "POST",
          body: data,
          headers: {
            'Accept': 'application/json'
          }
        });

        form.innerHTML = "Bonjour,<br/>Merci pour votre confiance.<br/>Nous reviendrons vers vous sous 48h par e-mail ou par téléphone.</br>Belle journée,<br/>L'équipe Hortense";
        form.classList.add("raleway-it-l15");
        form.classList.add("text-center");
        tripDescription.style.display = "none";
        myHomeButton.style.display = "block";
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
          const windowHeight = window.innerHeight;
          const targetHeight = windowHeight * 0.9;
          window.scrollTo({
            top: targetHeight
          });
        }
        else{
          myHomeButton.focus();
        }
        //this.$refs.myForm.reset();

        console.log("Form submitted successfully:", response);
      } catch (error) {
        status.innerHTML = "Oops! There was a problem submitting your form";
        console.error("Error submitting form:", error);
      }
    },
    submit(){
        const form = document.getElementById("my-form");
        form.addEventListener("submit", this.handleSubmit); // Attach the arrow function as the event listener
      },
      back(){
        window.location.href = '/';
      }
   },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/contact-form'>
.home-button {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 8rem;
      margin-bottom: 8rem;
    }

/*#my-form{
  input {
    color: #FFFFFF;
  }
}*/
#my-form input::placeholder {
  color: #000000 !important;
}

#my-form textarea::placeholder {
  color: #000000 !important;
}
.multiselect__placeholder {
  font-family: Raleway !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #000000 !important;
}
.select-item {
  color: #4e6a86;
}
.v-select {
  font-family: Raleway;
  font-weight: 400;
  font-size: 13px;
  color: #4e6a86;
}

.v-select::placeholder {
  font-family: Raleway !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #000000 !important;
}


#my-form {
  #my-form-button {
    width: 15vw;
    display: inline;
    background-color: #3B6152;
  }
  #projet {
   height: 10vw; 
  }
}
#footer-made-trip {
  background-image: url("../../images/made-trip/voyage-sur-mesure-footer.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 25vw;
}
.container-index-trip {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15vh;
  overflow: hidden;
}
#banner-made-trip {
    width: 100%;
    height: 694px;
    margin-top: 20vh;
    background-image: url("../../images/made-trip/voyage-sur-mesure-soleil.png");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    .banner-show-container {
      width: 100%;
      height: 650px;
      .catch-hub-show {
        color: white;
        margin-top: 4rem;
      }
      #desire-p {
          color: white;
          margin-left: 5vw;
      }
    }
    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;
      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }
    .btn-galerie-phone {
      display: none;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    /*.select-item {
     margin-top: 3.5rem;
    }*/
    #my-form {
        #my-form-button {
       width: 90%;
      }
      #projet {
       height: 20vw;
      }
    }
    .container-index-trip {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 15vh;
      overflow: hidden;
    }
    #banner-made-trip {
      margin-top: 5vh;
      display: block;
      .btn-galerie-phone {
        display: block;
        //width: 100%;
        margin-left: 1rem;
        .galerie-icon {
          background-color: rgba(0, 0, 0, 0.4);
          // opacity: 0.4;
          border-radius: 60px;
          padding: 1rem 2rem;
          color: white;
          width: 152px;
          height: 48px;
        }
      }
      .btn-galerie {
        display: none;
      }
      .banner-show-container {
        width: 100%;
        height: 700px;
        display: flex;
        align-items: flex-end;
        .catch-hub-show {
          margin-left: 1rem;
          p {
            font-size: 26px;
          }
        }
        #desire-p {
          color: white;
          margin-left: 5vw;
          margin-bottom: 160%;
        }
      }
    }
  }
  .padding-desktop {
    padding-left: 15rem;
  }


//DatePicker style
.made-trip-duration {
    height: 100%;
    .vhd__datepicker__input:first-child {
      background: white !important;
      border-bottom: 1px solid #ced4da !important;
      width: 48.5% !important;
      color: #000000 !important;
    }
    .vhd__datepicker__input {
      color: #000000 !important;
      text-align: left !important;
      border: 1px solid #ced4da !important;
      font-family: Raleway !important;
      font-weight: 400 !important;
      font-size: 13px !important;
      width: 48.5% !important;
    }
    .vhd__datepicker__dummy-wrapper {
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      border-bottom: 0 !important;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      height: 70% !important;
    }
    .vd-picker__input input {
      border-bottom: 1px solid black !important;
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      text-align: left !important;
      font-family: $Raleway;
      font-weight: 400;
      font-size: 15px;
      color: #4e6a86;

      &::placeholder {
        color: #000000 !important;
      }
    }
    .vd-picker__table-day--selected .vd-picker__table-day__text {
      color: black;
    }
    span.vd-picker__table-day__effect {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 36px;
      height: 36px;
      border-radius: 0 !important;
      border: 1px solid;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .made-trip-duration {
      .vhd__datepicker__input:first-child {
        width: 100% !important;
        margin-bottom: 1rem !important;
      }
      .vhd__datepicker__input {
        width: 100% !important;
      }
      .vhd__datepicker__dummy-wrapper {
        margin-bottom: 5rem !important;
      }
    }
  }

  //Multi select style
  .multiselect__option {
        display: block;
        padding: 25px !important;
        min-height: 40px;
        line-height: 16px;
        text-decoration: none;
        text-transform: none;
        vertical-align: middle;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        //font-style: italic !important;
        font-size: 13px !important;
        font-family: $Merriweather !important;
        font-weight: 400 !important;
        color: #000000;
    }

    .multiselect__option::before {
        content: "" !important;
        position: absolute !important;
        left: 10px !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        width: 10px !important;
        height: 10px !important;
        background-color: white !important;
        border: 1px solid #3B6152 !important; /* Add a border to the rectangle */
        box-sizing: border-box; /* Ensure that the border doesn't affect the dimensions of the rectangle */
    }

    .multiselect__option--selected {
      background: white !important;
      color: #4e6a86 !important;
      font-weight: 400 !important;
    }

    .multiselect__option--selected:after {
      content: "" !important;
      color: #4e6a86 !important;
    }
    .multiselect__option--selected::before {
        content: "" !important; /* Empty content to create the icon */
        position: absolute !important; /* Absolute positioning relative to the parent element */
        left: 10px !important; /* Position the icon 10px from the left */
        top: 50% !important; /* Vertically center the icon */
        transform: translateY(-50%) !important; /* Adjust vertical alignment */
        width: 10px !important; /* Set the width of the icon */
        height: 10px !important; /* Set the height of the icon */
        background-color: #3B6152 !important; /* Set the background color of the icon */
    }

    .multiselect__option--highlight {
      background: white !important;
      outline: none;
      color: #000000 !important;
    }

    .multiselect__option--highlight:after {
      content: "" !important;
      background: white !important;
      color: #4e6a86 !important;
    }

    .multiselect__option--selected.multiselect__option--highlight {
      background: white !important;
      color: #4e6a86 !important;
    }

   .multiselect__option--selected.multiselect__option--highlight:after {
      background: white !important;
      content: "" !important;
      color: #4e6a86 !important;
   }

   .multiselect__tag {
      background: #3B6152;
   }

   .multiselect__select:before{
    border-color: #3B6152 transparent transparent !important;
    border-width: 10px 7px 0 !important;
    margin-left: 10px !important;
   }

  //Change the arrows
  .vs__clear svg {
    display: none !important;
  }

  .vs__open-indicator path {
    fill: #3B6152 !important; 
    stroke: none !important;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
