<template>
  <div id="becomeHost">
    <div id="header">
      <div class=" text-center ">
        <h2 class="merriweather-reg-40">VOUS ÊTES HÔTELIER</h2>
        <p class="raleway-reg-p15">propriétaire ou directeur d’un établissement et souhaitez faire partie de l’aventure Hortense ?</p>
        <p class="raleway-reg-p15">Contactez-nous via ce formulaire, nous vous recontacterons dans les meilleurs délais.</p>        
      </div>
    </div>
    <div class="form-container">
      <form action="https://formspree.io/f/xqknyled" method="POST" >
        <div class="form-signup raleway-reg-p15">
          <div class="form-item">
            <input class="border-input" name="NOM" id="name" type="text" placeholder="Prénom">
          </div>
          <div class="form-item">
            <input class="border-input" name="NOM" id="name" type="text" placeholder="Nom">
          </div>
          <div class="form-item">
            <input class="border-input" type="email" name="Email" id="email" placeholder="Email">
          </div>
          <div class="form-item">
            <input class="border-input" name="telephone" id="telephone" type="text" placeholder="Numéro de téléphone">
          </div>
          <div class="form-item">
            <input class="border-input" type="text" name="poste" id="poste" placeholder="Poste occupé au sein de l’établissement">
          </div>
          <div class="form-item">
            <input class="border-input" type="text" name="hébergement" id="hébergement" placeholder="Nom de l'établissement">
          </div>
          <div class="form-item">
            <input class="border-input" name="address" id="address" type="text" placeholder="Adresse de l’établissement">
          </div>
          <div class="form-item">
            <input class="border-input" name="website" id="website" type="text" placeholder="Site web de l’établissement">
          </div>
          <div class="form-item">
            <p class="raleway-bold-p">Message*</p>
            <textarea  class="w-100 mb-2" cols="45" rows="6" type="text" name="Message" id="message"></textarea>
          </div>
          <button type="submit" class="hortense-btn-primary w-50 btn-submit-become">Je valide</button>
        </div>
      </form>
    </div>
    <div class="footer-container-become">
      <div class="become d-flex">
        <p class="raleway-reg-p15 mr-3">Les champs marqués d’un * sont obligatoires.</p>
      </div>
      <div class="knew-more">
        <p class="raleway-reg-p15">En savoir + sur Hortense :</p>
        <ul>
          <li>Découvrez la <a href="/green-note-engagement-environnemental/" class="link-hortense">Green Note</a>, un outil développé par Hortense qui permet de mesurer le niveau d’engagement éco-responsable des hébergements partenaires.</li>
          <li>Découvrez <a href="/programme-evergreen-demarche-environnementale/" class="link-hortense">le Programme Evergreen</a> : Hortense accompagne ses partenaires dans leur démarche environnementale via une aide opérationnelle et financière. </li>
          <li>Découvrez <a href="/label-ecoresponsable-green-host/" class="link-hortense">le label Green Host</a> qui met en avant les hébergements de charme qui répondent aux critères de respect de l’environnement Hortense.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
    import fetcher from 'packs/shared/api.js';

  export default{
    name: 'becomeHost',
    data() {
      return {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        post: '',
        name: '',
        address: '',
        website: '',
        message: '',
      }
    },
  }
</script>

<style lang="scss" scoped>
  #becomeHost{
    display: grid;
    #header{
      margin-top: 12%;
    }
    .form-container{
      border: 1px solid #CCC;
      margin: 3% 30%;
      padding: 2%;
    }

    .border-input{
      border: none;
      border-bottom: 1px solid #ccc;
      width: 100%;
      padding: 0.5rem;
      margin-bottom: 2%;
    }

    .footer-container-become{
      margin: 1% 28%;
      padding: 2%;
    }

    .btn-submit-become{
      margin: auto 23%;
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
      #header{
        margin-top: 25%;
        padding: 5%;

        h2{
          font-size: 24px;
        }
      }
      .form-container{
        margin: 3% 5%;
        padding: 3%;
      }

      .footer-container-become{
        margin: 1% 5%;
      }
    }
  }

</style>
