<template>
  <div class="container  justify-content-md-center mt-md-5 mt-lg-5 pt-md-4 pt-lg-4 pt-3 mt-3">
    <div class="pt-4">
    <h3 v-if="!isPhone" style="font: normal normal normal 26px/33px Merriweather;">Votre demande de réservation</h3>
      <div class="form-row">
        <div class="form-group col-md-3">
          <select class="form-control" id="exampleFormControlSelect1">
            <option>Mme</option>
            <option>Mr</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <input type="text" class="form-control" id="inputEmail4" placeholder="Nom" v-model='yogaReservation.name'>
        </div>
        <div class="form-group col-md-5">
          <input type="text" class="form-control" id="inputEmail4" placeholder="Prénom" v-model='yogaReservation.first_name'>
        </div>
      </div>
      <!-- row 2 -->
      <div class="form-row">
        <div class="form-group col-md-6">
          <input type="email" class="form-control" id="inputEmail4" placeholder="Adresse e-mail" v-model='yogaReservation.email'>
        </div>
        <div class="form-group col-md-6">
          <input type="text" class="form-control" id="inputEmail4" placeholder="Téléphone" v-model='yogaReservation.phone'>
        </div>
      </div>
      <!-- row 3 -->
      <div class="form-row">
        <div class="form-group col-md-12">
          <select id="inputState" class="form-control" v-model="yogaReservation.yoga_rate_id" @change="setPrice($event)">
            <option value="" disabled selected hidden>Les tarifs</option>
            <option v-for="rate in this.yoga_rate.rates" :value="rate[0]" :disabled="optionIsDisabled(rate[3])"> {{ rate[1] }} : {{ rate[2] }} € {{ rate[3] === "expired" ? "  EPUISE" : ""}}  </option>
          </select>
        </div>
      </div>
      <p style="font: italic normal normal 13px/15px Raleway;">Possibilité de régler le séjour en 3 fois avec KLARNA et PAYPAL.</p>

      <div class="form-row" v-if="this.yogaReservation.total">
        <div class="form-group col-md-6">
          <input id="code_input" v-model="code_promo" type="text" class="form-control"  placeholder="Code exclusif">
        </div>
        <div class="form-group col-md-6">
          <button style="height: 2% !important;" id="code_button"  @click.prevent="checkLoyaltyCode" class="hortense-btn-primary">Valider</button>
        </div>
      </div>
      <div class="error-message" v-if="this.loyaltyCodeValidity != '' && this.loyaltyCodeValidity != 'true' " :class="{ 'error-text': 'Invalid Code' }">
        Code invalide
      </div>

      <div class="form-row">
        <div class="form-group col-md-6"></div>
        <div class="form-group col-md-6">
          <input type="text" disabled class="form-control" id="inputEmail7" placeholder="Total à régler : 0€ ">
        </div>
      </div>

      <div class="form-row justify-content-center">
        <label class="small-checkbox-label">
        <input type="checkbox" id="wede" class="small-checkbox"  value="true" v-model="isChecked" />
        <p class="raleway-reg-p15 content">J’accepte les <a href="/conditions-generales-ventes"  class="black-link">conditions générales de vente</a></p>
          </label>
      </div>

      <div class="form-row justify-content-center">
        <button :disabled="!isChecked" type="submit" @click="createReservation" class="hortense-btn-primary">JE RÉSERVE</button>
      </div>

    </div>

<!--    <div class="row pt-5">-->
<!--      <div class="col-md-6 col-lg-6 col-sm-12 col-12 pt-4 round-b" >-->
<!--        <h6 style="font: italic normal medium Raleway;" class="pb-2" >NON INCLUS DANS LE SEJOUR</h6>-->
<!--        <p style="font: italic normal normal 15px/18px Raleway;">- Transport : le transport pour se rendre sur place. Les transferts entre les gares/aéroports et le lieu du séjour. Le transfert gares/aéroports peut être proposés en option pour certains lieux moyennant un coût supplémentaire.</p>-->
<!--        <p style="font: italic normal normal 15px/18px Raleway;">  - Assurance : l'assurance voyage (garanties annulation, frais médicaux, assurance bagages, responsabilité civile, assistance rapatriement médical...)</p>-->
<!--      </div>-->
<!--    </div>-->

    <div class="row pt-5">
      <div class="col-md-6 col-lg-6 col-sm-12 col-12 pt-4 round-b" >
        <h6 class="pb-2 raleway-bold-p16 ml-4 mb-0" >CONDITIONS D’ANNULATION </h6>
        <div class="raleway-reg-p15" v-html="this.yoga_rate.cancel_terms"></div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-12 pt-4 round-b" >
        <h6 class="pb-2 raleway-bold-p16 ml-4 mb-0" >Informations Importantes</h6>
        <div class="raleway-reg-p15" v-html="this.yoga_rate.early_bird"></div>
      </div>
    </div>
    <div class="col-lg-12 col-xs-12 col-md-12 col-12 pt-5">
      <div class="cancel-conditions">
        <div class="head d-flex">
          <img src="../../images/info-blue.svg" alt="info" />
          <p class="raleway-bold-p16 ml-4 mb-0">NON INCLUS DANS LE SEJOUR</p>
        </div>
        <div v-if="!isPhone" class="row pt-3">
          <p class="col-6 raleway-reg-p15 content">
            • Transport : le transport pour se rendre sur place. Les transferts entre les gares/aéroports et le lieu du séjour. Le transfert gares/aéroports peut être proposé en option pour certains lieux moyennant un coût supplémentaire.
          </p>
          <p class="col-6 raleway-reg-p15 content" style="line-height: 20px !important;">
            • Assurance : l'assurance voyage (garanties annulation, frais médicaux, assurance bagages, responsabilité civile, assistance rapatriement médical...)
          </p>
        </div>
        <div v-if="isPhone" class="pt-3">
          <p class="raleway-reg-p15 content" style="line-height: 20px !important;">
            • Transport : le transport pour se rendre sur place. Les transferts entre les gares/aéroports et le lieu du séjour. Le transfert gares/aéroports peut être proposés en option pour certains lieux moyennant un coût supplémentaire.
          </p>
          <p class="raleway-reg-p15 content" style="line-height: 20px !important;">
            • Assurance : l'assurance voyage (garanties annulation, frais médicaux, assurance bagages, responsabilité civile, assistance rapatriement médical...)
          </p>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import fetcher from "../../packs/shared/api";

export default{
  props: ['yoga_rate','yoga_id'],
  name:'YogaPayment',
  data(){
    return{
      code_promo: "",
      loyaltyCodeValidity: "",
      loyaltyCode: null,
      isChecked: false,
      is_urban: this.yoga_rate.yoga_city.includes('Urban Retreat'),
      yogaReservation: {
        name: "", first_name: "", email: "", phone: "", total: "", yoga_rate_id: "", promo: 0, promo_type: "", promo_id: null
      }
    }
  },
  methods: {
    setPrice(event) {
      this.yogaReservation.total = this.yoga_rate.rates.filter(e => e[0] == this.yogaReservation.yoga_rate_id )[0][2];
      $("#inputEmail7").attr('placeholder', 'Total à régler : ' + this.yogaReservation.total + '€' );
      if(!!this.code_promo)
      {
        this.checkLoyaltyCode();
      }
    },
    async createReservation(){
      if(this.yogaReservation.email != "" && this.yogaReservation.phone != "" && this.yogaReservation.name != "" && this.yogaReservation.first_name != "") {
        try {
          const response = await fetcher.post('/payments/yoga_reservation/' + this.yoga_rate.id, {reservation: this.yogaReservation});
          window.location.href = response.data;
          this.yogaReservation = {name: "", first_name: "", email: "", phone: "", total: "", yoga_rate_id: "", promo: 0, promo_type: "", promo_id: null};
          return false;
        } catch(e) {
          this.text = 'une erreur est survenue';
          console.error(e);
        }
      }
        else{
        alert("All fields must be filled out");
      }
    },
    optionIsDisabled(option){
      return option === "expired";
    },
    async checkLoyaltyCode(){
      try {
        console.log("urban", this.is_urban)
        const today = new Date();
        today.setDate(today.getDate());
        const response = await fetcher.get('/user_codes/' +  this.code_promo + '?is_urban=' + this.is_urban + '&yoga_id=' + this.yoga_id);
        const codeData = response.data;
        this.loyaltyCode = codeData;
        if (codeData && codeData.activation_code) {
          this.yogaReservation.total = this.yogaReservation.total - parseInt(codeData.amount);
          this.yogaReservation.total = this.yogaReservation.total < 0 ? 0 : this.yogaReservation.total
          this.yogaReservation.promo = parseInt(codeData.amount)
          this.yogaReservation.promo_type = "Amount"
          this.yogaReservation.promo_id = codeData.id
          var myButton = document.getElementById("code_button");
          var myInput = document.getElementById("code_input");
          myInput.disabled = true;
          myButton.disabled = true;
          $("#inputEmail7").attr('placeholder', 'Total à régler : ' + this.yogaReservation.total + '€');
        }
        else if( codeData && codeData.code_status === true && today <= new Date(codeData.expiry_date)
            && today >= new Date(codeData.validity_date)
            && !codeData.code_utilization_date)
        {
          if(codeData.code_type === 3 || codeData.code_type === 4)
          {
            this.loyaltyCodeValidity = 'true';
            if(codeData.code_type_description === "0"){
              this.yogaReservation.total = this.yogaReservation.total - parseInt(codeData.code_type_trigger);
              this.yogaReservation.total =  this.yogaReservation.total < 0 ? 0 :  this.yogaReservation.total
              this.yogaReservation.promo = parseInt(codeData.code_type_trigger)
              this.yogaReservation.promo_type = "Amount"
              this.yogaReservation.promo_id = codeData.id
              var myButton = document.getElementById("code_button");
              var myInput = document.getElementById("code_input");
              myInput.disabled = true;
              myButton.disabled = true;
              $("#inputEmail7").attr('placeholder', 'Total à régler : ' + this.yogaReservation.total + '€' );
            } else {
              this.yogaReservation.total  = this.yogaReservation.total  - ((this.yogaReservation.total  * parseInt(codeData.code_type_trigger)) / 100);
              this.yogaReservation.promo = parseInt(codeData.code_type_trigger)
              this.yogaReservation.promo_type = "Percentage";
              this.yogaReservation.promo_id = codeData.id
              var myButton = document.getElementById("code_button");
              var myInput = document.getElementById("code_input");
              myButton.disabled = true;
              myInput.disabled = true;
              $("#inputEmail7").attr('placeholder', 'Total à régler : ' + this.yogaReservation.total + '€' );
            }
          } else {
            this.loyaltyCodeValidity = 'notvalid';
          }
        }
          else {
            this.loyaltyCodeValidity = 'false';
        }
      } catch (e) {
        this.loyaltyCodeValidity = 'null';
        console.error(e);
      }
    },
  },
  computed: {
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>
<style scoped>
a.black-link {
  color: black;
  text-decoration: underline;
}
.small-checkbox-label {
  display: flex;
  align-items: center;
}

.small-checkbox {
  position: inherit;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-bottom: 15px;
}
.raleway-reg-p15{
  font-family: $Raleway;
  font-weight: 400;
  font-size: 15px;
}
.round-b{
  border: 2px solid #CFE5DC;
  border-radius: 0px 30px 30px 30px;
}
.col-conditions {
  display: block;
  justify-content: left;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    margin-top: 2rem;
  }

  .cancel-conditions {
    width: 100%;
    max-width: 400px;
    height: 233px;
    padding: 2rem 5rem 2rem 2rem;
    background-color: #f8f9ff;
    border-radius: 0px 20px 20px 20px;

    .head {
      align-items: center;
    }
    .content {
      margin-top: 1rem;
      margin-left: 2rem;
    }
  }
}
.error-message {
  color: red;
}

.error-text {
  font-weight: bold;
}
</style>