<template>
  <div id="destination">
    <div class="card-container">
      <div class="row row-hubs">
        <div class="col-4">
          <div class="destination-header">
            <h2 class="merriweather-reg-26">Nos</h2>
            <h2 class="merriweather-reg-40 mb-5">Destinations</h2>
            <p class="raleway-reg-p16">France, Caraïbes, Polynésie, Océan Indien, Maroc… Laissez-vous guider par notre sélection et trouvez la destination qui fera chavirer votre cœur.</p>
          </div>
            <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/destination'">Voir tous les hébergements</a>
        </div>
        <div class="col-8 pt-2">
          <div v-for='destination in destinationsMounted' data-aos="fade-up" data-aos-duration="1000">
            <CardDesti :destination="destination"></CardDesti>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

  import CardDesti from '../components/cards/CardDesti.vue';
  import AOS from 'aos';

  export default {
    name: 'destination',
    components: { CardDesti },
    props: {
      destinations: Array
    },
    options: {
      autoplay: false,
      transition: "slide",
      dots: true,
      animatedDots: false,
      dotLimit: false,
      fullscreen: false,
      fullscreenOffset: null,
      controls: true,
      numberOfSlides: 1,
      controlsWrapperClass: null,
      animationDuration: 500,
      animationEasing: "easeOutQuint",
      slidePosition: "center",
      slideClass: null,
      sliderClass: null,
      secondsOnSlide: 4,
      clickableSlides: false,
      endless: false,
      cuts: "right",
      gap: 30,
      adaptiveHeight: false,
      loop: true,
      extras: 3,
      overflowHiddenPadding: { top: 0, left: 0, right: 0, bottom: 0 },
      touch: true,
      preserveGrid: false,
      swipeTolerance: 80
    },
    data() {
      return {
        destinationsMounted: this.destinations,
      }
    },
    mounted(){
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/destination'>

</style>
