<template>
  <div class="reservation-card" :class="{ 'w-100': isEntire }" v-if="item">
    <div v-if="!isEntire" class="room-name mt-5">
      <p class="raleway-reg-p24 mb-0">{{ name }}</p>
      <div class="capacity d-flex">
        <p class="raleway-reg-p24 mb-0 mr-2">{{ this.item.capacity }}</p>
        <img src="../../images/profil.svg" alt="" />
      </div>
    </div>
    <div class="reservation-flex">
      <div v-if="!isEntire" class="hub-img-zoom">
        <img :src="image" alt="room photo" class="hub-img" />
        <ModalRoomGalery
          :item="this.item"
          v-if="modalGallery"
          @close="modalGallery = false"
        ></ModalRoomGalery>
        <div class="btn-galerie">
          <div class="galerie-icon df-jsb-aic">
            <img src="../../images/Galerie icone.svg" alt="galerie" />
            <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
          </div>
        </div>
      </div>

      <div class="reservation-description">
        <div v-if="!isEntire" class="grid">
          <div class="center">
            <p><strong>Chambre</strong> {{ item.room_type }}</p>
            <p><strong>Lit</strong> {{ item.bed_type }}</p>
            <p><strong>Superficie</strong> {{ item.area }}m²</p>
            <p><strong>Vue</strong> {{ item.view_on }}</p>
            <p v-if="!isEntire"><strong>Capacité</strong> {{ formatCapacity(item.capacity) }}</p>
          </div>
          <div class="center">
            <p class="raleway-bold-p15">Équipements</p>
            <p
              v-for="service in item.service_rooms.slice(0, this.value)"
              :key="service.id"
            >
              {{ service.name }}
            </p>
          </div>
        </div>
        <div v-if="this.item.service_rooms && !isEntire">
          <div v-if="this.value >= this.item.service_rooms.length" class="pagination-hub d-flex mt-3">
            <a @click="SeeLessService" class="link-hortense">
              voir moins <img src="../../images/Fleche haut.svg" alt="fleche bas"
            /></a>
          </div>
          <div v-else class="pagination-hub d-flex mt-3">
            <a @click="SeeMoreService" class="link-hortense">
              voir plus
              <img src="../../images/flechebas-simple.svg" alt="fleche bas"
            /></a>
          </div>
       </div>

      <!--KOEDIA-->
      <!--<div v-if="this.accomodationCode && !item.is_entire">-->
      <div v-if="this.accomodationCode">
        <!--<div v-if="!this.roomAvailability || this.roomAvailability.options.length === 0 || isSearching" class="reservation-box">-->
        <div v-if="!this.priceOptions || this.priceOptions.length === 0 || isSearching" class="reservation-box">
          <div class="box-price box-price-no-search">
            <div class="box-middle d-flex">
              <button
                :disabled="disableButton"
                class="hortense-btn-primary"
                @click="$emit('expandCalendar');"
              >
                {{ bookButtonCaption }}
              </button>
            </div>
          </div>
          <div v-if="(!this.roomAvailability || this.roomAvailability.options.length === 0) && this.searched && minNights > nights">
            <p class="raleway-reg-p15"><i>Nombre de nuits minimum : {{ minNights }}.</i></p>
            <p class="raleway-reg-p15">Veuillez refaire votre recherche de disponibilité.</p>
          </div>
        </div>

        <div v-else v-for="(priceOption, idx) in priceOptions" :key="idx" class="reservation-box">
          <div class="box-price" :class="{ 'box-price-two-col': isEntire }">
            <div class="box-middle-two">
              <p class="raleway-semi-p15">{{ cancellationMessages[idx] }}</p>
              <p class="raleway-semi-p15">{{ boardType(idx) }}</p>
            </div>
            <div class="box-middle">
              <div v-if="roomAvailability">
                <p class="raleway-reg-p15">{{ pricePerNightTtc(idx) }}€ par nuit</p>
                <div class="taxes">
                  <p class="raleway-semi-p13 mb-0">{{ totalTtc(idx) }}€ au total</p>
                  <p class="raleway-reg-p13">Taxes et frais compris</p>
                </div>
              </div>
            </div>
            <div class="box-middle d-flex" :class="{ 'box-two-col': isEntire }">
              <button :disabled="disableButton" class="hortense-btn-primary" @click="sendInfo(idx)">
                {{ bookButtonCaption }}
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <!-- NOT KOEDIA -->
      <div v-if="!this.accomodationCode">
        <div v-if="!this.roomAvailabilityNotKoedia || this.roomAvailabilityNotKoedia.length === 0 || isSearching" class="reservation-box">
          <div class="box-price box-price-no-search">
            <div class="box-middle d-flex">
              <button
                :disabled="disableButtonNotKoedia"
                class="hortense-btn-primary"
                @click="$emit('expandCalendar');"
              >
                {{ bookButtonCaptionNotKoedia }}
              </button>
            </div>
          </div>
        </div>
        <div v-else v-for="tariff in this.tariffsNotKoedia">
            <div class="reservation-box">
              <div v-if="pricePerNightTtcNotKoedia(tariff)" class="box-price" :class="{ 'box-price-two-col': isEntire }">
                  <div class="box-middle">
                      <p v-for="idx in tariff.split(',')" class="raleway-semi-p15">{{ optionHash[idx] }}</p>
                  </div>
                <div class="box-middle">
                    <!--<div v-if="this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length != 0">-->
                     <p class="raleway-reg-p15">{{ pricePerNightTtcNotKoedia(tariff) }}€ par nuit</p>
                     <div class="taxes">
                        <p class="raleway-semi-p13 mb-0">{{ totalTtcNotKoedia(tariff) }}€ au total</p>
                        <p class="raleway-reg-p13">Taxes et frais compris</p>
                     </div>
                </div>
                <div class="box-middle d-flex" :class="{ 'box-two-col': isEntire }">
                  <button :disabled="disableButtonNotKoedia" class="hortense-btn-primary" @click="sendInfo(tariff)">
                    {{ bookButtonCaptionNotKoedia }}
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>
      









      </div>
    </div>
  </div>
</template>

<script>
import VueImageSlider from "vue-image-slider";
import ModalRoomGalery from "../../components/modals/ModalRoomGalery.vue";
import fetcher from "packs/shared/api.js";

export default {
  name: "ReservationCard",
  components: { VueImageSlider, ModalRoomGalery },
  props: {
    minNights: Number,
    lodging: Object,
    koediaAvailability: Boolean,
    item: Object,
    searched: Boolean,
    isSearching: Boolean,
    nights: Number,
    from: Date,
    to: Date,
    roomAvailability: Object,
    roomAvailabilityNotKoedia: [],
    numberOfRoom: {
      default: 0,
      type: Number,
    },
    isEntire: Boolean,
    taxNotKoedia: Object,
    accomodationCode: Object,
    koediaFrom: String,
    koediaTo: String,
    tariffsNotKoedia: [],
    koediaSessionId: String,
  },
  data() {
    return {
      tvaNotKoedia: Number,
      houseHoldNotKoedia: String,
      checkIn: null,
      checkOut: null,
      // imageArray: this.item.photos_keys,
      modalGallery: false,
      value: 3,
      subTotal: 0,
      gettingRateDetails: true,
      rateDetails: null,
      roomChoosedCount: 0,
      price: null,
      status: {
        getRateDetails: null,
      },
      cancellationMessages: {},
      image: `${
        this.item.photo_banner ??
        "https://images.unsplash.com/flagged/photo-1556438758-8d49568ce18e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80"
      }`,
       pricingHash: {
        pn: 'breakfast_notinclude_pricing',
        pd: 'breakfast_include_pricing',
        cf: 'cancellation_free_pricing',
        nr: 'non_refundable_pricing',
        ai: 'all_inclusive_pricing',
        hp: 'half_pension_pricing',
        fp: 'full_pension_pricing',
        cc: 'cancel_condition_pricing'
      },
      optionHash: {
        pn: 'Petit déjeuner non inclus',
        pd: 'Petit déjeuner inclus',
        cf: 'Annulation gratuite',
        nr: 'Non remboursable',
        ai: 'Tout inclus',
        hp: 'Demi-pension',
        fp: 'Pension complète',
        cc: 'Annulation sous conditions',
        cs: 'Chambre seulement',
        t1: 'Tarif pour 1 personne',
        t2: 'Tarif pour 2 personnes',
        n7: '7 nuits minimum',
        n6: '6 nuits minimum',
        n5: '5 nuits minimum',
        n4: '4 nuits minimum',
        n3: '3 nuits minimum',
        n2: '2 nuits minimum'
      },
      //image: { backgroundImage: `url("${this.item.photo_banner ?? "https://images.unsplash.com/flagged/photo-1556438758-8d49568ce18e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80" }")`}
    };
  },
  mounted(){
    console.log('tariffsNotKoedia');
    console.log(this.tariffsNotKoedia);
  },
  watch: {
    from: {
      immediate: true,
      handler(val) {
        this.checkIn = val;
      },
    },
    to: {
      immediate: true,
      handler(val) {
        this.checkOut = val;
      },
    },
    numberOfRoom: {
      immediate: true,
      handler(val) {
        this.roomChoosedCount = val;
      },
    },
    priceOptions: {
      handler(newOptions) {
        newOptions.forEach((option, idx) => {
          this.isRefundable(idx);
        });
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    },
    bookButtonCaption() {
      if (this.isSearching) {
        return "Recherche en cours...";
      } else if (!this.searched) {
        return "Disponibilité à confirmer";
      //} else if (this.isAvailable) {
      //  return "Réserver";
      //} else {
      //  return "Indisponible";
      //}
	  } else if (this.roomAvailability && this.roomAvailability.options.length != 0) {
        return "Réserver";
      } else if (!this.roomAvailability || this.roomAvailability.options.length === 0){
        console.log('this.roomAvailability');
        console.log(this.roomAvailability);
        return "Indisponible";
      }
	  
    },
    bookButtonCaptionNotKoedia() {
      if (this.isSearching) {
        return "Recherche en cours...";
      } else if (!this.searched) {
        return "Disponibilité à confirmer";
      } else if (this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length != 0) {
        return "Réserver";
      } else if (!this.roomAvailabilityNotKoedia || this.roomAvailabilityNotKoedia.length === 0){
        //console.log('WHY');
        //console.log(this.roomAvailabilityNotKoedia);
        return "Indisponible";
      }
    },
    name() {
      return this.item.name;
    },
    total() {
      if (this.checkIn && this.checkOut && this.item.availabilities) {
        const dateCheckIn = this.item.availabilities.find(
          (el) => el.date === this.formatDate(this.checkIn)
        );
        const indexFrom = this.item.availabilities.indexOf(dateCheckIn);
        const dateCheckOut = this.item.availabilities.find(
          (el) => el.date === this.formatDate(this.checkOut)
        );
        const indexTo = this.item.availabilities.indexOf(dateCheckOut);
        const sum = this.item.availabilities
          .slice(indexFrom, indexTo + 1)
          .reduce((acc, current) => acc + current.pricing, 0);
        return sum * this.numberOfRoom;
      }
    },
    isAvailable() {
      return this.roomAvailability;
    },
    disableButton() {
      return (this.searched && (!this.isAvailable || this.roomAvailability.options.length === 0)) || this.isSearching;
    },
    disableButtonNotKoedia() {
      return (this.searched && (!this.roomAvailabilityNotKoedia || this.roomAvailabilityNotKoedia.length === 0)) || this.isSearching;
    },
    priceOptions() {
      if (this.isAvailable) {
        console.log('DEBUG priceOptions');
        console.log(this.roomAvailability.options);
        return this.roomAvailability.options;
      }
    },
  },
  methods: {
    displayCalendar() {
      // ???
    },
    pricePerNightTtc(idx) {
      if (this.priceOptions) {
        return this.priceOptions[idx].room_price.per_day.rack;
      }
    },
    totalTtc(idx) {
      if (this.priceOptions) {
        return this.priceOptions[idx].room_price.total.rack.toFixed(2);
      }
    },
    async getRateDetails(idx) {
      if (this.accomodationCode) {
        this.gettingRateDetails = true;
        try {
          const res = await fetcher.post(`/lodgings/${this.lodging.slug}/get_rate_details`, {
            koedia_session_id: this.koediaSessionId,
            koedia_room_id: this.priceOptions[idx].room_id,
            start: this.koediaFrom,
            end: this.koediaTo,
            children: 0,
            children_ages: [],
          });
          this.rateDetails = res.data.get_rate_details_response.res_get_rate_details;
          if (this.priceOptions[idx].refundable) {
            const refundableToDay = Array.isArray(this.rateDetails.cancellation_fees_policy)
                ? this.rateDetails.cancellation_fees_policy[0].to_day
                : this.rateDetails.cancellation_fees_policy.to_day;

            const koediaFromDate = new Date(this.koediaFrom);
            koediaFromDate.setDate(koediaFromDate.getDate() - refundableToDay);

            const resultYear = koediaFromDate.getFullYear();
            const resultMonth = (koediaFromDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
            const resultDay = koediaFromDate.getDate().toString().padStart(2, '0');

            const resultDate = `${resultDay}-${resultMonth}-${resultYear}`;

            console.log('Resulting date after subtracting refundableToDay:', resultDate);

            return resultDate;
          }
        } catch (e) {
          this.status.getRateDetails = false;
          console.log("Failed getRateDetails");
          console.log(e);
          throw e; // Re-throw the error to handle it in isRefundable
        }
      }
    },
    pricePerNightTtcNotKoedia(tariff) {
      //console.log('pricePerNightTtcNotKoedia avails');
      //console.log(this.roomAvailabilityNotKoedia);
      if(this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length !== 0)
      {
          const pricing_unit = this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff).tariff_price;
          this.tvaNotKoedia = pricing_unit * (this.taxNotKoedia.tva_rate/100);
          this.houseHoldNotKoedia = this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff).house_hold_fee;
          return (pricing_unit + pricing_unit * (this.taxNotKoedia.tva_rate/100) + this.taxNotKoedia.tourist_tax).toFixed(2); 
      }
      return null;
    },
     priceHtNotKoedia(tariff) {
      if(this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length != 0)
      {
          return this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff).tariff_price;
      }
      return null;
    },
    totalTtcNotKoedia(tariff) {
      if(this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length != 0)
      {
          const pricing_unit = this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff).tariff_price;
          //console.log('UNIT PRICING');
          //console.log(pricing_unit);

          

          //console.log('NIGHTS');
          //console.log(this.nights);

          //console.log('tax');
          //console.log(this.taxNotKoedia.tourist_tax);

          //console.log('VALUE FINALE');

          //console.log(((pricing_unit + this.taxNotKoedia.tourist_tax + pricing_unit  * (this.taxNotKoedia.tva_rate/100)) * this.nights).toFixed(2));

          return ((pricing_unit + this.taxNotKoedia.tourist_tax + pricing_unit  * (this.taxNotKoedia.tva_rate/100)) * this.nights).toFixed(2);
      }
      return null;
    },
    async isRefundable(idx) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString('fr-FR');
      if (this.priceOptions) {
        try {
          const cancel = await this.getRateDetails(idx);
          console.log('cancel', cancel);
          this.$set(this.cancellationMessages, idx, this.priceOptions[idx].refundable ? `Annulation gratuite avant le ${cancel}` : "Non annulable");
        } catch (e) {
          console.log('Failed to get rate details:', e);
          this.$set(this.cancellationMessages, idx, "Error fetching cancellation details");
        }
      }
    },
    boardType(idx) {
      if (this.priceOptions) {
        return Object.values(this.priceOptions[idx].board)[0];
      }
    },
    personDetails(idx) {
      if (this.item) {
        const children = parseInt(this.priceOptions[idx].children);
        const adults = parseInt(this.priceOptions[idx].adults);
        const totalPeople = children + adults;
        const personneSuffix = totalPeople > 1 ? "personnes" : "personne";
        return `Tarif pour ${totalPeople} ${personneSuffix}`;
      }
    },
    personsAmount(idx) {
      if (this.item) {
        const children = parseInt(this.priceOptions[idx].children);
        const adults = parseInt(this.priceOptions[idx].adults);
        const childrenAmount = children > 1 ? this.priceOptions[idx].children + " Enfants" : this.priceOptions[idx].children +  " Enfant";
        const adultAmount = adults > 1 ? this.priceOptions[idx].adults + " Adultes" : this.priceOptions[idx].adults +  " Adultes";
        return `(${adultAmount}, ${childrenAmount})`;
      }
    },
    SeeMoreService(e) {
      e.preventDefault();
      this.value += 3;
    },
    SeeLessService(e) {
      e.preventDefault();
      this.value = 3;
    },
    showModalGallery() {
      this.modalGallery = !this.modalGallery;
    },

    formatCapacity(data) {
      if (data.length >= 1) {
        return `${data} personne`;
      } else {
        return `${data} personnes`;
      }
    },
    seeMore() {
      console.log(this.item);
    },
    // sendInfo() {
    //   this.$emit("sendInfo", this.item, this.numberOfRoom, this.total);
    // },
    sendInfo(idx) {
      window.scrollTo({ top: 0, left: 0 });
      if(this.accomodationCode)
      {
          this.$emit(
            "sendInfo",
            this.item,
            this.pricePerNightTtc(idx),
            this.totalTtc(idx),
            this.priceOptions[idx],
            idx
          );
          window.location.href = '/reservation-hotel/' + this.lodging.slug + '/reservation/confirm'
      }
      else
      {
          this.$emit(
            "sendInfo",
            this.item,
            this.pricePerNightTtcNotKoedia(idx),
            this.totalTtcNotKoedia(idx),
            this.roomAvailabilityNotKoedia,
            idx,
            this.tvaNotKoedia,
            this.priceHtNotKoedia(idx),
            this.houseHoldNotKoedia
          );
        window.location.href = '/reservation-hotel/' + this.lodging.slug + '/reservation/confirm'
      }
    },
    formatDate(date) {
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      let year = date.getFullYear();

      return year + "-" + month + "-" + day;
    },
    findPrice() {
      if (this.checkIn && this.checkOut && this.item.availabilities) {
        const dateCheckIn = this.item.availabilities.find(
          (el) => el.date === this.formatDate(this.checkIn)
        );
        const indexFrom = this.item.availabilities.indexOf(dateCheckIn);
        const dateCheckOut = this.item.availabilities.find(
          (el) => el.date === this.formatDate(this.checkOut)
        );
        const indexTo = this.item.availabilities.indexOf(dateCheckOut);

        const sum = this.item.availabilities
          .slice(indexFrom, indexTo + 1)
          .reduce((acc, current) => acc + current.pricing, 0);
        this.price = (sum / (indexTo - indexFrom + 1)).toFixed(2);
      }
      if (this.price) return this.price;
      else return this.item.pricing;
    },
  }
};
</script>

<style lang="scss" scoped>
.reservation-card {
  width: 94%;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.room-name {
  display: flex;

  .capacity {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
}
.reservation-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
}

.reservation-description {
  width: 100%;
  flex: 1 1 auto;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .center {
      // text-align: center;

      p {
        font-size: 0.8rem;
        margin: 0;
      }
    }
  }

  .breakline {
    width: 100%;
    border-bottom: 1px solid black;
    text-align: right;
    cursor: pointer;
    img {
      width: 2%;
    }
  }
}

.hub-img-zoom {
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  height: 362px;
  margin-right: 2rem;
  overflow: hidden;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  .hub-img {
    transition: transform 0.5s ease;
    object-fit: cover;
    height: 100%;
    width: 100%;
    aspect-ratio: 16/10;
  }

  .btn-galerie {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 152px;

    .galerie-icon {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 60px;
      padding: 1rem 2rem;
      color: white;
      width: 152px;
      height: 48px;
    }
  }

  .hub-img:hover {
    transform: scale(1.1);
  }
}

.box-price {
  width: 100%;
  min-height: 120px;
  margin: auto;
  margin-top: 2rem;
  border: 1px solid grey;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.box-price-two-col {
  grid-template-columns: repeat(2, 1fr);
}

.box-two-col {
  grid-column: 1 / 3; 
}

.box-price-no-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-middle {
  margin: auto;
  padding: 10px;
  .taxes {
    background-color: #f7f7f7;
  }
}

.box-middle-two {
  margin: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .taxes {
    background-color: #f7f7f7;
  }
}

.reservation-box {
  width: 100%;
  // height: 224px;
}

.green-inputer {
  background-color: $light-kaki;
  color: white;
  border: none;
  padding-left: 17px;
  width: 22%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .reservation-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
  }
  .reservation-description {
    width: 100%;

    .grid {
      display: flex;
      flex-direction: column;
    }
    .center {
      margin-top: 1rem;
    }
  }
  .hub-img-zoom {
    width: 100%;
    height: 224px;
    margin-right: 2rem;
    overflow: hidden;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .box-price {
    display: flex;
    flex-direction: column;
  }
}
</style>
