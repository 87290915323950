<template>
  <div id="card-green-night">
    <div class="head-card">
      <p class="raleway-reg-p24">Vos Green Nights</p>
      <p class="raleway-reg-p15">Vos points sont échangeables contre des nuits offertes : les Green Nights³. Vous pouvez réduire le montant total de votre séjour en utilisant vos points, directement depuis l’espace de réservation.</p>
      <p class="raleway-reg-p15">Vous pouvez échanger votre cagnotte contre 1 nuit offerte⁴. La valeur d’une nuit offerte est fixée à 100€.</p>
      <p class="raleway-reg-p15">500 points = 1 nuit offerte = 100€</p>
      <p class="raleway-reg-p15">³ Sous forme de bon d’achat à utiliser sous 12 mois. Si vous n’utilisez pas votre bon Green Night avant la date limite, votre cagnotte sera réduite de 500 points (valeur du bon).<br>⁴ Dans la limite de 1 nuit</p>
      <a :href="'/pages/cgu'" class="link-hortense">Conditions d’utilisation du programme de fidélité Hortensia</a>
    </div>
    <div class="green-section mt-4" v-if='this.greenNights'>
      <template v-for="greenNight in this.greenNights">
        <div class="green-night mb-3">
          <p class="raleway-bold-p mb-0">1 green night</p>
          <p class="raleway-reg-p15 mb-0">1 nuit offerte soit 150€</p>
          <p class="raleway-reg-p15 mb-0">Validité : {{ greenNight.expiration_date }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'card-green-night',
    props: ["user"],
    data() {
      return {
        userStatut: '',
        userMount: '',
        greenNights: '',
      }
    },
    methods: {
      async setUserLevel(){
        const res = await fetcher.get('/dashboard/'+this.user.slug)
        this.userMount = res.data
        this.userStatut = this.userMount.privilege.status
        this.greenNights = this.userMount.green_nights
      },
    },
    mounted() {
      this.setUserLevel();
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
