<template>
  <div id="lodging-show">
    <template v-if="isChoosing">
      <!-- Banner -->
      <template>
        <div
          id="banner-lodging"
          :style="getBackgroundStyle()"
        >
          <ModalGallery
            :lodging="this.lodging"
            :photoKeys="this.lodging.photos_keys"
            v-if="modalGallery"
            @close="modalGallery = false"
          ></ModalGallery>
          <div class="btn-galerie mb-3">
            <div class="galerie-icon df-jsb-aic">
              <img src="../../images/Galerie icone.svg" alt="galerie" />
              <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
            </div>
          </div>
          <div class="banner-show-container" v-if="this.lodging.lodging_description.catch_sentence">
            <div class="col-9 offset-3 catch-hub-show">
              <p class="merriweather-italic30">
                {{ this.lodging.lodging_description.catch_sentence }}
              </p>
            </div>
          </div>
          <div class="banner-show-container" v-else>
            <div class="col-lg-9 offset-lg-3 catch-hub-show">
              <p class="merriweather-italic30">Un havre aussi <br />éco-responsable que luxueux</p>
            </div>
          </div>
          <div class="btn-galerie-phone mb-3">
            <div class="galerie-icon df-jsb-aic">
              <img src="../../images/Galerie icone.svg" alt="galerie" />
              <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
            </div>
          </div>
        </div>
        <div id="banner-lodging" v-else>
          <div class="btn-galerie mb-3">
            <div class="galerie-icon df-jsb-aic">
              <img src="../../images/Galerie icone.svg" alt="galerie" />
              <p class="raleway-bold-p mb-0">Galerie</p>
            </div>
          </div>
          <div class="banner-show-container" v-if="this.lodging.lodging_description.catch_sentence">
            <div class="col-9 offset-3 catch-hub-show">
              <p class="merriweather-italic30">
                {{ this.lodging.lodging_description.catch_sentence }}
              </p>
            </div>
          </div>
          <div class="banner-show-container" v-else>
            <div class="col-9 offset-3 catch-hub-show">
              <p class="merriweather-italic30">Un havre aussi <br />éco-responsable que luxueux</p>
            </div>
          </div>
          <div class="btn-galerie-phone mb-3">
            <div class="galerie-icon df-jsb-aic">
              <img src="../../images/Galerie icone.svg" alt="galerie" />
              <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
            </div>
          </div>
        </div>
      </template>
      <!-- Nav lodgin -->
      <div class="nav-lodging">
        <div class="lodging-header">
          <div class="header">
            <h1 class="lodging-show-raleway-reg-p26-b text-center">{{ this.lodging.name }}
              <img v-if="lodging.lodging_description.green_host" src="../../images/label_green_host.png" alt="Green Host" class="label" style="width: 70px; max-width:100%; height:70px;">
            </h1>
            <!-- TODO standing -->
            <div class="standing justify-content-center">
              <img
                src="../../images/Etoiles.svg"
                alt="Etoile remplie"
                v-for="i in parseInt(this.lodging.standing)"
                :key="i"
              />
            </div>
          </div>
          <div class="interaction justify-content-center" style="margin-top: 0.5rem;">
            <div class="faves">
              <div>
                <img
                    src="../../images/wishlistOn.svg"
                    alt="Coeur plein"
                    v-if="isIncludeInWishlist()"F
                    @click="toggleLike()"
                />
                <img
                    src="../../images/wishlist.svg"
                    alt="Coeur vide"
                    v-else
                    @click="toggleLike()"
                />
              </div>
            </div>

            <Modalshare
                v-if="showModal"
                @close="showModal = false"
                :for="this.lodging"
            ></Modalshare>
            <div class="share d-flex" style="margin-right: 1rem;" @click="showModal = true">
              <img src="../../images/Share.svg" alt="Partager" />
              <p class="raleway-reg-p13 mb-0">Partager</p>
            </div>
          </div>
        </div>
        <div class="menu-lodging">
          <ul class="list-menu">
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('lodging-discover')">Description</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('essential')">Les essentiels</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('card-statut')" v-if="!this.lodging.is_entire"
                >programme de fidélité</a
              >
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('greennote-lodging')">La Green Note</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('room-availiable')">Les chambres</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('experiences-lodging')">Les expériences locales</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('services-lodging')">Les équipements</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a :href="'/reservation-hotel/' + this.lodging.slug + '/avis'">Avis</a>
            </li>
            <li>
              <a v-else
                :href="reservationUrl"
                class="hortense-btn-primary df-jsb-aic"
                v-if="!this.lodging.is_entire"
                >Voir les disponibilités</a
              >
              <a
                @click="scrollToElement('row-resa-entire')"
                class="hortense-btn-primary df-jsb-aic"
                v-else
                >Voir les disponibilités</a
              >
            </li>
          </ul>
        </div>
        <a
          :href="reservationUrl"
          class="hortense-btn-primary phone-btn df-jsb-aic"
          v-if="!this.lodging.is_entire"
          >Voir les disponibilités</a
        >
      </div>
      <div class="lodging-info" v-if="!this.lodging.is_entire">
        <p class="raleway-reg-p24 mb-2">{{ formatStyle(this.country.name) }}, {{ this.city.name }}</p>
        <div class="location-hub d-flex mb-3">
          <img src="../../images/Localisation.svg" alt="Localisation" />
          <!-- <p class="raleway-reg-p13 mb-0 pl-1 pr-2">3,4km du centre</p> -->
          <a class="link-hortense main-link" @click="displayMapfind">Voir sur la carte</a>
        </div>
        <p class="raleway-reg-p13">
          {{ this.lodging.address }}, {{ this.lodging.zip_code }},
          {{ this.city.name }}
        </p>
        <p class="raleway-reg-p13">
          Cet établissement propose {{ this.lodging.number_of_room }} {{ this.lodging.number_of_room == 1 ? 'chambre' : 'chambres' }}
        </p>
        <!--  <div class="room-left d-flex">
        <img src="../../images/Alerte.svg" alt="Alerte">
        <p class="raleway-reg-p13 mb-0 ml-2">Chambres restantes : {{this.lodging.number_of_room}}</p>
      </div> -->
        <Maplodgingshow
          v-if="showMapfind"
          :markers="this.markers"
          @markerSelected="selectCard"
          @close="showMapfind = false"
        ></Maplodgingshow>
        <div class="styles">
          <div class="style" v-for="style in styles" :key="style.id">
            <p class="raleway-reg-p15">{{ formatStyle(style.name) }}</p>
          </div>
        </div>
      </div>
      <div class="lodging-info" v-else>
        <p class="raleway-reg-p24 mb-2">{{ formatStyle(this.country.name) }}, {{ this.city.name }}</p>
        <div class="location-hub d-flex mb-3">
          <img src="../../images/Localisation.svg" alt="Localisation" />
          <a class="link-hortense main-link" @click="displayMapfind">Voir sur la carte</a>
        </div>
        <!-- <p class="raleway-reg-p13 mb-0">Logement entier</p> -->
        <p class="raleway-reg-p13">
          {{ this.lodging.address }}, {{ this.lodging.zip_code }},
          {{ this.city.name }}
        </p>
        <p class="raleway-reg-p13">
          Cet établissement propose {{ this.lodging.number_of_room }} {{ this.lodging.number_of_room == 1 ? 'chambre' : 'chambres' }}
        </p>
        <br>
       <div class="d-flex" v-if="this.lodging.is_entire">
        <img src="../../images/accommodation_capacity.png" style="width: 75%; height:74%;" alt="Accomodation" v-if="this.lodging.accommodation_capacity">
        <p class="raleway-reg-p24 mb-0" style="padding-top: 10px; font-size: 18px;" v-if="this.lodging.accommodation_capacity"> {{ this.lodging.accommodation_capacity }}</p>
         <img src="../../images/bedroom.png" alt="Rooms" style="width: 78%; height:78%; padding-left: 20px;" v-if="this.lodging.number_of_room">
         <p class="raleway-reg-p24 mb-0" style="padding-top: 8px; padding-left: 4px; font-size: 18px;" v-if="this.lodging.number_of_room"> {{ this.lodging.number_of_room }}</p>
         <img src="../../images/bath.png" alt="Bathroom" style="width: 70%; height:68%; padding-left: 20px;" v-if="this.lodging.number_of_bathroom" >
         <p class="raleway-reg-p24 mb-0" style="padding-top: 10px; padding-left: 5px; font-size: 18px;" v-if="this.lodging.number_of_bathroom"> {{ this.lodging.number_of_bathroom }}</p>
      </div>
        <Maplodgingshow
          v-if="showMapfind"
          :markers="this.markers"
          @markerSelected="selectCard"
          @close="showMapfind = false"
        ></Maplodgingshow>
        <div class="styles">
          <div class="style" v-for="style in styles" :key="style.id">
            <p class="raleway-reg-p15">{{ formatStyle(style.name) }}</p>
          </div>
        </div>
      </div>
      <div class="container-profil phone">
        <div class="row-basicinfo">
          <div class="first d-flex" data-aos="fade-left" data-aos-duration="900">
            <img
              :src="greenNotePicto(this.lodging.score.toFixed())"
              :alt="this.lodging.score.toFixed()"
              v-if="this.lodging.score"
            />
            <div class="content" v-if="this.lodging.score">
              <div class="note d-flex">
                <p class="raleway-reg-p24 mb-0">{{ this.lodging.score.toFixed() }}/</p>
                <p class="raleway-reg-p15 mb-0 mr-2">10</p>
                <p class="raleway-bold-p16 mb-0">
                  {{ this.lodging.green_note_mention }}
                </p>
              </div>
              <a :href="'/green-note-engagement-environnemental/'" class="link-hortense"
                >Comprendre la Green Note</a
              >
            </div>
            <div class="content" v-else>
              <div class="note d-flex">
                <p class="raleway-reg-p24 mb-0">_/</p>
                <p class="raleway-reg-p15 mb-0 mr-2">10</p>
                <p class="raleway-bold-p16 mb-0"></p>
              </div>
              <a :href="'/green-note-engagement-environnemental/'" class="link-hortense"
                >Comprendre la Green Note</a
              >
            </div>
          </div>
          <div class="snd d-flex" data-aos="fade-left" data-aos-duration="900">
            <img src="../../images/Note.svg" alt="" />
            <div class="content">
              <div class="note d-flex">
                <p class="raleway-reg-p24 mb-0">{{ this.lodging.lodging_description.rating_value || this.average }}/</p>
                <p class="raleway-reg-p15 mb-0 mr-2">5</p>
              </div>
              <p class="raleway-reg-p13 mb-0">Note des voyageurs</p>
              <a
                :href="'/reservation-hotel/' + this.lodging.slug + '/avis'"
                class="link-hortense"
                v-if="this.ratedLodgings.length || this.lodging.lodging_description.review_count"
                >{{ this.lodging.lodging_description.review_count || this.ratedLodgings.length }} avis</a
              >
              <a
                :href="'/reservation-hotel/' + this.lodging.slug + '/avis'"
                class="link-hortense"
                v-else
                >{{ 0 }} avis</a
              >
            </div>
          </div>
          <div class="separation"></div>
          <div class="third d-flex" data-aos="fade-right" data-aos-duration="900">
            <img src="../../images/CO2simple.svg" alt="" />
            <div class="content">
              <div class="note d-flex">
                <p class="raleway-reg-p24 mb-0 mr-2">6,9kg</p>
                <p class="raleway-reg-p13 mb-0 mr-2">de CO²</p>
              </div>
            </div>
          </div>
          <div class="fourth d-flex" data-aos="fade-right" data-aos-duration="900">
            <img src="../../images/Localisation.svg" alt="" />
            <div class="content">
              <p class="raleway-reg-p24 mb-0" v-if="this.distanceBetween">
                {{ this.distanceBetween }} km
              </p>
              <p class="raleway-reg-p13 mb-0">Distance depuis chez <br />vous</p>
            </div>
          </div>
<!--          <div v-if="this.lodging.lodging_description.is_club" class="separation"></div>-->
<!--          <div v-if="this.lodging.lodging_description.is_club" class="fourth d-flex" data-aos="fade-right" data-aos-duration="900">-->
<!--            <img style="height: 70px; width: 70px" src="../../images/club_logo.png" alt="" />-->
<!--            <div class="content">-->
<!--              <p class="raleway-reg-p24 mb-0">-->
<!--                Découvrez ce club-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1">
          <div
            class="accordion-header"
            @click.prevent="isExpand = !isExpand"
            :class="this.isExpand ? 'active' : ''"
          >
            <p class="raleway-reg-p24">A la découverte de l’hébergement</p>
          </div>
          <div class="accordion-body" :class="this.isExpand ? 'active' : ''">
            <p class="raleway-reg-p15">
              Nous vous dévoilons ici les secrets qui font le charme de cet établissement.<br />
              <br />{{ this.lodging.lodging_description.lodging_discover }}
            </p>
          </div>
        </div>
        <div class="row admin-discover">
          <div
            class="col-lg-5 col-xs-6"
            v-if="this.lodging"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            <div
              class="accordion-header"
              @click.prevent="isExpandAdminDiscover = !isExpandAdminDiscover"
              :class="this.isExpandAdminDiscover ? 'active' : ''"
            >
              <p class="raleway-reg-p24">A la découverte du gérant</p>
            </div>
            <div class="accordion-body" :class="this.isExpandAdminDiscover ? 'active' : ''">
              <p class="raleway-reg-p15">
                {{ this.lodging.lodging_description.manager_meet }}
              </p>
            </div>
          </div>
          <div class="col-5 portrait" data-aos="fade-up" data-aos-duration="900">
            <img
              v-if="this.lodging.photo_manager"
              :src="this.lodging.photo_manager"
              alt="manager photo"
            />
            <img
              v-else
              src="https://media.gettyimages.com/photos/portrait-of-confident-businesswoman-picture-id900243402?k=6&m=900243402&s=612x612&w=0&h=g3QokXD8374mJOA7Q4OzwriFeNmpQofn0WEwimx_i2E="
              alt=""
            />
          </div>
        </div>
        <div class="essential" data-aos="fade-up" data-aos-duration="900">
          <h3 class="raleway-reg-p24">Les essentiels</h3>
          <div class="row row-essentials">
            <div
              class="col-lg-4 col-xs-9"
              v-if="service_essentials && service_essentials.length > 0"
            >
              <div
                class="accordion-header"
                @click.prevent="isExpandEssentials = !isExpandEssentials"
                :class="this.isExpandEssentials ? 'active' : ''"
              >
                <p class="raleway-bold-p16 mb-5">Équipements & services</p>
              </div>
              <div class="accordion-body" :class="this.isExpandEssentials ? 'active' : ''">
                <div class="list-services" v-for="service in service_essentials" :key="service.id">
                  <div class="service-item">
                    <img :src="displayPicto(service)" :alt="service.name" />
                    <p class="raleway-reg-p15 mb-0">{{ service.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!--          <div class="col-lg-4 col-xs-9" v-else-if="!this.lodging.is_entire && service_rooms">
            <p class="raleway-bold-p16 mb-5" >Équipements & services</p>
            <div class="list-services" v-for="service in service_rooms.slice(0,5)" :key="service.id">
              <div class="service-item">
                <img :src="displayPicto(service)" :alt="service.name">
                <p class="raleway-reg-p15 mb-0">{{ service.name }}</p>
              </div>
            </div>
          </div>-->
            <div class="col-lg-3 col-xs-8">
              <div
                class="accordion-header"
                @click.prevent="isExpandInfo = !isExpandInfo"
                :class="this.isExpandInfo ? 'active' : ''"
              >
                <p class="raleway-bold-p16 mb-5">Informations importantes</p>
              </div>
              <div class="accordion-body" :class="this.isExpandInfo ? 'active' : ''">
                <div class="list-info">
                  <p class="raleway-reg-p15 mb-4 arrival">
                    Heure d’arrivée :
                    {{ this.lodging.lodging_profile.checkin_hour }}
                  </p>
                  <p class="raleway-reg-p15 mb-4 departure">
                    Heure de départ :
                    {{ this.lodging.lodging_profile.checkout_hour }}
                  </p>
                  <p
                    class="raleway-reg-p15 mb-4 adult-only"
                    v-if="this.lodging.lodging_profile.adult_only"
                  >
                    Réservé aux adultes
                  </p>
                  <p
                    class="raleway-reg-p15 mb-4 pmr"
                    v-if="this.lodging.lodging_profile.pmr_access"
                  >
                    Accessible aux PMR
                  </p>
                  <p class="raleway-reg-p15 mb-4 pmr" v-if="!this.lodging.lodging_profile.smoking">
                    Chambres non-fumeurs
                  </p>
                  <p class="raleway-reg-p15 mb-4 pmr" v-if="this.lodging.lodging_profile.smoking">
                    Chambres fumeurs
                  </p>
                  <p
                    class="raleway-reg-p15 mb-4 pet-accepted"
                    v-if="this.lodging.lodging_profile.animal"
                  >
                    Animaux de compagnie admis
                  </p>
                  <p class="raleway-reg-p15 mb-4 pet-accepted" v-else>
                    Animaux de compagnie non-admis
                  </p>
                  <p class="raleway-reg-p15 mb-0 payement">Moyens de paiement acceptés :</p>
                  <ul class="languages-list">
                    <template v-for="payment in this.lodging.payments">
                      <li class="raleway-reg-p15 mb-0 payement" :key="payment.id">
                        {{ formatEl(payment.name, "payments") }}
                      </li>
                    </template>
                  </ul>
                  <p
                    class="raleway-reg-p15 mb-4 mt-4 apoint-bed"
                    v-if="this.lodging.lodging_profile.extra_bed"
                  >
                    Lit d’appoint disponible
                  </p>
                  <p class="raleway-reg-p15 mt-4 mb-0 languages">Langues parlées :</p>
                  <ul class="languages-list">
                    <template v-for="language in this.lodging.languages">
                      <li class="raleway-reg-p15 mb-0 payement" :key="language.id">
                        {{ formatEl(language.name, "languages") }}
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-xs-9 col-conditions">
              <div class="cancel-conditions">
                <div class="head d-flex">
                  <img src="../../images/info-blue.svg" alt="info" />
                  <p class="raleway-bold-p16 ml-4 mb-0">Conditions</p>
                </div>
                <p class="raleway-reg-p15 content">
                  Les conditions d’annulation et de prépaiement varient en fonction du type de
                  chambre (tarif). Veuillez consulter les conditions de la chambre avant d’effectuer
                  votre choix.
                </p>
              </div>
              <div v-if="!isPhone" class="lodging-made-trip">
                  <img
                    src="../../images/bag.svg"
                    alt="bag"
                  />
                  <div class="content">
                    <p class="raleway-bold-p mb-0">Envie d'organiser un voyage sur mesure ?</p>
                    <p class="raleway-reg-p13 mb-0">Prenez contact avec nous <a class="link-hortense" :href="'/voyage-sur-mesure/'">ici</a></p>
                  </div>
              </div>
            </div>
            <div v-if="isPhone" class="lodging-made-trip">
                  <img
                    src="../../images/bag.svg"
                    alt="bag"
                  />
                  <div class="content">
                    <p class="raleway-bold-p mb-0">Envie d'organiser un voyage sur mesure ?</p>
                    <p class="raleway-reg-p13 mb-0">Prenez contact avec nous <a class="link-hortense" :href="'/voyage-sur-mesure/'">ici</a></p>
                  </div>
            </div>
          </div>
        </div>
        <div id="hortensia-lodging">
          <div class="card-statut">
            <div class="row row-statut">
              <div class="col-lg-4 col-xs-10 statut-content">
                <p class="raleway-reg-p24 mb-5">Les statuts du programme Hortensia</p>
                <p class="raleway-reg-p15">
                  Tous les 500 points, vous atteignez un <br />nouveau statut de fidélité qui vous
                  <br />permet d’accéder à de nouveaux <br />privilèges, avantages² et cadeaux.
                </p>
              </div>
              <div
                class="col-lg-2 col-xs-6 col-statut"
                data-aos="fade-down"
                data-aos-duration="600"
              >
                <img
                  src="../../images/badge-guest.png"
                  class="guest statut-img"
                  alt="badge guest"
                />
                <h3 class="raleway-bold-p">Green Trotter</h3>
                <p class="raleway-reg-p13">500 points soit 10 nuits réservées</p>
                <div class="col-8 col-only-phone" v-if="this.lodging.green_trotters.length > 0">
                  <template v-for="gift in this.lodging.green_trotters[0].gifts">
                    <div class="d-flex" :key="gift.id">
                      <i class="fas fa-check"></i>
                      <p class="raleway-reg-p13">{{ gift.name }}</p>
                    </div>
                  </template>
                </div>
              </div>
              <div
                class="col-lg-2 col-xs-6 col-statut-trotter"
                data-aos="fade-down"
                data-aos-duration="700"
              >
                <img
                  src="../../images/badge-member.png"
                  class="guest statut-img"
                  alt="badge member"
                />
                <h3 class="raleway-bold-p">Green Trotter <br />Plus</h3>
                <p class="raleway-reg-p13">1 000 points soit 20 nuits réservées</p>
                <div class="col-8 col-only-phone" v-if="this.lodging.green_trotters.length">
                  <template v-for="gift in this.lodging.green_trotters[1].gifts">
                    <div class="d-flex" :key="gift.id">
                      <i class="fas fa-check"></i>
                      <p class="raleway-reg-p13">{{ gift.name }}</p>
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-lg-2 col-xs-6" data-aos="fade-down" data-aos-duration="800">
                <img
                  src="../../images/badge-nomad@2x.png"
                  class="nomad statut-img"
                  alt="badge nomad"
                />
                <p class="raleway-bold-p">Green Trotter <br />Premium</p>
                <p class="raleway-reg-p13">1 500 points soit 30 nuits réservées</p>
                <div class="col-8 col-only-phone" v-if="this.lodging.green_trotters.length">
                  <template v-for="gift in this.lodging.green_trotters[2].gifts">
                    <div class="d-flex" :key="gift.id">
                      <i class="fas fa-check"></i>
                      <p class="raleway-reg-p13">{{ gift.name }}</p>
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-lg-2 col-xs-6" data-aos="fade-down" data-aos-duration="900">
                <img
                  src="../../images/badge-trotter.png"
                  class="trotter statut-img"
                  alt="badge trotter"
                />
                <p class="raleway-bold-p">Green Trotter <br />Infinite</p>
                <p class="raleway-reg-p13">2 500 points soit 50 nuits réservées</p>
                <div class="col-8 col-only-phone" v-if="this.lodging.green_trotters.length">
                  <template v-for="gift in this.lodging.green_trotters[3].gifts">
                    <div class="d-flex" :key="gift.id">
                      <i class="fas fa-check"></i>
                      <p class="raleway-reg-p13">{{ gift.name }}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="row row-hortensia-info" v-if="this.lodging.green_trotters.length">
              <div class="col-4">
                <a :href="'/programme-fidélité-hortensia/'" class="link-hortense"
                  >En savoir plus sur Hortensia</a
                >
              </div>
              <div class="col-2 col-only-desktop">
                <template v-for="gift in this.lodging.green_trotters[0].gifts">
                  <div class="d-flex" :key="gift.id">
                    <i class="fas fa-check"></i>
                    <p class="raleway-reg-p13">{{ gift.name }}</p>
                  </div>
                </template>
              </div>
              <div class="col-2 col-only-desktop">
                <template v-for="gift in this.lodging.green_trotters[1].gifts">
                  <div class="d-flex" :key="gift.id">
                    <i class="fas fa-check"></i>
                    <p class="raleway-reg-p13">{{ gift.name }}</p>
                  </div>
                </template>
              </div>
              <div class="col-2 col-only-desktop">
                <template v-for="gift in this.lodging.green_trotters[2].gifts">
                  <div class="d-flex" :key="gift.id">
                    <i class="fas fa-check"></i>
                    <p class="raleway-reg-p13">{{ gift.name }}</p>
                  </div>
                </template>
              </div>
              <div class="col-2 col-only-desktop">
                <template v-for="gift in this.lodging.green_trotters[3].gifts">
                  <div class="d-flex" :key="gift.id">
                    <i class="fas fa-check"></i>
                    <p class="raleway-reg-p13">{{ gift.name }}</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CARD RESA ENTIRE -->
      <div id="resa-entire" v-if="this.lodging.is_entire">
        <ModalRoomGalery
          :item="lodging"
          v-if="showRoomGalery"
          @close="showRoomGalery = false"
        ></ModalRoomGalery>
        <div class="row row-resa-entire center" :style="{ backgroundImage: 'url(' + this.lodging.photo_second_banner + ')' }">
          <div class="col-lg-6 col-xs-9 col-room">
            <div style="color:white" class="header-room">
              <p class="raleway-reg-p24">Les chambres</p>
              <div class="galerie">
                <img src="../../images/Galerie icone.svg" alt="galerie" />
                <button style="color:white" class="link-hortense" @click="showModalRoomGalery">Voir la Galerie</button>
              </div>
            </div>
            <!--<div v-if="!isPhone" class="row">-->
            <div :class="{'row': !isPhone}">
              <div :class="{'col-6': !isPhone}" v-for="(room, index) in this.rooms" :key="index">
                <div class="card-room mb-4 ml-4" :class="{ 'padding_bottom': sliceValueByIndex(index) }">
                  <!--<img :src="image" alt="room photo" class="hub-img" />-->
                  <div class="capacity">
                    <p class="raleway-reg-p24 mb-0 mr-1">{{ room.capacity }}</p>
                    <img src="../../images/profil.svg" alt="" />
                  </div>
                  <img src="../../images/lit.svg" alt="lit" />
                  <div class="room-details">
                    <!--<p class="raleway-semi-p15 mb-0">Chambre {{ index + 1 }}</p>-->
                    <p class="raleway-semi-p15 mb-0">{{ room.name }}</p>
                    <p class="raleway-reg-p15">{{ room.bed_type }}</p>
                  </div>
                  <!--HERE MODIFY-->
                  <div v-if="room.service_rooms">
                        <p v-show="sliceValueByIndex(index)" class="raleway-semi-p15 mb-2">Équipements</p>
                        <p v-show="sliceValueByIndex(index)"
                           v-for="service in getUniqueServiceRooms(room.service_rooms)"
                           :key="service.id" class="raleway-reg-p13">

                            {{ service.name}}
                        </p>
                        <div v-show="sliceValueByIndex(index)" :style="{'float': isPhone ? 'right' : 'left'}">
                            <a @click="toggleService(index)" class="link-hortense" :style="{'text-decoration': isPhone ? 'none' : 'underline'}">
                              voir moins <img src="../../images/Fleche haut.svg" :style="{'width': isPhone ? '3vw' : '1vw'}" alt="fleche bas"
                            /></a>
                        </div>
                        <div v-show="!sliceValueByIndex(index)" :style="{'float': isPhone ? 'right' : 'left'}">
                            <a @click="toggleService(index)" class="link-hortense" :style="{'text-decoration': isPhone ? 'none' : 'underline'}">
                              voir plus
                              <img src="../../images/flechebas-simple.svg" :style="{'width': isPhone ? '3vw' : '1vw'}" alt="fleche bas"
                            /></a>
                        </div>
                  </div>
                  <!--HERE END MODIFY-->
                </div>
              </div>
            </div>
            <!--DISPLAY ROOM FOR MOBILE-->
            <div v-else>
                <div v-for="(room, index) in this.lodging.rooms" :key="index">
                  <div class="card-room mb-4 ml-4" style="border: grey 1px solid;width: 80vw">
                    <div class="capacity pr-2 pt-2" style="float:right">
                      <p class="raleway-reg-p15 mb-0 mr-1">{{ room.capacity }}</p>
                      <img src="../../images/profil.svg" alt="" />
                    </div>
                    <img src="../../images/lit.svg" alt="lit" style="padding-left:15%" />
                    <div class="room-details" style="padding-left:15%">
                      <p class="raleway-up-semi-p13 mb-0">Chambre {{ index + 1 }}</p>
                      <p class="raleway-reg-p15">{{ room.bed_type }}</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-lg-6 col-card-resa">
            <div class="card-resa-entire">
              <div class="form-inp-lodging">
                <div class="first-line-inp">
                  <label for="checkin" class="raleway-reg-p13" :class="{ 'mr-4':!isPhone }"
                    >Arrivée
                    <input v-if="!isPhone" v-model="startDate" placeholder="Arrivée" type="date" data-date-inline-picker="true"/>
                    <input v-else v-model="startDate" placeholder="Arrivée" type="date" data-date-inline-picker="true" style="width: 38vw"/>
                  </label>
                  <label for="checkout" class="raleway-reg-p13 ml-2"
                    >Départ
                    <input v-if="!isPhone" v-model="endDate" placeholder="Départ" type="date" data-date-inline-picker="true"/>
                    <input v-else v-model="endDate" placeholder="Départ" type="date" data-date-inline-picker="true" style="width: 38vw"/>
                  </label>
                </div>
                <input v-if="!isPhone" type="number" v-model="guestNumber" placeholder="Nombre de voyageurs" class="raleway-reg-p13" min="1"/>
                <input v-else type="number" v-model="guestNumber" placeholder="Nombre de voyageurs" class="raleway-reg-p13" min="1" style="width: 90vw"/>
                <button
                  class="hortense-btn-primary"
                  @click="checkIfAvailable(startDate, endDate, guestNumber)"
                  :disabled="isSearching"
                >
                  {{ availabilitySearchButtonCaption }}
                </button>
                <p class="raleway-reg-p13 mt-2">Veuillez saisir le nombre de voyageurs avant de faire votre recherche</p>
              </div>
              <!--<template v-if="isAvailable && !isSearching">-->
              <template v-if="show_resa_card && !isSearching">
                <ReservationCard
                  :minNights="minNights"
		              :accomodationCode="accommodation_code"
                  :searched="searched"
                  :isSearching="isSearching"
                  :item="lodging"
                  :from="from"
                  :to="to"
                  :roomAvailability="roomOptions"
                  :roomAvailabilityNotKoedia="availabilitiesNotKoedia"
                  :tariffsNotKoedia="tariffsNotKoedia"
                  :isEntire="true"
                  :taxNotKoedia="lodgingTax"
                  :nights="nights"
                  :taxTotal="touristTax"
                  @sendInfo="displayUserInfo"
                ></ReservationCard>
              </template>
              <!--<div v-if="isAvailable === false">
                <div class="row d-flex">
                  <div class="col-12">
                    <p class="raleway-reg-p15">Établissement non disponible pour ces dates.</p>
                  </div>
                </div>
              </div>-->
            </div>
            <div v-if="!lodging.is_entire" class="raleway-reg-p13 message-container">
              <p v-if="!this.accommodation_code" class="text-center text-muted bottom-static-text">
              Cet hébergement ne propose pas la réservation instantanée. Une demande de réservation doit être faite. La disponibilité vous sera confirmée sous 24h.</p>
            </div>
            <!--<div v-else-if="lodging.is_entire" class="raleway-reg-p13 message-container">
              <div v-if="!lodging.lodging_channel_manager">
                <p v-if="!lodging.rooms[0].koedia_room_name" class="text-center text-muted bottom-static-text">
                Cet hébergement ne propose pas la réservation instantanée. Une demande de réservation doit être faite. La disponibilité vous sera confirmée sous 24h.</p>
              </div>
            </div>-->
          </div>
        </div>
      </div>

      <!-- END RESA ENTIRE -->
      <template v-if="!this.lodging.is_entire">
        <div
          class="room-availiable"
          v-if="this.lodging.photo_second_banner"
          :style="{
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(' +
              this.lodging.photo_second_banner +
              ')',
          }"
        >
          <p class="raleway-reg-p24" data-aos="fade-up" data-aos-duration="900">Les chambres</p>
          <a
            :href="reservationUrl"
            class="xs-btn-primary"
            data-aos="fade-up"
            data-aos-duration="900"
            >Voir les disponibilités</a
          >
        </div>
        <div class="room-availiable" v-else>
          <p class="raleway-reg-p24" data-aos="fade-up" data-aos-duration="900">Les chambres</p>
          <a
            :href="reservationUrl"
            class="xs-btn-primary"
            data-aos="fade-up"
            data-aos-duration="900"
            >Voir les disponibilités</a
          >
        </div>
      </template>
      <div class="container-profil">
        <ModalEnvironmentalPromise
          :description="this.lodging.lodging_description"
          v-if="showModalPromise"
          @close="showModalPromise = false"
        ></ModalEnvironmentalPromise>
        <div class="row greennote-lodging" data-aos="fade-up" data-aos-duration="900">
          <div class="col-lg-7 col-xs-12">
            <p class="raleway-reg-p24">La Green Note</p>
            <div class="first d-flex">
              <img
                :src="greenNotePicto(this.lodging.score.toFixed())"
                :alt="this.lodging.score.toFixed()"
                v-if="this.lodging.score"
              />
              <div class="content">
                <div class="note d-flex" v-if="this.lodging.score">
                  <p class="raleway-reg-p15 mb-0">{{ this.lodging.score.toFixed() }}/</p>
                  <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                  <p class="raleway-bold-p16 mb-0">
                    {{ this.lodging.green_note_mention }}
                  </p>
                </div>
                <a :href="'/green-note-engagement-environnemental/'" class="link-hortense"
                  >Comprendre la Green Note</a
                >
              </div>
              <div class="d-flex">
                <div class="w-30 ml-auto" v-if="lodging.lodging_description.green_host">
                  <img src="../../images/green_host.png" alt="Green Host" class="img-fluid" style=" width: auto; max-width:100%; height:auto;">
                </div>
                <div class="w-30 ml-auto" v-if="lodging.lodging_description.green_globe">
                  <img src="../../images/green_globe.png" alt="Green Globa" class="img-fluid" style="width: auto; max-width:100%; height:auto;">
                </div>
                <div class="w-30 ml-auto" v-if="lodging.lodging_description.green_key">
                  <img src="../../images/verte.png" alt="Verte" class="img-fluid" style="width: auto; max-width:100%; height:auto;">
                </div>
                <div class="w-30 ml-auto" v-if="lodging.lodging_description.ecolabel">
                  <img src="../../images/eco_label.png" alt="Eco Label" class="img-fluid" style=" width: auto; max-width:100%; height:92%;">
                </div>
              </div>
              <!-- <img src="../../images/Label.svg" class="label" alt=""> -->
            </div>
            <div class="row row-slidenote">
              <div class="col-lg-5 col-xs-8 col-md-8">
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Politique et gestion <br />environnementale</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.pol }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note1">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="pol"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#65B193' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Responsabilité sociale</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.soc }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note2">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="soc"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#386F5A' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Formation du personnel</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.per }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note3">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="per"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#65B193' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Sensibilisation des clients</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.cli }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note4">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="cli"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#4E8E75' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Politique d’achats responsables</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.ach }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note5">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="ach"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#E5E5B7' }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-xs-8 col-md-8 mt-3">
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Gestion de l’eau</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.eau }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note6">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="eau"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#386F5A' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Gestion des déchets</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.dec }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note7">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="dec"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#A9D997' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Gestion de l’énergie</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.ene }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note8">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="ene"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#7DCEAF' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Eco-construction</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.con }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note9">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="con"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#358867' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Environnement extérieur et intérieur</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.env }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note10">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="env"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#8ED8A2' }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-xs-10 col-planet">
            <img src="../../images/illus green note.png" />
            <div class="promise" v-if="this.lodging.lodging_description.environmental_promise">
              <p class="raleway-reg-p24 mb-3">Leur promesse environnementale</p>
              <p class="raleway-reg-p15 mb-3">
                «
                {{ this.lodging.lodging_description.environmental_promise }} »<br />
              </p>
              <a @click="showModalPromise = !showModalPromise" class="link-hortense"
                >En savoir plus sur leur promesse environnementale</a
              >
            </div>
          </div>
        </div>
      </div>
      <div id="lodging-resto">
        <div class="hortense-fav" data-aos="fade" data-aos-duration="900">
          <p class="merriweather-italic24 text-center">
            « {{ this.lodging.lodging_description.hortense_crush }} »
          </p>
          <p class="raleway-bold-p16">Le coup de cœur de Hortense</p>
        </div>
      </div>
      <!-- resto -->
      <div id="main-lodging-resto">
        <div class="lodging-resto-side-section">
          <div class="resto-side-section-title" v-if="this.lodging.is_entire === false">
            A table
          </div>
          <div class="resto-side-section-title" v-else>
            Le Club Hortense
          </div>
          <div  class="resto-side-section-title-2-sec">
            <div class="resto-side-section-title-2">
              L'EXPÉRIENCE CULINAIRE
            </div>
          </div>
          <div class="resto-side-section-description" v-if="this.lodging.is_entire === false">
            Avec Hortense, la gastronomie est au coeur de votre expérience de voyage. Vous pourrez vivre des moments culinaires uniques tout au long de votre séjour en choisissant parmi plusieurs formules.
          </div>
          <div class="resto-side-section-description" v-else>
            Offrez-vous l'expérience exclusive d'un séjour dans un lieu d'exception avec un service tout inclus, accompagné d'un chief privé. Pour tous vos séjours de 2 nuits minimum, vous pouvez sélectionner votre option de séjour. Laissez-vous dorloter par Hortense, qui se charge de tous les détails pour que vous puissiez savourer pleinement chaque instant de votre escapade.
          </div>
          <div class="resto-side-section-tab" v-if="this.lodging.lodging_profile.breakfast_included">
            DÉLICE AU RÉVEIL - PETIT DÉJEUNER INCLUS
          </div>
          <div class="resto-side-section-tab" v-if="this.lodging.lodging_profile.half_board">
            ESCAPADE GOURMANDE - DEMI-PENSION
          </div>
          <div class="resto-side-section-tab" v-if="this.lodging.lodging_profile.full_board">
            ÉVASION CULINAIRE - PENSION COMPLÈTE
          </div>
          <div class="resto-side-section-tab" v-if="this.lodging.lodging_profile.all_inclusive">
            PLAISIRS ILLIMITÉS - TOUT INCLUS
          </div>
          <a href="/experience-club-hortense/" class="resto-side-section-link" v-if="this.lodging.is_entire === true">Découvrir l'expérience Club Hortense</a>
        </div>
        <div class="lodging-resto-section-1" v-if="this.lodging.is_entire === false">
          <div class="chief-section-main">
            <div class="chief-section">
              <div class="chief-description">
                <strong>La spécialité culinaire du Chef</strong>
                <p>{{ this.lodging.lodging_description.chief_speciality }}</p>
                <ModalChiefIntro
                  :description="this.lodging.lodging_description"
                  v-if="showModalChief"
                  @close="showModalChief = false"
                ></ModalChiefIntro>
                <a class="chief-disc-link" @click="showModalChief = !showModalChief">En savoir plus sur le Chef</a>
              </div>
              <img v-if="this.lodging.lodging_description.chief_photo" class="chief-img" :src="this.lodging.lodging_description.chief_photo" alt="chief">
            </div>
            <div class="resturant-section">
              <strong>Les restaurants de l’établissement</strong>
              <ul class="list-resto" v-if="this.lodging.lodging_description.restaurant">
                <li
                  v-for="(li, index) in formatList(this.lodging.lodging_description.restaurant)"
                  :key="index"
                >
                  {{ li }}
                </li>
              </ul>
            </div>
          </div>
          <div class="culinary-section">
            <div class="culinary-img" v-if="foodMount && foodMount[0]">
              <img :src="foodMount[0].culinary_specialty_photo" alt="culinary photo" />
            </div>
            <div class="culinary-list-section">
              <p class="raleway-semi-p15">Top 5 des spécialités culinaires de la région</p>
              <div class="culinary-list">
                <ul  class="list-resto" v-if="foodMount && foodMount[0]">
                  <li>{{ foodMount[0].name_one }}</li>
                  <li>{{ foodMount[0].name_two }}</li>
                  <li>{{ foodMount[0].name_three }}</li>
                </ul>
                <ul  class="list-resto" v-if="foodMount && foodMount[0]">
                  <li>{{ foodMount[0].name_four }}</li>
                  <li>{{ foodMount[0].name_five }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="lodging-resto-section-2" v-else>
          <div class="culinary-section">
            <div class="culinary-list-section">
              <p class="culinary-list-title">Top 5 des spécialités culinaires de la région</p>
              <div class="culinary-list">
                <ul  class="list-resto" v-if="foodMount && foodMount[0]">
                  <li>{{ foodMount[0].name_one }}</li>
                  <li>{{ foodMount[0].name_two }}</li>
                  <li>{{ foodMount[0].name_three }}</li>
                </ul>
                <ul  class="list-resto" v-if="foodMount && foodMount[0]">
                  <li>{{ foodMount[0].name_four }}</li>
                  <li>{{ foodMount[0].name_five }}</li>
                </ul>
              </div>
            </div>
            <div class="culinary-img" v-if="foodMount && foodMount[0]">
              <img :src="this.foodMount[0].culinary_specialty_photo" alt="culinary photo" />
            </div>
          </div>
          <div class="service-section">
            <div class="service-description">
              <span>Les services sur mesure</span>
              <p>Hortense vous offre un service de conciergerie disponible sur demande avant votre séjour. Vous avez la possibilité de personnaliser votre expérience. Veuillez noter que certains services peuvent entrainer des frais supplémentaires.</p>
            </div>
            <div class="service-list-section">
              <div class="tailor-entire">
                <p v-if="this.lodging.lodging_profile.tailor_service" class="raleway-reg-p15" v-html="this.lodging.lodging_profile.tailor_service"></p>
              </div>
              <div class="service-img">
                <img :src="this.lodging.lodging_description.service_photo" alt="service photo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- exp -->
      <div data-aos="fade-up" data-aos-duration="900" class="ml-3 mt-5 lodging-header-container">
        <div class="lodging-header">
          <p class="raleway-reg-p24">Expériences authentiques, à proximité de cet établissement</p>
          <p class="raleway-reg-p15"></p>
          <div class="show-cart-btn">
            <img src="../../images/Localisation.svg" alt="Localisation" width="15" height="15" />
            <a class="link-hortense" @click="displayMapfind">Voir sur la carte</a>
          </div>
        </div>
      </div>
      <LodgingShowPhone
        :experiences="this.lodging.experiences"
        :user="user"
        v-if="this.targetShare"
      ></LodgingShowPhone>
      <div
        id="experiences-lodging"
        class="experiences-lodging"
        data-aos="fade-up"
        data-aos-duration="900"
        v-else
      >
        <div class="container-lodging phone">
          <div class="row d-flex justify-content-around">
            <div class="col-lg-3 col-md-10">
              <div class="lodging-header-desktop">
                <p class="raleway-reg-p24">
                  Expériences authentiques, à proximité de cet établissement
                </p>
                <p class="raleway-reg-p15"></p>
                <div class="show-cart-btn">
                  <img
                    src="../../images/Localisation.svg"
                    alt="Localisation"
                    width="15"
                    height="15"
                  />
                  <a class="link-hortense" @click="displayMapfind">Voir sur la carte</a>
                </div>
              </div>
              <div class="exp-filter">
                <ul class="filter-exp">
                  <li>
                    <a class="exp-category active" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="culturelles">culturelles</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="au naturel">au naturel</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="au bord de l'eau">au bord de l'eau</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="sportives">sportives</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="immersives">immersives</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="culinaires">culinaires</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="diy">DIY</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="oenologiques">oenologiques</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-8 col-md-10 pt-2" v-if="experiencesFiltered.length > 0">
              <slither-slider
                ref="slither"
                :options="{ numberOfSlides: 1, dots: false, controls: true }"
              >
                <div v-for="experience in experiencesFiltered" :key="experience.id">
                  <Cardexplodging
                    :experience="experience"
                    :user="isUserConnected()"
                    @connection="$emit('connection')"
                  ></Cardexplodging>
                  <!-- <div v-if='!selectedMarker'>
                    <Cardexplodging :experience="experience" :user="isUserConnected()" @connection="$emit('connection')"></Cardexplodging>
                  </div>
                  <div v-else>
                    <Cardexplodging :experience="experiencesFiltered[experienceselected]" :user="isUserConnected()" @connection="$emit('connection')"></Cardexplodging>
                  </div> -->
                </div>
                <template slot="previous">
                  <div class="previous">
                    <img src="../../images/fleche gauche.svg" alt="fleche gauche" />
                  </div>
                </template>
                <template slot="next">
                  <img src="../../images/fleche droite.svg" alt="fleche droite" />
                </template>
              </slither-slider>
            </div>
            <div class="col-8 pt-5" v-else>
              <p class="raleway-reg-p15">
                Hortense est à la recherche d'expériences à vous proposer près de cet hébergement
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-view"
        :style="{
          backgroundImage: 'url(' + this.lodging.photo_region_banner + ')',
        }"
        v-if="this.lodging.photo_region_banner"
      ></div>
      <div
        class="banner-view"
        :style="{
          backgroundImage:
            'url(https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg)',
        }"
        v-else
      ></div>
      <div class="container-region" data-aos="fade-up" data-aos-duration="900">
        <div class="dicsover-lodging-state">
          <div
            class="accordion-header"
            @click.prevent="isExpandDiscoverLodging = !isExpandDiscoverLodging"
            :class="this.isExpandDiscoverLodging ? 'active' : ''"
          >
            <h2 class="raleway-reg-p24 mb-4">Découvrir la région</h2>
          </div>
          <div class="accordion-body" :class="this.isExpandDiscoverLodging ? 'active' : ''">
            <p class="raleway-reg-p15 text-center">{{ this.region.content }}</p>
            <div class="df-jcc-aic" v-if="this.lodging.lodging_description.travel_guide_link">
            </div>
          </div>
          <a v-if="this.lodging.lodging_description.travel_guide_link" :href= this.lodging.lodging_description.travel_guide_link target="_blank" class="hortense-btn-primary">DÉCOUVREZ NOTRE GUIDE DE VOYAGE</a>
          <br>
        </div>
      </div>
      <!-- services -->
      <div id="services-lodging" class="services-lodging">
        <div class="row row-services">
          <div class="col-lg-4 col-md-12">
            <div v-if="lodging.photo_equipment">
              <img :src="baseUrl + lodging.photo_equipment" alt="Service Image" class="img-service" />
            </div>
<!--            <div-->
<!--              class="img-service"-->
<!--              :style="{-->
<!--                backgroundImage: 'url(' + baseUrl + this.lodging.photo_equipment + ')',-->
<!--              }"-->
<!--              v-if="this.lodging.photo_equipment"-->
<!--            ></div>-->
            <div
              class="img-service"
              :style="{
                backgroundImage:
                  'url(https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg)',
              }"
              v-else
            ></div>
          </div>
          <div
            class="col-lg-7 col-md-6 col-xs-6 col-description-services"
            v-if="this.lodging.is_entire"
          >
            <div
              class="accordion-header"
              @click.prevent="isExpandDescriptionServices = !isExpandDescriptionServices"
              :class="this.isExpandDescriptionServices ? 'active' : ''"
            >
              <h3 class="raleway-reg-p24 col-margin mb-5">Equipements et services</h3>
            </div>
            <div class="accordion-body" :class="this.isExpandDescriptionServices ? 'active' : ''">
              <div class="row row-services-description">
                <template v-for="(services, category, index) in this.groupedServiceEntiers">
                  <div
                    class="col-12 col-lg-6 mb-4"
                    data-aos="fade-up"
                    data-aos-duration="900"
                    :key="index"
                  >
                    <p class="raleway-bold-p16 mb-2">{{ category }}</p>
                    <div v-for="service in services" :key="service.id">
                      <div class="list-services top">
                        <div class="service-item">
                          <img :src="displayPicto(service)" :alt="service.name" />
                          <p class="raleway-reg-p15 mb-0">{{ service.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-6 col-xs-6 col-description-services" v-else>
            <div
              class="accordion-header"
              @click.prevent="isExpandDescriptionServices = !isExpandDescriptionServices"
              :class="this.isExpandDescriptionServices ? 'active' : ''"
            >
              <h3 class="raleway-reg-p24 col-margin mb-5">Equipements et services</h3>
            </div>
            <div class="accordion-body" :class="this.isExpandDescriptionServices ? 'active' : ''">
              <div class="row row-services-description">
                <template v-for="(services, category, index) in this.groupedServices">
                  <div
                    class="col-12 col-lg-6 mb-4"
                    data-aos="fade-up"
                    data-aos-duration="900"
                    :key="index"
                  >
                    <p class="raleway-bold-p16 mb-2">{{ category }}</p>
                    <div v-for="service in services" :key="service.id">
                      <div class="list-services top">
                        <div class="service-item">
                          <img
                            :src="displayPicto(service)"
                            :alt="service.name"
                            width="20px"
                            height="20px"
                          />
                          <p class="raleway-reg-p15 mb-0">
                            {{ `${service.name} ${formatDispo(service.disponibility)}` }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="col-lg-7 offset-lg-4">
            <div class="good-know" v-if="this.lodging.lodging_profile.good_to_know">
              <div class="head d-flex">
                <img src="../../images/info-blue.svg" alt="info" />
                <p class="raleway-bold-p16 ml-4 mb-0">Bon à savoir</p>
              </div>
              <p class="raleway-reg-p15 content">
                {{ this.lodging.lodging_profile.good_to_know }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="customer-avis"
        class="customer-avis"
        v-if="this.comments && this.comments.length"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <h3 class="raleway-reg-p24 mb-5 text-center">Avis des voyageurs</h3>
        <div class="sum-note mb-5 d-flex justify-content-center">
          <img src="../../images/Note.svg" alt="note" />
          <div class="note df-jsb-aic ml-4">
            <p class="mb-0 raleway-reg-p24">{{ this.average }}/</p>
            <p class="mb-0 raleway-reg-p15">10</p>
          </div>
        </div>
        <template v-for="resa in this.resaMount">
          <Cardcomment :resa="resa" :key="resa.id"></Cardcomment>
        </template>
        <div class="pagination-avis text-center mt-3">
          <a :href="'reservation-hotel/' + this.lodging.slug + '/avis'" class="link-hortense">
            voir plus
            <img src="../../images/flechebas-simple.svg" alt="fleche bas"
          /></a>
        </div>
      </div>
      <div class="selection-host-card">
        <h3 class="raleway-reg-p24 text-center mb-5">Voir les autres hébergements</h3>
        <div v-if="lodgingsByRegionMounted">
          <div v-for="lodging in this.lodgingsByRegionMounted.slice(0, 2)" :key="lodging.id">
            <SelectionHost
              :lodging="lodging"
              :user="isUserConnected()"
              :exclusive="exclusive"
              v-if="lodging"
              class="mt-5"
              data-aos="fade-up"
              data-aos-duration="900"
            ></SelectionHost>
          </div>
        </div>
        <div class="df-jcc-aic">
          <a :href="'/reservation-hotel'" class="hortense-btn-primary"
            >Découvrir nos hébergements</a
          >
        </div>
      </div>
    </template>
    <ReservationNewUserInfo
      v-else
      :item="lodging"
      :user="user"
      :lodging="lodging"
      :from="from"
      :to="to"
      :tax="lodging.tax"
      :selectedOption="selectedRoomOption"
      :koediaFrom="koediaFrom"
      :koediaTo="koediaTo"
      :koediaRoomId="selectedRoomOption.room_id"
      :koediaSessionId="koediaSessionId"
      :pricePerNightHt="pricePerNightHt(chosenIdx)"
      :totalHt="totalHt(chosenIdx)"
      :tva="tva(chosenIdx)"
      :totalTtc="totalTtc(chosenIdx)"
      :adults="parseInt(guestNumber)"
      :childs="0"
      :numberOfNights="nights"
      :selectedPriceOptionFee="selectedPriceOptionFee"
      :accomodationCode="accommodation_code"
      :totalTaxNotKoedia="totalTaxNotKoedia"
      :totalHtNotKoedia="totalHtNotKoedia"
      :totalTvaNotKoedia="totalTvaNotKoedia"
      :totalTtcNotKoedia="totalTtcNotKoedia"
      :houseHold="houseHold"
      @closed="handleClose"
    ></ReservationNewUserInfo>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import fetcher from "../../packs/shared/api.js";
import VueSlider from "vue-slider-component";
import ReservationNewUserInfo from "pages/reservations/ReservationNewUserInfo";
import LodgingShowPhone from "../../components/LodgingShowPhone.vue";
import SelectionHost from "../../components/cards/SelectionHost.vue";
import Cardexplodging from "../../components/cards/Cardexplodging.vue";
import ReservationCard from "../../components/cards/ReservationCard";
import Cardcomment from "../../components/cards/Cardcomment.vue";
import Modalshare from "../../components/modals/Modalshare.vue";
import ModalGallery from "../../components/modals/ModalGallery.vue";
import ModalRoomGalery from "../../components/modals/ModalRoomGalery.vue";
import Maplodgingshow from "../../pages/lodgings/Maplodgingshow.vue";
import ModalEnvironmentalPromise from "../../pages/lodgings/ModalEnvironmentalPromise.vue";
import ModalChiefIntro from "../../pages/lodgings/ModalChiefIntro.vue";
import AOS from "aos";
import "mapbox-gl/dist/mapbox-gl.css";
import {isNil} from "buefy";

export default {
  name: "lodging-show",
  components: {
    VueSlider,
    ModalGallery,
    LodgingShowPhone,
    ModalRoomGalery,
    SelectionHost,
    Cardcomment,
    Cardexplodging,
    Modalshare,
    Maplodgingshow,
    ModalEnvironmentalPromise,
    ModalChiefIntro,
    ReservationCard,
    ReservationNewUserInfo,
  },
  props: {
    minNights: Number,
    rooms: Array,
    lodging: {
      type: Object,
      required: true
    },
    exclusive: Object,
    user: Object,
    directionsApi: String,
    processStyle: {
      backgroundColor: String,
    },
  },
  data() {
    return {
      baseUrl: 'https://www.hortense.green',
      show_resa_card: false,
      slice_value: [],
      houseHold: null,
      accommodation_code: null,
      totalTaxNotKoedia: String,
      totalHtNotKoedia: String,
      totalTvaNotKoedia: String,
      totalTtcNotKoedia: String,
      touristTax: null,
      availability: null,
      isExpand: false,
      isExpandAdminDiscover: false,
      isExpandEssentials: false,
      isExpandInfo: false,
      isExpandDiscoverLodging: false,
      isExpandDescriptionServices: false,
      experiencesList: null,
      experiencesFiltered: [],
      isSearching: false,
      searched: false,
      isChoosing: true,
      targetShare: null,
      distanceBetween: null,
      to: null,
      from: null,
      koediaFrom: null,
      koediaTo: null,
      // pricePerNight: null,
      // totalHt: 0,
      nights: null,
      roomOptions: null,
      koediaSessionId: null,
      showModalPromise: false,
      showRoomGalery: false,
      showModalChief: false,
      modalGallery: false,
      showMapfind: false,
      chosenIdx: null,
      groupedQuestions: null,
      markers: [],
      ratedLodgings: [],
      averageList: [],
      average: 0,
      showModal: false,
      lodgingsByRegionMounted: null,
      source: "",
      categoryServices: [],
      categoryServiceEntiers: [],
      groupedServiceEntiers: null,
      groupedServices: null,
      wishlistLodgings: null,
      SelectedOption: "culturelles",
      selectedRoomOption: null,
      foodMount: null,
      experienceselected: 0,
      options: {
        min: 0,
        max: 10,
        dotSize: 60,
        fixed: true,
        clickable: false,
        tooltip: "none",
      },
      availabilitiesNotKoedia: [],
      selectedPriceOptionFee: null,
    };
  },
  watch: {
    slice_value : {
      handler(newVal) {
        this.slice_value = newVal;
        //console.log('slice_value');
        //console.log(this.slice_value);
      },
      immediate: true,
      deep: true,
    },
    directionsApi: {
      immediate: true,
      handler(key) {
        const mapsLoader = new Loader({ apiKey: key });
        mapsLoader.load().then(() => this.getLocation());
      },
    },
    isChoosing: {
      handler(newVal) {
        this.isChoosing = newVal;
      },
      immediate: true,
    },
    /*experiencesFiltered: {
				immediate: true,
				handler() {
					this.$refs.slither.reload();
				},
			},*/
  },
  methods: {
    isNil() {
      return isNil
    },
    getBackgroundStyle() {
      // if (!this.lodging.photo_banner) {
      //   return {};
      // }
      return {
        backgroundImage: `url("${this.baseUrl}${this.lodging.photo_banner}")`
      };
    },
    async ReqInventoryBook() {
      try {
        const res = await fetcher.post(
          `/lodgings/${this.lodging.slug}/request_inventory_booking`,
          {
            start: this.koediaFrom,
            end: this.koediaTo,
          }
        );
        if(res.data){
           this.minNights = res.data.booking_request;
           console.log('this.minNights');
           console.log(this.minNights);
        }
      } catch (e) {
        console.error(e);
      }
    },
    getUniqueServiceRooms(services) {
      const uniqueNames = new Set();
      return services.filter(service => {
        if (!uniqueNames.has(service.name)) {
          uniqueNames.add(service.name);
          return true;
        }
        return false;
      });
    },
    sliceValueByIndex(index) {
      //console.log('slice_value[index]');
      //console.log(this.slice_value[index]);
      return this.slice_value[index];
    },
    toggleService(idx) {
       this.$set(this.slice_value, idx, !this.slice_value[idx]);
      //this.slice_value[idx] = !this.slice_value[idx];

      console.log('idx');
      console.log(idx);

      console.log('idx value');
      console.log(this.slice_value[idx]);
    },
    displayUserInfo(room, pricePerNightTtc, totalTtc, koediaRoomAvailability, idx, tva, priceHt, houseHOld = null) {
      // this.roomChoosed = room;
      this.selectedRoomOption = koediaRoomAvailability;
      // this.numberOfRoom = numberOfRoom;
      //this.chosendTotalTtc = totalTtc;
      this.chosentPricePerNightHt = pricePerNightTtc;
      this.chosenIdx = idx;
      this.isChoosing = false;
      this.selectedPriceOptionFee = koediaRoomAvailability.find((el) => el.tariff === idx).tariff_cancel_feed;

      // TAX
      //this.totalTaxNotKoedia = (this.lodging.tax.tourist_tax * this.nights * parseInt(this.guestNumber)).toFixed(2);
      this.totalTaxNotKoedia = (this.lodging.tax.tourist_tax * this.nights).toFixed(2);
      
      // TOTAL HT
      this.totalHtNotKoedia = (priceHt * this.nights).toFixed(2);

      // TOTAL TVA
      this.totalTvaNotKoedia = (tva * this.nights).toFixed(2);

      // TOTAL TTC
      this.totalTtcNotKoedia = (parseFloat(this.totalHtNotKoedia) + parseFloat(this.totalTvaNotKoedia) + parseFloat(this.totalTaxNotKoedia)).toFixed(2);
      this.houseHold = houseHOld;
    },
    pricePerNightTtc(idx) {
      return parseFloat(this.roomOptions.options[idx].room_price.per_day.rack);
    },
    totalTtc(idx) {
      if(this.accommodation_code){
       return (parseFloat(this.roomOptions.options[idx].room_price.total.rack)).toFixed(2);
      }else{
       return this.chosendTotalTtc;
      }
    },
    pricePerNightHt(idx) {
      if(this.accommodation_code){
       return (parseFloat(this.pricePerNightTtc(idx)) / (1 + (parseFloat(this.lodging.tax.tva_rate) / 100))).toFixed(2);
      }else{
        return this.chosentPricePerNightHt;
      }
    },
    totalHt(idx) {
      if(this.accommodation_code){
       return (parseFloat(this.totalTtc(idx)) / (1 + (parseFloat(this.lodging.tax.tva_rate) / 100))).toFixed(2);
      }else{
       return (parseFloat(this.chosendTotalTtc) / (1 + (parseFloat(this.lodging.tax.tva_rate) / 100))).toFixed(2);
      }
    },
    tva(idx) {
      return (parseFloat(this.totalTtc(idx)) - parseFloat(this.totalHt(idx))).toFixed(2);
    },
    handleClose() {
      this.isChoosing = true;
    },
    completeReservation() {
      this.isChoosing = false;
    },
    getTotalTTC() {
      if (this.total && this.tva) {
        return this.total + this.tva;
      }
      return 0;
    },
    async fetchFood() {
      try {
        const response = await fetcher.get(
          "/departments/" + this.department.id + "/food_specialties/list"
        );
        console.log("food", response.data)
        this.foodMount = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchExps(cat) {
      try {
        const response = await fetcher.get(
          `/categories/${encodeURI(cat)}/experiences/list?lodging_id=${this.lodging.id}`
        );
        this.experiencesFiltered = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    formatDispo(data) {
      if (data === "disponible") {
        return "";
      } else if (data === "extra") {
        return "(en supplément)";
      } else if (data === "on_demand") {
        return "(sur demande)";
      }
    },
    async fetchLodgingByRegion() {
      try {
        const response = await fetcher.get("/reservation-hotel?search=" + this.region.name);
        this.lodgingsByRegionMounted = response.data.filter((el) => {
          el.name !== this.lodging.name;
        });
      } catch (e) {
        console.error(e);
      }
    },
    shareDevices() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return (this.targetShare = true);
      } else {
        return (this.targetShare = false);
      }
    },
    updateAvailabilityInfo() {
      //if (this.options) {
      //  this.from = new Date(this.startDate);
      //  this.to = new Date(this.endDate);
      //  this.countNights();
      //} else {
      //  this.from = new Date(this.startDate);
      //  this.to = new Date(this.endDate);
      //  this.countNights();
      //}

      this.from = new Date(this.startDate);
      this.to = new Date(this.endDate);
      this.countNights();
      this.isSearching = false;
      this.searched = true;
      this.touristTax =(parseFloat(this.lodging.tax.tourist_tax) *
                        parseInt(this.nights) *
                        parseInt(this.guestNumber)).toFixed(2);
    },
    countNights() {
      this.nights = (this.to - this.from) / 864e5;
    },
    checkIfAvailable(checkin, checkout, guests) {
      this.searched = false;
      this.isSearching = true;
      this.show_resa_card = false;
      if (!this.accommodation_code)
      //if(!this.lodging.lodging_channel_manager.accommodation_code)
      {
        console.log('NOT KOEDIA');
        this.availabilitiesNotKoedia = [];
        fetcher.post('/lodgings/' + this.lodging.id + '/availabilities').
         then((response) => {
             console.log("RESPONSE");
             console.log(response);

             console.log('START');
             console.log(new Date(this.startDate));

             console.log('END');
             console.log(new Date(this.endDate));

             let filtered_data = response.data
                 .filter((item) => (item.tariff && item.date && /*item.tariff_price &&*/ 
                                    new Date(item.date) >= new Date(this.startDate) &&
                                    new Date(item.date) <= new Date(this.endDate))
             );
             this.availabilitiesNotKoedia = filtered_data.filter((item) => item.tariff_price !== null);

             console.log('LODGING ' + this.lodging.id);
             console.log(this.availabilitiesNotKoedia);
             this.show_resa_card = true;
             console.log('show_resa_card');
             console.log(this.show_resa_card);
             this.updateAvailabilityInfo();
        });
        
        setTimeout(() => {
            this.searched = true;
            this.isSearching = false;
        }, 6000);
      }
      else
      {
        this.ReqInventoryBook();
        console.log('KOEDIA');
        console.log(this.accommodation_code);
        this.koediaFrom = checkin;
        this.koediaTo = checkout;
        this.isAvailable = null;
        this.getAvailability(checkin, checkout, guests)
          .then((response) => {
            this.koediaSessionId = response.data.koedia_session_id;
            this.roomOptions = response.data.availabilities;
            console.log('this.roomOptions');
            console.log(this.roomOptions);
	    this.show_resa_card = true;
            this.updateAvailabilityInfo();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getAvailability(checkin, checkout, guests) {
      return fetcher.post(`/lodgings/${this.lodging.slug}/get_availability`, {
        accommodation_code: this.accommodation_code,
        accommodation_name: this.lodging.lodging_channel_manager.accommodation_name,
        start: checkin,
        end: checkout,
        adults: guests,
      });
    },
    isBeetween(date, from, to) {
      return date >= from && date <= to;
    },
    placeAvailable(lodging, from, to) {
      let bool = true;
      this.lodging.reservations.every((reservation) => {
        let checkin = new Date(reservation.checkin);
        let check_out = new Date(reservation.check_out);
        if (
          this.lodging.code === reservation.room_code &&
          (this.isBeetween(checkin, from, to) || this.isBeetween(check_out, from, to))
        ) {
          bool = false;
        }
      });
      return (this.isAvailable = bool);
    },
    formatEl(el, type) {
      const arr = type === "languages" ? this.lodging.languages : this.lodging.payments;
      return el === arr[arr.length - 1].name ? `${el}.` : `${el}, `;
    },
    formatList(string) {
      return string
        .replaceAll("\n", "")
        .replaceAll("•", "")
        .split("\t")
        .filter((s) => s);
    },
    openModal() {
      this.$root.$emit("openModal");
    },
    showModalGallery() {
      this.modalGallery = !this.modalGallery;
    },
    showModalRoomGalery() {
      this.showRoomGalery = !this.showRoomGalery;
    },
    isUserConnected() {
      return this.user ? this.user : {};
    },
    groupByCat(key) {
      const serviceEntiers = this.service_unique();
      console.log('serviceEntiers', serviceEntiers);

      this.groupedServices = serviceEntiers.reduce((rv, x) => {
        if (x.category && x.category.name) {
          const categoryName = x.category.name;
          if (!rv[categoryName]) {
            rv[categoryName] = [];
          }
          rv[categoryName].push(x);
        } else {
          const undefinedCategory = 'Undefined Category';
          if (!rv[undefinedCategory]) {
            rv[undefinedCategory] = [];
          }
          rv[undefinedCategory].push(x);
        }
        return rv;
      }, {});

      console.log('this.groupedServices', this.groupedServices);
      this.categoryServices = Object.keys(this.groupedServices);
    },

    groupByCatEntire() {
      const serviceEntiers = this.service_entiers();
      this.groupedServiceEntiers = serviceEntiers.reduce((rv, x) => {
        if (x.category && x.category.name) {
          const categoryName = x.category.name;
          if (!rv[categoryName]) {
            rv[categoryName] = [];
          }
          rv[categoryName].push(x);
        } else {
          const undefinedCategory = 'Undefined Category';
          if (!rv[undefinedCategory]) {
            rv[undefinedCategory] = [];
          }
          rv[undefinedCategory].push(x);
        }
        return rv;
      }, {});
      this.categoryServiceEntiers = Object.keys(this.groupedServiceEntiers);
    },
    selectCard(attr) {
      this.experienceselected = attr - 1;
      this.selectedMarker = !this.selectedMarker;
    },
    greenNotePicto(score) {
      if (score === `${score}`) {
        return require(`../../images/greennote ${score}.svg`);
      } else {
        return require("../../images/blanc.png");
      }
    },
    displayPicto(service) {
      const pictoName = service.name.toLowerCase().replaceAll("/", "-");
      try {
        return require(`../../images/${pictoName}.svg`);
      } catch (e) {
        return require("../../images/blanc.png");
      }
    },
    markerMap() {
      const markers = [];
      markers.push([this.lodging.longitude, this.lodging.latitude]);
      this.experiencesFiltered.map((el) => {
        markers.push([el.longitude, el.latitude]);
      });
      return markers;
    },
    displayMapfind() {
      this.markers = JSON.parse(JSON.stringify(this.markerMap()));
      this.showMapfind = !this.showMapfind;
      if (event.currentTarget.className === "link-hortense main-link") {
        setTimeout(function () {
          document.querySelectorAll(".marker").forEach((marker) => {
            marker.style.display = "none";
            marker.style.pointerEvents = "none";
          });
          document.querySelectorAll(".marker")[0].style.display = "block";
        }, 1);
      }
    },
    formatStyle(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    capitalizeWords(string){
  return string.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
},
    scrollToElement(div) {
      const el = document.getElementsByClassName(div)[0];

      if (el) {
        el.scrollIntoView();
      }
    },
    async selectOption(e) {
      const activeEl = document.querySelector(".active");
      activeEl.classList.remove("active");
      e.target.parentNode.classList.add("active");
      this.SelectedOption = e.target.dataset.value;
      if (this.SelectedOption) {
        console.log(this.SelectedOption);
        this.fetchExps(this.SelectedOption);
      } else {
        this.experiencesFiltered = [];
      }
    },
    toggleLike() {
      if (this.user) {
        fetcher.patch("/wishlists/" + 1, { lodging_id: this.lodging.id }).then((res) => {
          this.wishlistLodgings = res.data.lodgings;
        });
      } else {
        this.$root.$emit("openModal");
        window.scrollTo({
          top: 0,
          behaviour: "smooth",
        });
      }
    },
    ratedLodging() {
      if (!this.lodging.reservations) {
        this.ratedLodgings = [];
      } else {
        this.ratedLodgings = this.lodging.reservations.filter((el) => el.rated === true);
      }
      return this.ratedLodgings;
    },
    averageRate() {
      this.ratedLodgings.forEach((el) => {
        this.averageList.push(el.rate_average);
      });
      return this.averageList;
    },
    averageLodging() {
      if (this.averageList.length) {
        this.average = (
          this.averageList.reduce((a, b) => a + b, 0) / this.averageList.length
        ).toFixed(1);
        return this.average;
      } else {
        return (this.average = "-");
      }
    },
    isIncludeInWishlist() {
      if (this.wishlistLodgings) {
        for (const lodging of this.wishlistLodgings) {
          if (lodging.id === this.lodging.id) {
            return true;
          }
        }
      }
      return false;
    },
    fetchLodging() {
      this.ratedLodging();
      this.averageRate();
      this.averageLodging();
      this.groupByCat("category");
      this.groupByCatEntire("category");
      this.fetchLodgingByRegion();
    },
    groupBy() {
      this.groupedQuestions = this.questionList.reduce((rv, x) => {
        (rv[x["category"]["name"]] = rv[x["category"]["name"]] || []).push(x);
        return rv;
      }, {});
    },
    async fetchWishlist() {
      try {
        if (this.user) {
          const response = await fetcher.get("/wishlists.json");
          this.wishlistLodgings = response.data.lodgings;
        }
      } catch (e) {}
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition((pos) => {
        let currPos = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
        let desti = new google.maps.LatLng(this.lodging.latitude, this.lodging.longitude);
        new google.maps.DistanceMatrixService()
          .getDistanceMatrix({
            origins: [currPos],
            destinations: [desti],
            travelMode: "DRIVING",
          })
          .then((res) => {
            this.distanceBetween = (res.rows[0].elements[0].distance.value / 1000).toFixed();
          });
      });
    },
    service_entiers() {
      const lodgingServiceEntiers = this.lodging.lodging_service_entiers;
      const uniqueServiceEntiers = Object.values(
          lodgingServiceEntiers.reduce((acc, service) => {
            acc[service.name] = service;
            return acc;
          }, {})
      );
      return uniqueServiceEntiers;
    },
    service_unique() {
      const lodgingServiceEntiers = this.lodging.lodging_services;
      const uniqueServiceEntiers = Object.values(
          lodgingServiceEntiers.reduce((acc, service) => {
            acc[service.name] = service;
            return acc;
          }, {})
      );
      return uniqueServiceEntiers;
    },
    // distance(lat1, lon1, lat2, lon2, unit) {
    // 	const radlat1 = (Math.PI * lat1) / 180;
    // 	const radlat2 = (Math.PI * lat2) / 180;
    // 	const theta = lon1 - lon2;
    // 	const radtheta = (Math.PI * theta) / 180;
    // 	this.distanceBetween = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    // 	this.distanceBetween = Math.acos(this.distanceBetween);
    // 	this.distanceBetween = (this.distanceBetween * 180) / Math.PI;
    // 	this.distanceBetween = this.distanceBetween * 60 * 1.1515;
    // 	if (unit === 'K') {
    // 		this.distanceBetween = this.distanceBetween * 1.609344;
    // 	}
    // 	if (unit === 'N') {
    // 		this.distanceBetween = this.distanceBetween * 0.8684;
    // 	}
    // 	return this.distanceBetween;
    // },
  },
  mounted() {
    AOS.init();
    this.fetchLodging();
    this.shareDevices();
    this.fetchFood();
    this.fetchExps("culturelles");
    if(this.lodging.lodging_channel_manager){
       this.accommodation_code = this.lodging.lodging_channel_manager.accommodation_code;
    }
    for (let cpt = 0; cpt < this.rooms.length; cpt++) {
      this.slice_value[cpt] = false;
    }
    /*console.log('lodging');
    console.log(this.lodging);

    console.log('slice_value');
    console.log(this.slice_value);*/
  },
  beforeMount() {
    this.fetchWishlist();
  },
  computed: {
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    },
    reservationUrl(){
      console.log('exclusive')
      console.log(this.exclusive)
      if(this.exclusive)
        return '/offres-exclusives/' + this.exclusive.slug + '/' + this.lodging.slug + '/reservation'
      else
        return '/reservation-hotel/' + this.lodging.slug + '/reservation';
    },
    tariffsNotKoedia(){
       if(this.availabilitiesNotKoedia && this.availabilitiesNotKoedia.length > 0){
          let collection = this.availabilitiesNotKoedia.filter((el) => el.tariff != null && el.tariff != "").map((el) => el.tariff);
          console.log('TARIFFS');
          console.log([...new Set(collection)]);
          return [...new Set(collection)];
       }else{
           console.log('HERE')
           return [];
       }
    },
    lodgingTax(){
       console.log("FROM lodgingTax")
       console.log(this.lodging.tax);
       return this.lodging.tax;
    },
    /*isAvailable() {
      if(this.accommodation_code)
      {
          return this.roomOptions !== null;
      }else{
        return this.availabilitiesNotKoedia.length > 0;
      }
    },*/
    availabilitySearchButtonCaption() {
      if (this.isSearching) {
        return "Recherche en cours...";
      } else {
        return "Vérifier les disponibilités";
      }
    },
    comments() {
      return this.lodging.comments;
    },
    country() {
      return this.lodging.country;
    },
    region() {
      return this.lodging.region;
    },
    department() {
      return this.lodging.department;
    },
    city() {
      return this.lodging.city;
    },
    styles() {
      return this.lodging.styles;
    },
    services() {
      return this.lodging.lodging_services;
    },
    service_rooms() {
      return this.lodging.lodging_service_rooms;
    },
    service_essentials() {
      return this.lodging.service_essentials;
    },
    photos() {
      return this.lodging.photos_keys;
    },
    pol() {
      return this.lodging.average_score_for_POL;
    },
    soc() {
      return this.lodging.average_score_for_SOC;
    },
    per() {
      return this.lodging.average_score_for_PER;
    },
    cli() {
      return this.lodging.average_score_for_CLI;
    },
    ach() {
      return this.lodging.average_score_for_ACH;
    },
    eau() {
      return this.lodging.average_score_for_EAU;
    },
    dec() {
      return this.lodging.average_score_for_DEC;
    },
    ene() {
      return this.lodging.average_score_for_ENE;
    },
    con() {
      return this.lodging.average_score_for_CON;
    },
    env() {
      return this.lodging.average_score_for_ENV;
    },
    resaMount() {
      return this.lodging.reservations;
    },
    standing() {
      return this.lodging.standing;
    },
    questionList() {
      return this.lodging.questions;
    },
    calculateTva() {
      if (this.total) {
        return (this.tva = parseInt(((this.total * this.lodging.tax.tva_rate) / 100).toFixed(2)));
      }
      return 0;
    },
    total() {
      if (this.startDate && this.endDate) {
        const datestartDate = this.lodging.availabilities.find((el) => el.date === this.startDate);
        const indexFrom = this.lodging.availabilities.indexOf(datestartDate);
        const dateendDate = this.lodging.availabilities.find((el) => el.date === this.endDate);
        const indexTo = this.lodging.availabilities.indexOf(dateendDate);

        return this.lodging.availabilities
          .slice(indexFrom, indexTo + 1)
          .reduce((acc, current) => acc + current.pricing, 0);
      }
      return 0;
      return (this.tva = ((this.totalHt * this.lodging.tax.tva_rate) / 100).toFixed(2));
    },
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-show">
.padding_bottom {
    padding-bottom: 10vw;
}

.link-hortense {
  cursor: pointer;
}
.capacity {
   display: flex;
   align-items: center;
   margin-left: 1rem;
   margin-left: auto;
}
.center{
  background-position: center;
  background-size: cover;
}

@media only screen and (max-device-width: 480px) {
  .lodging-header-desktop {
    display: none !important;
  }
}
@media only screen and (min-device-width: 481px) {
  .container-lodging {
    margin-top: 15vh !important;
  }
  .lodging-header-container {
    display: none;
  }
}
.lodging-made-trip {
  text-align: center;
  border-color: #cddfd3;
	border-style: solid;
	border-width: 1px;
  border-top-right-radius: 20px 20px;
	border-bottom-right-radius: 20px 20px;
	border-bottom-left-radius: 20px 20px;
  height: fit-content;
  width: 200px;
  margin-top: 250px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: absolute;
  //left: 71%;
  max-width: 400px;
  width: 100%;

  @media (max-width: 480px) {
    left: 4%;
    margin-top: 1em;
    position: relative;
    max-width: 370px;
    width: 92%;
  }
  .image-container {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .image-container img {
    flex: 1;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
  }
  
  .head {
    display: flex;
    
    img {
      width: 32px;
      height: 32px;
    }
    
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    
    .modal-arrow {
      .arrow {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.lodging-show-raleway-reg-p26-b{
  font-family: $Merriweather;
  font-weight: 400;
  font-size: 26px;
}
</style>
