<template>
  <div id="destinations">
    <div class="container-index">
      <div id="banner-lodging" :style="cssProps">
        <div class="banner-show-container">
          <div class="col-md-5 col-sm-2 offset-0 catch-hub-show">
            <p class="merriweather-italic24">
              Plongez dans une toute nouvelle expérience de voyage, conçue pour vous offrir le confort de la maison tout en vous permettant de créer des souvenirs inoubliables et de rencontrer des personnes extraordinaires.
            </p>
          </div>
        </div>
      </div>
      <div class="container">
      <div class="row mt-5">
        <div class="col-lg-10 col-md-10 col-sm-12 col-xsm-12 mt-5">
          <h4 class="mt-3" style="font: normal normal normal 26px/33px Merriweather;">Découvrez l’expérience Club Hortense </h4>
<!--          <div class="raleway-reg-p13" v-html="this.yoga_place.description"></div>-->
          <br><br>
        </div>
        <div class="col-md-4 col-sm-6 mb-4" v-for="(experience, index) in experiences" :key="index">
          <div class="card">
            <img :src="experience.image"  class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">{{ experience.title }}</h5>
              <p class="card-text">{{ experience.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-10 col-md-10 col-sm-12 col-xsm-12">
          <h4 style="font: normal normal normal 26px/33px Merriweather;">Des séjours éco-responsables </h4>
                    <div class="raleway-reg-p13"> <p class="raleway-reg-p13">Votre séjour est durable : hébergements écologiques, cuisine éco-responsable, activités eco-friendly, partenariats locaux. Toutes nos activités et repas sont choisis en partenariat avec des entreprises locales, offrant des expériences authentiques tout en soutenant l'économie locale. Réservez vos vacances dans des clubs éco-responsables avec Hortense !</p></div>
          <br><br>
        </div>
        <div class="col-md-4 col-sm-6 mb-4" v-for="(experience, index) in sustainabilityInitiatives" :key="index">
          <div class="card">
            <img :src="experience.icon"  class="card-img-topp" alt="...">
            <div class="card-body">
              <h5 class="card-titlee">{{ experience.title }}</h5>
              <p class="card-textt">{{ experience.description }}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Experience",
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require('../../images/yoga.png')})`
      },
      experiences: [
        {
          image: require("../../images/experienceone.png"),
          title: "EXPÉRIENCE EXCLUSIVE",
          description: "Un séjour exclusif en petit groupe dans des lieux d'exception, chaque endroit étant une perle rare, au cœur de paysages uniques. Une expérience mêlant bien-être, nature et intimité, pour une véritable évasion."
        },
        {
          image: require("../../images/exp-two.png"),
          title: "EXPÉRIENCE ALL INCLUSIVE",
          description: "Tout est arrangé : hébergement, repas, activités et ateliers inclus. Pas besoin de vous soucier de l'organisation. Profitez pleinement de votre séjour tout compris et laissez-nous vous chouchouter."
        },
        {
          image: require("../../images/exp-three.png"),
          title: "EXPÉRIENCE COMMUNAUTAIRE",
          description: "Partagez des instants conviviaux et rencontrez de nouvelles personnes dans une atmosphère accueillante. Nos clubs offrent une opportunité parfaite pour des échanges enrichissants."
        },
        {
          image: require("../../images/exp-four.png"),
          title: "EXPÉRIENCE ENRICHISSANTE",
          description: "Une multitude d'activités captivantes pour tous, petits et grands, sont prévues. Nos séjours promettent de belles surprises avec des divertissements pour chacun."
        },
        {
          image: require("../../images/exp-five.png"),
          title: "EXPÉRIENCE KIDS FRIENDLY",
          description: "Nos séjours sont conçus pour plaire aux petits aventuriers, avec des activités ludiques adaptées à leur âge. Nos équipes dévouées veillent à ce que chaque moment soit magique pour vos enfants."
        },
        {
          image: require("../../images/exp-six.png"),
          title: "EXPÉRIENCE ÉCO-RESPONSABLE",
          description: "Nous planifions les séjours avec un fort engagement pour la durabilité : hébergements écologiques, cuisine locale, biologique et de saison, partenaires locaux pour un tourisme responsable."
        }
      ],
      sustainabilityInitiatives: [
        {
          icon: require("../../images/a_one.png"),
          title: "DES HÉBERGEMENTS RESPECTUEUX DE L'ENVIRONNEMENT",
          description: "Nous travaillons uniquement avec des hébergements engagés dans une démarche environnementale, évalués grâce à notre Green Note, une note sur une échelle de 1 à 10 mesurant leur engagement éco-responsable. Cette approche informe les voyageurs et incite les hébergements à adopter des pratiques durables."
        },
        {
          icon: require("../../images/a_two.png"),
          title: "UNE ALIMENTATION LOCALE, RAISONNÉE ET RESPONSABLE",
          description: "Nous privilégions les fruits et légumes de saison et achetons directement aux producteurs locaux. Notre cuisine entièrement végétarienne réduit notre empreinte carbone tout en vous offrant des plats délicieux et copieux. De plus, nous gérons soigneusement les portions pour minimiser le gaspillage alimentaire."
        },
        {
          icon: require("../../images/a_three.png"),
          title: "UNE BONNE GESTION DES DÉCHETS",
          description: "Nous encourageons le recyclage en mettant en place diverses initiatives auprès de nos partenaires : compostage des déchets végétaux et alimentaires, récupération et recyclage des huiles de cuisine usagées, utilisation de papier hygiénique 100% recyclé, limitation des déchets lors de la préparation des repas..."
        },
        {
          icon: require("../../images/a_four.png"),
          title: "UNE EMPREINTE CARBONE RÉDUITE",
          description: "Nous réduisons notre empreinte carbone en favorisant l'achat local chez les producteurs de la région, en utilisant des produits biodégradables et recyclables, et en proposant une cuisine entièrement végétarienne. De plus, nous privilégions les prestataires locaux pour une expérience authentique et pour soutenir le développement local."
        },
        {
          icon: require("../../images/a_five.png"),
          title: "UN PROGRAMME DE FINANCEMENT SOLIDAIRE",
          description: "En séjournant dans nos clubs, vous participez activement au tourisme durable. Notre initiative, le programme Evergreen de Hortense, soutient financièrement des projets écologiques visant à réduire l'impact environnemental de nos partenaires d'hébergement."
        }
      ]
    };
  }
};
</script>

<style scoped>
#banner-lodging {
  width: 100%;
  height: 100%;
  background-image: url("https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;

  .banner-show-container {
    width: 100%;
    height: 444px;
    margin: 0px !important;
    padding: 0px !important;

    .catch-hub-show {
      color: white;
      margin-top: 14rem;
    }
  }

  .btn-galerie {
    width: 100%;
    margin-left: 5rem;
    cursor: pointer;

    .galerie-icon {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 60px;
      padding: 1rem 2rem;
      color: white;
      width: 152px;
      height: 48px;
    }
  }

  .btn-galerie-phone {
    display: none;
  }
}
.card {
  border: none;
}
.card-img-top {
  max-width: 253px;
  height: 253px;
  object-fit: cover;
}
.card-img-topp {
  max-width: 96px;
  max-height: 96px;
}
.card-title {
  font: normal normal bold 15px/18px Raleway;
  max-width: 253px;
  font-weight: bold;
}
.card-text {
  font: normal normal normal 15px/18px Raleway;
  max-width: 253px;
  color: #555;
}
.card-titlee {
  font: normal normal bold 15px/18px Raleway;
  font-weight: bold;
}
.card-textt {
  font: normal normal normal 15px/18px Raleway;
  color: #555;
}
@media (max-width: 768px) {
  .card-img-top {
    height: 150px;
  }
  .card-title {
    font-size: 1.1rem;
  }
  .card-text {
    font-size: 0.9rem;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #banner-lodging {
    margin-top: 16vh;
    display: block;

    .btn-galerie-phone {
      display: block;
      margin-left: 1rem;

      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }

    .btn-galerie {
      display: none;
    }

    .banner-show-container {
      width: 100%;
      height: 554px;
      display: flex;
      align-items: flex-end;

      .catch-hub-show {
        margin-left: 1rem;

        p {
          font-size: 26px;
        }
      }
    }
  }
}

</style>