<template>
  <div id="b-phone">
    <video playsinline autoplay loop muted class="home_video">

      <source src="https://hortense-vue.s3.eu-west-3.amazonaws.com/COVER+VERTICALE.mov">
      <!--source src="https://hortense-vue.s3.eu-west-3.amazonaws.com/videos/COVER-VERTICALE.webm"-->

    </video>
    <div class="b-phone-container">
      <div class="b-phone-header">
        <h3 class="b-title merriweather-reg-30">{{ $t("Banner.header") }}</h3>
        <p class="b-content raleway-reg-p15">{{ $t("Banner.subtitle") }}</p>
      </div>
      <div class="banner-search">
        <input class="search-bar" type="text" :placeholder="$t('Banner.whereto')" v-model="search"></input>
        <a class="around-btn link-hortense" @click="getLocation">
          Autour de moi
        </a>
      </div>
        <a :href="'/reservation-hotel?search=' + search" :data="search" class="no-deco">
          <div class="search-button hortense-btn-primary">
            {{ $t("search") }}
          </div>
        </a>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'b-phone',
    data() {
      return {
        search: "",
      }
    },
    methods: {
     getLocation(){
        navigator.geolocation.getCurrentPosition((position) => {
          Turbolinks.visit(`/reservation-hotel?geo=${position.coords.latitude},${position.coords.longitude}`)
        });
      },
      animLogo(e) {
        e.currentTarget.animate([
          {transform: 'translateY(0px)'},
          {transform: 'translateY(-10px)'},
          {transform: 'translateY(0px)'}
        ],
        {
          duration: 800,
          easing: 'ease-in-out',
          iterations: 2
        })
      },
      scrollDown() {
        this.$el.nextElementSibling.scrollIntoView({behavior: 'smooth'});
      }
    },
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/banner-phone'>
</style>
