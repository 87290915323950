<template>
  <div id="nav-phone" :class="(currentRoute == '/') ? 'homenav' : ''">
    <Modalsignup v-if="showModal" @close="setUser"></Modalsignup>
    <div class="nav-phone">
      <a href="/" class="logo" v-if="!showBanner">
        <img src="../../images/hortense blanc.svg" alt="Logo">
      </a>
      <div v-if="shownavphone" style="z-index:9999999;position:relative">
        <SearchbtnPhoneWhite v-if="!showBanner" @show="showBanner = !showBanner"></SearchbtnPhoneWhite>
      </div>
      <Burger :user="user"></Burger>
    </div>      
    <div v-if="shownavphone">
      <SearchbannerPhone v-if="showBanner" @close="showBanner = false"></SearchbannerPhone>
    </div>
  </div>
</template>

<script>

  import Burger from 'components/menus/Burger.vue';
  import fetcher from 'packs/shared/api.js';
  import Modalsignup from 'components/modals/Modalsignup.vue';
  import SearchbtnPhoneWhite from 'components/btns/SearchbtnPhoneWhite.vue';
  import SearchbannerPhone from 'components/btns/SearchbannerPhone.vue';

  export default{
    name: 'nav-main-phone',
    props: ["user", "shownavphone"],
    components: { Burger, Modalsignup, SearchbannerPhone, SearchbtnPhoneWhite},
    data() {
      return {
        search: "",
        currentRoute: window.location.pathname,
        showModal: false,
        showBanner: false
      }
    },
    methods:{
      setUser(user){
        this.user = user;
        this.showModal = false;
      },
    },
    mounted() {
      this.$root.$on('openModal', _ => {
        this.showModal = true;
      });
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/nav-phone'>

</style>
