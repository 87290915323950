<template>
  <div id="destinations">
    <div class="container-index">
      <div class="container">
        <p class="pb-3 raleway-reg-p15 text-center">Bonjour {{this.reservation.first_name}},</p>
        <p class="pb-2 raleway-reg-p15 text-center">Nous avons bien enregistré votre réservation.</p>
        <p class="pb-2 raleway-reg-p15 text-center">Nous espérons que vous vivrez une expérience</p>
        <p class="pb-2 raleway-reg-p15 text-center">Yogacation exceptionnelle.</p>
        <p class="pb-5 raleway-reg-p15 text-center">À bientôt ! L’équipe Hortense.</p>
        <div class="form-row justify-content-center">
          <button @click="goToYoga" class="hortense-btn-primary">RETOURNER SUR LA PAGE D’ACCUEIL</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadYoga from "./HeadYoga.vue";
import ScrollUp from "../../components/btns/ScrollUp.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "../../components/btns/Searchbtn.vue";
import Searchbanner from "../../components/btns/Searchbanner.vue";
import YogaCard from "./YogaCard.vue";
import YogaStay from "./YogaStay.vue";
import YogaCardAlter from "./YogaCardAlter.vue";

export default {
  name: "payment-success",
  components: { HeadYoga, ScrollUp, Searchbtn, Searchbanner, YogaCard, YogaStay, YogaCardAlter  },
  props:["reservation"],
  data() {
    return {
      showBanner: false,
      selectedDestination: "France",
      destinations: null,
      isLoading: true,
      fileNames: {
        "Caraïbes": "Caraibes",
        "Polynésie Française": "Polynesias",
        "Afrique": "Africas",
        "Océan Indien": "Indians",
        "France": "Francecard",
        "Europe": "Europe",
        "Americas": "Americas",
        "Asia": "Asia"
      },
    };
  },
  methods: {
    goToYoga() {
      window.location.href = "/experiences-bien-etre"
    }
  }
};
</script>
<style
    lang="scss"
    scope="app/assets/stylesheets/components/destinations"
></style>
