<template>
  <div id="search-banner" v-if="this.display">
    <div class="container-banner">
      <div class="search-location">
        <div class="banner-search">
          <template v-if="lodging">
            <input
              class="raleway-reg-p15 search-bar-location"
              type="text"
              :placeholder="$t('Banner.whereto')"
              v-model="search"
              autofocus
            />
          </template>
          <template v-else>
            <input
              class="raleway-reg-p15 search-bar-location"
              type="text"
              :placeholder="$t('Banner.whereto')"
              v-model="search"
              autofocus
            />
            <a class="around-btn link-hortense" @click="getLocation">
              <img src="../../images/Localisation.svg" alt="" />
              Autour de moi
            </a>
          </template>
          <a
            :href="'/reservation-hotel?search=' + search + '&otherValue=test'"
            :data="search"
            class="no-deco"
          ></a>
        </div>
      </div>
      <div class="search-duration">
        <HotelDatePicker
          :startDate="minDate"
          :endDate="maxDate"
          :format="'DD/MM/YYYY'"
          :gridStyle="false"
          :hoveringTooltip="false"
          :i18n="cFR"
          ref="datePicker"
          @periodSelected="selectDates"
        ></HotelDatePicker>
      </div>
      <div class="search-number">
        <input
          class="raleway-reg-p15 search-bar-top text-center"
          @click="showGuestInfo = !showGuestInfo"
          :placeholder="formatAdults() + ' - ' + formatChilds()"
        />
        <Guestinfo
          v-if="showGuestInfo"
          @ageSelected="updateAges"
          @increaseRooms="updateRooms"
          @decreaseRooms="updateRooms"
          @increaseChilds="updateChilds"
          @decreaseChilds="updateChilds"
          @increaseAdults="updateAdults"
          @decreaseAdults="updateAdults"
          @closed="handleClose"
        />
      </div>

      <template v-if="lodging">
        <button class="hortense-btn-primary" :disabled="disableSearchButton" @click="validate">
          {{ searchButtonCaption }}
        </button>
      </template>
      <template v-else>
        <button class="hortense-btn-primary" :disabled="disableGoButton" >
          <a :href="'/reservation-hotel?search=' + search + '&childs='+ childs+ '&adults='+ adults" :data="search" class="no-deco text-white">
            <div class="search-button">
              {{ searchButtonCaption }}
            </div>
          </a>
        </button>
      </template>
      <button class="" @click="close">
        <img src="../../images/Croix fermer.svg" alt="fermer" />
      </button>
    </div>
  </div>
</template>

<script>
import Guestinfo from "components/btns/Guestinfo.vue";
import { VueDatePicker } from "@mathieustan/vue-datepicker";

export default {
  name: "SearchBannerPhone",
  components: { Guestinfo, VueDatePicker },
  props: {
    lodging: {
      type: Object,
      default: null,
    },
    searched: Boolean,
    expandCalendar: Boolean,
  },
  data() {
    return {
      lat: "",
      long: "",
      not_enable: false,
      valtemp: "",
      selectedDates: [],
      showGuestInfo: false,
      rooms: 1,
      childs: 0,
      adults: 2,
      childAges: [],
      color: "#CDDFD3",
      date: new Date(),
      currentDate: new Date(),
      display: true,
      isSearching: false,
      cFR: {
        night: "Nuit",
        nights: "Nuits",
        week: "semaine",
        weeks: "semaines",
        "day-names": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        "check-in": "Arrivée",
        "check-out": "Départ",
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        tooltip: {
          halfDayCheckIn: "Available CheckIn",
          halfDayCheckOut: "Available CheckOut",
          saturdayToSaturday: "Only Saturday to Saturday",
          sundayToSunday: "Only Sunday to Sunday",
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum.",
        },
      },
    };
  },
  watch: {
    searched() {
      this.isSearching = !this.searched;
    },
    expandCalendar(new_value, old_value) {
      if (this.expandCalendar) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          this.$refs.datePicker.showDatepicker();
        }, 100);
      }
      this.$emit('updateExpandCalendar')
    },
  },
  methods: {
    handleClose() {
      this.showGuestInfo = false;
    },
    validate() {
      this.$emit("selectDates", this.selectedDates);
      this.$emit("adultsNum", this.adults);
      this.$emit("childsNum", this.childs);
      this.$emit("childsAge", this.childAges);
      this.$emit("formSubmit");
      this.isSearching = true;
      this.showGuestInfo = false;
    },
    selectDates(event, start, end) {
      // console.log(this.formatDate(start), this.formatDate(end))
      this.selectedDates = [this.formatDate(start), this.formatDate(end)];
      // console.log(this.selectDates);
    },
    formatDate(date) {
      const set = new Date(date);
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      //return set.toLocaleString("default", options);
      return set.toLocaleString("fr", options);
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude;
        let long = position.coords.longitude;
        Turbolinks.visit(`/reservation-hotel?geo=${lat},${long}`);
      });
    },
    updateRooms(room) {
      this.rooms = room;
      this.$emit("numberOfRoom", this.rooms);
    },
    formatRooms() {
      let chambre = " Chambre";
      if (this.rooms > 1) {
        chambre += "s";
      }
      return this.rooms + chambre;
    },
    updateChilds(child) {
      this.childs = child;
      this.$emit("numberOfChild", this.childs);
    },
    formatChilds() {
      let enfant = " Enfant";
      if (this.childs > 1) {
        enfant += "s";
      }
      return this.childs + enfant;
    },
    updateAdults(adult) {
      this.adults = adult;
      this.$emit("numberOfAdult", this.adults);
    },
    updateAges(value) {
      this.childAges = [...value];
      this.$emit("ageOfChild", this.childAges);
    },
    formatAdults() {
      let adulte = " Adulte";
      if (this.adults > 1) {
        adulte += "s";
      }
      return this.adults + adulte;
    },
    close() {
      if (this.lodging) {
        this.display = false;
      } else {
        this.$emit("close");
      }
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const from = urlParams.get('from');
    const to = urlParams.get('to');
    const searchParam = urlParams.get('search');
    const adults = urlParams.get('adults');
    const childs = urlParams.get('childs');

    if (from && to) {
      this.selectedDates = [this.formatDate(from), this.formatDate(to)];
      this.$emit("selectDates", this.selectedDates);
    }

    if (searchParam) {
      this.search = searchParam;
    }

    if (adults) {
      this.adults = parseInt(adults);
    }

    if (childs) {
      this.childs = parseInt(childs);
    }
  },
  computed: {
    searchButtonCaption() {
      return this.isSearching ? "Recherche en cours..." : "Valider";
    },
    disableSearchButton() {
      return this.isSearching;
    },
    disableGoButton(){
      return this.getlocate;
    },
    minDate() {
      return new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
    },
    maxDate() {
      return new Date(
        this.currentDate.getFullYear() + 1,
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
    },
    search: {
      get () {
        if (this.lodging) {
          return this.lodging.name
        }
        else{
          return this.valtemp;
        }
      },
      set (newValue) {
        this.valtemp = newValue
      }
    },
  },
};
</script>
<style>
.vhd__datepicker__input:first-child {
  background: white !important;
  border-bottom: 1px solid black;
}

.vhd__datepicker__dummy-wrapper {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid black !important;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.vhd__datepicker__month-button,
.vhd__datepicker__month-button--prev {
  /*.vhd__datepicker__month-button {*/
  background: transparent url(/packs/media/img/ic-arrow-right-green.regular.83ed3b6c-93e0ea4a.svg)
    no-repeat 50%/8px;
  width: 2.5em;
  height: 2.5em;
  border: 1px solid #cddfd3 !important;
  outline: none;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.vhd__datepicker__month-day--first-day-selected,
.vhd__datepicker__month-day--last-day-selected {
  background: #cddfd3 !important;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  pointer-events: auto;
}
.vhd__datepicker__month-day--selected {
  background-color: rgba(205, 223, 221, 0.7) !important;
  color: #fff;
}
</style>
