<template>
  <div id="nav-phone">
    <Modalsignup v-if="showModal" @close="setUser"></Modalsignup>
    <div class="nav-phone">
      <div class="search-wrapper">
        <div class="search-container">
          <input
              type="text"
              class="search-input"
              v-model="search"
              @focus="showSearchBanner"
              placeholder="Où partez vous ?"
          />
          <div class="icons-container">
            <div class="search-icon-container mr-5">
              <SearchbtnPhone v-if="!showBanner" @show="showBanner = !showBanner"></SearchbtnPhone>
            </div>
            <div class="burger-icon-container">
              <BurgerTwo :user="user"></BurgerTwo>
            </div>
          </div>
        </div>
        <div class="search-banner-container" v-if="showBanner">
          <SearchbannerPhone @close="showBanner = false"></SearchbannerPhone>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetcher from 'packs/shared/api.js';
import Modalsignup from 'components/modals/Modalsignup.vue';
import SearchbtnPhone from 'components/btns/SearchbtnPhone.vue';
import SearchbannerPhone from 'components/btns/SearchbannerPhone.vue';
import BurgerTwo from "../menus/BurgerTwo.vue";

export default {
  name: 'nav-phone',
  props: ["user", "shownavphone"],
  components: {
    BurgerTwo,
    Modalsignup,
    SearchbannerPhone,
    SearchbtnPhone
  },
  data() {
    return {
      search: "",
      showModal: false,
      showBanner: false,
    }
  },
  methods: {
    setUser(user) {
      this.user = user;
      this.showModal = false;
    },
    showSearchBanner() {
      this.showBanner = true;
    }
  },
  mounted() {
    this.$root.$on('openModal', _ => {
      this.showModal = true;
    });
    const urlParams = new URLSearchParams(window.location.search);
    this.search = urlParams.get('search') || '';
  },
}
</script>

<style scoped lang="scss">
.nav-phone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  position: fixed; /* Make it fixed */
  top: 0; /* Stick it to the top */
  left: 0; /* Full width */
  right: 0; /* Full width */
  z-index: 1000; /* Ensure it appears on top */
  background-color: white; /* Background color to prevent overlap issues */
}

.search-wrapper {
  position: relative;
  width: 100%;
}

.search-container {
  display: flex;
  align-items: center;
  background: #CFE5DC;
  border-radius: 21px;
  padding: 5px 10px;
  max-width: 600px;
  margin: 0 auto;
}

.search-input {
  border: none;
  background-color: transparent;
  outline: none;
  flex: 1;
  padding: 5px;
}

.search-input::placeholder {
  color: #000; /* Adjust placeholder text color if needed */
}

.icons-container {
  display: flex;
  align-items: center;
}

.search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #CFE5DC; /* Same background color */
  border-radius: 50%; /* Make it round */
  padding: 5px; /* Adjust padding for size */
  margin-left: 5px; /* Adjust margin for spacing */
  margin-right: 15px;
}

.burger-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #CFE5DC; /* Same background color */
  border-radius: 50%; /* Make it round */
  padding: 5px; /* Adjust padding for size */
  margin-left: 5px; /* Adjust margin for spacing */
}

.search-banner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure it appears on top */
  background-color: #fff; /* Adjust as needed */
}
</style>
