<template>
  <div id="galeries">
    <Searchbtn
        @show="showBanner = !showBanner"
        @top="redirectToTop"
    ></Searchbtn>
    <Searchbanner v-if="showBanner" @close="showBanner = false"></Searchbanner>
    <div class="container-index">
      <div class="index-text">
        <h2>Galerie</h2>
        <p>
          Plongez au cœur du tourisme durable avec Hortense ! Inspirez-vous de
          notre galerie et faites de votre voyage un souvenir mémorable.
          Destinations de rêve, hôtels de charme, écolodges luxueux ou encore
          expériences inédites : dites-nous ce que vous cherchez et découvrez
          une sélection parfaite pour vous.
        </p>
      </div>
      <div class="select-list">
        <div class="row row-galerie col-galerie">
          <div class="col-sm-2" v-if="destinationsChoices.length">
            <v-select
                class="select-item raleway-bold-p13 text-center"
                placeholder="DESTINATIONS"
                v-model="destinationSelected"
                @input="filterLodgings"
                label="name"
                :options="destinationsChoices"
            ></v-select>
          </div>
          <div class="col-sm-2" v-if="typesChoices.length">
            <v-select
                class="select-item raleway-bold-p13 text-center"
                placeholder="HÉBERGEMENTS"
                v-model="typeSelected"
                @input="filterLodgings"
                label="name"
                :options="typesChoices"
            ></v-select>
          </div>
          <div class="col-sm-2" v-if="ambiancesChoices.length">
            <v-select
                class="select-item raleway-bold-p13 text-center"
                placeholder="AMBIANCE"
                v-model="ambianceSelected"
                @input="filterLodgings"
                label="name"
                :options="ambiancesChoices"
            ></v-select>
          </div>
          <div class="col-sm-2" v-if="stylesChoices.length">
            <v-select
                class="select-item raleway-bold-p13 text-center"
                placeholder="STYLE"
                v-model="styleSelected"
                @input="filterLodgings"
                label="name"
                :options="stylesChoices"
            ></v-select>
          </div>
        </div>
      </div>

      <div class="gallery gallery-galerie">
        <div style="display: contents" v-for="lodging in lodgings" :key="lodging.id">
          <CardGaleries
              :lodging="lodging"
              data-aos="fade-up"
              data-aos-duration="1000"
          ></CardGaleries>
        </div>
      </div>
      <Loader v-if="loading" />
      <div id="scrollUp">
        <div class="scroll-up" @click="fetchLodgings">
          Voir plus de photos
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import ScrollUp from "../../components/btns/ScrollUp.vue";
import CardGaleries from "../../components/cards/CardGaleries.vue";
import fetcher from "../../packs/shared/api.js";
import Loader from "../../components/shared/Loader";
import Searchbtn from "components/btns/Searchbtn.vue";
import Searchbanner from "components/btns/Searchbanner.vue";

export default {
  name: "galeries",
  components: {Loader, ScrollUp, CardGaleries, Searchbtn, Searchbanner},
  data() {
    return {
      showBanner: false,
      lodgings: [],
      page: 1,
      destinationsChoices: [],
      destinationSelected: null,
      typesChoices: [
        {value: "Hotel", name: "Hôtels"},
        {value: "Gite", name: "Gites"},
        {value: "Guestroom", name: "Chambres d'hôtes"},
        {value: "Villa", name: "Villas"},
        {value: "Appartment", name: "Appartements"},
        {value: "Lodge", name: "Lodges"},
        {value: "Guesthouse", name: "Maison d'hôtes"},
        {value: "Other", name: "Lieux insolites"},
      ],
      typeSelected: null,
      ambiancesChoices: [],
      ambianceSelected: null,
      stylesChoices: [],
      styleSelected: null,
      loading: false,
    };
  },
  mounted() {
    this.fetchChoices();
    AOS.init();
  },
  methods: {
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    filterLodgings() {
      this.page = 1;
      this.lodgings = [];
      this.fetchLodgings();
    },
    async fetchLodgings() {
      this.loading = true;
      let params = "";
      if (this.destinationSelected) {
        params += `&destination=${this.destinationSelected.id}`;
      }
      if (this.typeSelected) {
        params += `&type=${this.typeSelected.value}`;
      }
      if (this.ambianceSelected) {
        params += `&ambiance=${this.ambianceSelected.id}`;
      }
      if (this.styleSelected) {
        params += `&style=${this.styleSelected.id}`;
      }

      try {
        const response = await fetcher.get(
            `/galerie-hotel-ecoresponsable?page=${this.page}${params}`
        );
        this.lodgings.push(...response.data);
        this.page++;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchChoices() {
      this.loading = true;
      await this.fetchDestinations();
      await this.fetchAmbiances();
      await this.fetchStyles();
      await this.fetchLodgings();
      this.loading = false;
    },

    async fetchDestinations() {
      try {
        const response = await fetcher.get("/destination-galerie.json");
        this.destinationsChoices = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchAmbiances() {
      try {
        const response = await fetcher.get("/ambiances.json");
        this.ambiancesChoices = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchStyles() {
      try {
        const response = await fetcher.get("/styles.json");
        this.stylesChoices = response.data;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/galerie">
@import "vue-select/src/scss/vue-select.scss";
</style>
