<template>
  <div id="newsletterSubscribe">
    <div id="header">
      <div class="text-center">
        <h2 class="merriweather-reg-40">La newsletter d’Hortense</h2>
      </div>
    </div>
    <div class="form-container">
      <form @submit.prevent="sendContact">
        <div class="form-signup raleway-reg-p15">
          <div class="form-item">
            <label for="first_name">*Prénom</label>
            <input class="border-input" v-model="newsletter.first_name" type="text" id="first_name">
          </div>
          <div class="form-item">
            <label for="last_name">*Nom</label>
            <input class="border-input" v-model="newsletter.last_name" type="text" id="last_name">
          </div>
          <div class="form-item">
            <label for="email">*Mon adresse mail</label>
            <input class="border-input" v-model="newsletter.email" type="text" id="email">
          </div>
          <p class="raleway-it-l13">Les champs marqués d’un * sont obligatoires.</p>
          <button type="submit" class="hortense-btn-primary w-50 btn-submit-subscribe">Je m'abonne</button>
        </div>
      </form>
    </div>
    <div class="footer-container-subscribe">
      <div class="become d-flex">
        <p class="raleway-reg-p15 mr-3">En m’abonnant, j’accepte qu’Hortense m’envoie des informations et des offres par e-mail. Je peux me désabonner à tout moment sur mon espace en ligne ou en cliquant sur le lien « Se désinscrire » qui se trouve en bas de tous nos e-mails. J’ai lu la <a href="/politique-confidentialité/" class="link-hortense"> Politique de confidentialité</a> Hortense.</p>
      </div>
    </div>
  </div>
</template>

<script>
import fetcher from 'packs/shared/api.js';

export default {
  name: 'newsletterPage',
  data() {
    return {
      newsletter: {
        email: '',
        last_name: '',
        first_name: '',
      },
    };
  },
  methods: {
    async sendContact() {
      try {
        console.log('newsletter');
        console.log(this.newsletter);
        const res = await fetcher.post('/newsletters/', { newsletter: this.newsletter, just_mail: true });
        alert('Subscribed successfully!');
        window.location.href = '/';
      } catch (e) {
        console.error(e);
        window.location.href = '/';
        alert('Subscription failed.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#newsletterSubscribe {
  display: grid;

  #header {
    margin-top: 12%;
    text-align: center;

    h2 {
      margin-bottom: 1rem;
    }
  }

  .form-container {
    border: 1px solid #ccc;
    margin: 3% 30%;
    padding: 2%;
  }

  .border-input {
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 2%;
  }

  .footer-container-subscribe {
    margin: 1% 28%;
    padding: 2%;
  }

  .btn-submit-subscribe {
    margin: auto 23%;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    #header {
      margin-top: 25%;
      padding: 5%;

      h2 {
        font-size: 24px;
      }
    }

    .form-container {
      margin: 3% 5%;
      padding: 3%;
    }

    .footer-container-subscribe {
      margin: 1% 5%;
    }
  }
}
</style>
