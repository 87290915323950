<template>
  <div id="profil-dropdown">
    <div  class="el-center--flex-column no-deco-profil pointer active-dropdown-menu" @click="showMenu">
      <ul class="list-items">
        <a :href="'/dashboard/' + this.user.slug" class="no-deco-profil">
          <li>Mon profil</li>
        </a>
        <a :href="'/reservations'" class="no-deco-profil">
          <li>Mes voyages</li>
        </a>
        <a :href="'/wishlists'" class="no-deco-profil">
          <li>Mes favoris</li>
        </a>
        <a href="#" class="no-deco-profil">
          <li>Programme de fidélité Hortensia</li>
        </a>
        <a :href="'/comments/'" class="no-deco-profil">
          <li>Mes commentaires</li>
        </a>
        <a href="/users/sign_out" data-method="delete" class="no-deco-profil">
          <li class="sign_out">Me déconnecter</li>
        </a>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'profil-dropdown',
    props: {
      user: Object
    },
    data() {
      return {
        showMenu : false,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .list-items {
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      list-style: none !important;
    }

  .active-dropdown-menu{
    left: -89px;
    top: 201px;
    width: 240px;
    height: 346px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    position: relative;
    z-index: 2;

    .list-items {
      padding: 5px 7px;
      height: inherit;
      display: flex;
      margin-bottom: 0;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      list-style: none !important;
      a{
        width: 100%;
        &:hover{
          background-color: #CDDFD3;
        }
      }

      .sign_out{
        border-top: 1px solid black;
      }
      li{
        width: 100%;
        padding: 13px;
      }
    }
  }

  .no-deco-profil{
    color: #000000;
    font-family: $Raleway;
    font-weight: 400;
    font-size: 13px;
    &:hover {
      text-decoration: none;
      color: #000000;
    }
  }

  .pointer {
    cursor: pointer;
  }
</style>
